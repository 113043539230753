import React from "react";
import { IconButton, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const ContactFieldsGroup = (props) => {
  return (
    <>
      <h5 className="stepSectionTitle d-flex">
        {props?.contactTitle}
        {props?.allowDelete === true && (
          <IconButton className="ml-auto fs-14" size="small" variant="text" onClick={props?.onDeleteContact}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </IconButton>
        )}
      </h5>
      <div className="stepSectionContent pl-15">
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="name"
            id="name"
            label="Name"
            variant="outlined"
            placeholder="Name"
            onChange={props?.handleInputChange}
            inputProps={{maxLength :25}}
            value={props?.values?.name ? props?.values?.name : ""}
          />
          {typeof props?.errors?.name !== "undefined" &&
            props?.errors?.name !== "" && (
              <span className="small error color-danger">
                {props?.errors?.name}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="email"
            id="email"
            label="Email"
            variant="outlined"
            placeholder="Email"
            onChange={props?.handleInputChange}
            value={props?.values?.email ? props?.values?.email : ""}
          />
          {typeof props?.errors?.email !== "undefined" &&
            props?.errors?.email !== "" && (
              <span className="small error color-danger">
                {props?.errors?.email}
              </span>
            )}
        </div>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="mobileNumber"
            id="mobileNumber"
            label="Mobile Number"
            variant="outlined"
            placeholder="Mobile Number"
            onChange={props?.handleInputChange}
            value={
              props?.values?.mobileNumber ? props?.values?.mobileNumber : ""
            }
          />
          {typeof props?.errors?.mobileNumber !== "undefined" &&
            props?.errors?.mobileNumber !== "" && (
              <span className="small error color-danger">
                {props?.errors?.mobileNumber}
              </span>
            )}
        </div>
      </div>
    </>
  );
};

export default ContactFieldsGroup;
