/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import CommonContext from "../../../../hooks/commonContext";
import AuthContext from "../../../../hooks/authContext";
import { Container, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery } from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import {
  getCategories, addNotes, fetchNotes,
  getSupplyOrderListBasedOnId,
  updateArchive,
  updateSupplyItemsStatus,
  updateSupplyItemDate,
  updateSuppliesStatus,
} from "../../../../services/apiservices/supplies";
import SupplyRequestDetails, { SupplyRequestDetailsResponsive } from "./SupplyRequestDetails";
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import { getClientsListbasedonIds } from "../../../../services/apiservices/client";
import { getAllEmployee, getEmployeesListBasedOnId } from "../../../../services/apiservices/employee";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { convertFirestoreTimestampToDate, formatDate } from "../../../../services/helpers";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { saveNotification2, clothingSaveNotification } from "../../../../services/apiservices/notifications";
import Checkbox from '@mui/material/Checkbox';
import ManageSuppliesDetailsResponsive from "../../../../components/ResponsiveCardComponent/SupplyComponent/ManageSupplyDetailsResponsive";
dayjs.extend(utc);
dayjs.extend(timezone);

const NotesSection = ({ notes, onAddNote, employee, createdBy, orderId, setNotes, data, empData, clientData, commonContext }) => {
  const [newNote, setNewNote] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const supply = true;
  const clientName = clientData ? clientData[0].label : null;

  const sendPushAndEmailNotification = async (orderNumber, name, email) => {
    const formattedMessage = `New Message - Supply #${orderNumber}. Check for more details.`;
    const notifications = [
      {
        Type: "PushNotification",
        Message: formattedMessage,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name
          }
        ],
        extData: { type: "SuppliesNote", id: orderId }
      },
      {
        Type: "Email",
        Message: newNote.trim(),
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];
    await saveNotification2(notifications, supply, orderNumber, clientName, orderId);
  };

  const handleAddNote = async () => {
    if (!newNote.trim()) return;

    const note = {
      createBy: createdBy,
      note: newNote.trim(),
      orderId: orderId,
    };

    let orderNumber = String(data.orderNumber).padStart(6, '0');
    const employee = empData.find(emp => emp.value === data.createdBy);
    const name = employee ? employee.label : '';
    const email = employee ? employee.email : '';

    try {
      commonContext?.setLoader(true);
      const notificationPromise = sendPushAndEmailNotification(orderNumber, name, email);
      const addedNotePromise = addNotes(note);
      const [addedNote] = await Promise.all([notificationPromise, addedNotePromise]);

      onAddNote && onAddNote(addedNote);
      commonContext?.setLoader(false);
      fetchNotes(orderId, (fetchedNotes) => {
        setNotes(fetchedNotes);
      });
    } catch (error) {
      commonContext?.setLoader(false);
      console.error('Error adding note: ', error);
    }
    setNewNote('');
  };

  const handleCancel = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirmCancel = () => {
    setDialogOpen(false);
    navigate(-1);
    setNewNote('');
  };

  const createBy = (id, employees) => {
    const employee = employees.find(item => item.value === id);
    const data = employee ? employee.label : "";
    return data;
  };

  const sortedNotes = [...notes].sort((a, b) => {
    const dateA = convertFirestoreTimestampToDate(a.createdAt);
    const dateB = convertFirestoreTimestampToDate(b.createdAt);
    return dateB - dateA;
  });

  return (
    <>
      <div className="note-input mb-30">
        <TextField
          label="Add Notes"
          multiline
          rows={3}
          variant="outlined"
          className="mt-15"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          fullWidth
        />
        <Button
          color="primary"
          variant="contained"
          onClick={handleAddNote}
          className="mt-15"
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={handleCancel}
          className="mt-24 m-10"
        >
          Cancel
        </Button>
      </div>

      <strong className="mt-20"> Notes </strong>
      <hr className="note-divider mb-25" />
      <div className="notes-section">
        {sortedNotes[0]?.message ? (
          <p>No Notes Available</p>
        ) : (
          sortedNotes.map((note, index) => (
            <div key={index} className="note">
              <p>{note?.note}</p>
              <p>
                <strong>Date:</strong> {formatDate(note?.createdAt)}{' '}
                <strong className="ml-10">Created By:</strong> {createBy(note?.createBy, employee)}
              </p>
              {index < sortedNotes.length - 1 && <hr className="note-divider" />}
            </div>
          ))
        )}
      </div>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this note?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleConfirmCancel} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CheckBoxComponent = ({ rcellItem, rowIndex, handleCheckboxChange, isChecked }) => {
  return (
    <Checkbox
      checked={isChecked}
      onChange={() => handleCheckboxChange(rcellItem?.id)}
    />
  );
};

const SuccessDialog = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        This order has been successfully Archived for Future Reference.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ManageSuppliesRequestDetails = (props) => {
  const commonContext = useContext(CommonContext);
  const { userProfile } = useContext(AuthContext);
  const { requestId } = useParams();
  const [requestData, setRequestData] = React.useState({});
  const [allSupplies, setAllSupplies] = React.useState([]);
  const [notes, setNotes] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [openDatePickerId, setOpenDatePickerId] = useState(null);
  const [categories, setCategories] = useState({});
  const [empName, setEmployeeName] = useState();
  const [empData, setEmpData] = useState();
  const [clientData, setClientData] = useState();
  const [categoryMap, setCategoryMap] = useState({});
  const [data, setData] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [newId, setNewId] = useState();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [previousStatuses, setPreviousStatuses] = useState();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');


  const getRequestData = (requestId) => {
    if (typeof requestId !== "undefined" && requestId !== "") {
      commonContext?.setLoader(true);

      getSupplyOrderListBasedOnId(
        requestId,
        async (res) => {
          if (!res) {
            setRequestData({});
            setAllSupplies([]);
            commonContext?.setLoader(false);
            return;
          }

          setData(res);
          const fetchCategoriesPromises = [
            fetchAndStoreCategories(res?.supplyItems),
            fetchAndStoreCategoriesForEmail([res])
          ];
          await Promise.all(fetchCategoriesPromises);

          const deliveryStatus = await getDeliveryStatus(res?.supplyItems);
          let newStatus = null;

          switch (deliveryStatus) {
            case "Full Delivery":
              newStatus = "Delivered";
              break;
            case "cancelled":
              newStatus = "Cancel Item";
              break;
            case "Full Shipment":
              newStatus = "Shipped";
              break;
            case "In Process":
              newStatus = "Ordered";
              break;
            case "Back Ordered":
              newStatus = "Back Ordered";
              break;
            case "Partial Delivery":
              newStatus = "Partial Delivery";
              break;
            case "Partial Shipment":
              newStatus = "Partial Shipment";
              break;

            default:
              newStatus = "Approved";
              break;
          }

          if (newStatus && newStatus !== res?.status) {
            const formData = { status: newStatus };
            await updateSuppliesStatus(res?.id, formData);
          }

          let clientIds = [res?.clientId];
          let empIds = [res?.createdBy];

          let clintsData = [];
          await getClientsListbasedonIds(clientIds, (result) => {
            clintsData = result.map((item) => ({
              value: item?.id,
              label: item?.clientName,
            }));
            setClientData(clintsData);
          });

          let employeeData = [];
          await getEmployeesListBasedOnId(empIds, (result) => {
            const employees = result.map((item) => {
              return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}`, email: item?.email };
            });
            setEmpData(employees)
            employeeData = employees;
          });

          if (deliveryStatus === "cancelled") {
            const formData = {
              status: "Rejected"
            }
            await Promise.all([
              updateSuppliesStatus(res.id, formData),
              sendRejectNotification()
            ])
            navigate(-1);
          }

          await getAllEmployee(
            (result) => {
              const employees = result.data.map((item) => {
                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
              });
              setEmployeeName(employees);
            });

          const client = clintsData.find(client => client?.value === res?.clientId);
          const clientName = client ? client.label : 'Unknown Client';

          const employee = employeeData.find(emp => emp?.value === res?.createdBy);
          const employeeName = employee ? employee.label : 'Unknown Employee';

          const ids = res.supplyItems.map(supplyItem => supplyItem.id);
          setNewId(ids);

          const details = res.supplyItems.filter(item => {
            return item.title === 'Shirts' && item.hasOwnProperty('maxNumberAllowed');
          });
          const currentStatus = details.length > 0 ? details[0].status : "";
          let OrdersId = res?.id;
          let orderNumber = res?.orderNumber;
          if (details.length > 0) {
            if (previousStatuses !== currentStatus && currentStatus === "Delivered") {
              await clothingCateNotification(empData, data, OrdersId, orderNumber);
            } else {
              setPreviousStatuses(currentStatus);
            }
          }
          setPreviousStatuses(currentStatus);

          let priorityLabel;
          switch (res.priority) {
            case "1":
              priorityLabel = "High";
              break;
            case "2":
              priorityLabel = "Medium";
              break;
            case "3":
              priorityLabel = "Low";
              break;
            default:
              priorityLabel = "Unknown";
          }

          const finaleData = {
            ...res,
            priority: priorityLabel,
            orderNumber: String(res?.orderNumber).padStart(6, '0'),
            clientName: clientName,
            employeeName: employeeName,
            deliveryStatus: deliveryStatus
          };

          setRequestData(finaleData);
          setAllSupplies(finaleData?.supplyItems);

          commonContext?.setLoader(false);
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
    } else {
      setRequestData({});
      setAllSupplies([]);
      commonContext?.setLoader(false);
    }
  };

  useEffect(() => {
    setSelectedIds([]);
    setSelectedStatus('');
    setSelectAllChecked(false);
    getRequestData(requestId);
  }, [requestId]);

  useEffect(() => {
    const loadNotes = async () => {
      if (!requestData?.id) {
        setNotes([{ message: 'No ID provided' }]);
        return;
      }

      fetchNotes(
        requestData?.id,
        (fetchedNotes) => {
          setNotes(fetchedNotes);
        },
        (error) => {
          console.error('Error fetching notes: ', error);
          setNotes([{ message: 'Error fetching notes' }]);
        }
      );
    };

    loadNotes();
  }, [requestData?.id])

  const handleNote = (newNote) => {
    setNotes([...notes, newNote]);
  };

  const fetchAndStoreCategories = async (items) => {
    for (const item of items) {
      if (item?.parentCategoryId) {
        await getCategories((categoriesData) => {

          if (Array.isArray(categoriesData)) {
            const category = categoriesData.find(cate => cate?.id === item.parentCategoryId);
            const title = category?.title;

            if (title) {
              setCategories(prevCategories => ({
                ...prevCategories,
                [item.parentCategoryId]: title,
              }));
            }
          } else {
            console.error('Expected categoriesData to be an array, but got:', categoriesData);
          }
        });
      }
    }
  };

  const fetchAndStoreCategoriesForEmail = async (res) => {
    const newCategoryMap = {};

    let categoriesData = [];
    try {
      categoriesData = await getCategories();
    } catch (error) {
      console.error("Error fetching categories:", error);
      categoriesData = [];
    }

    for (const item of res) {
      const { id: mainId, supplyItems } = item;
      if (supplyItems) {
        const categoriesForItem = [];
        for (const supplyItem of supplyItems) {
          const { parentCategoryId } = supplyItem;
          if (parentCategoryId && Array.isArray(categoriesData)) {
            try {
              const category = categoriesData.find((cate) => cate?.id === parentCategoryId);
              const title = category?.title;
              if (title) {
                categoriesForItem.push({
                  parentCategoryId,
                  title
                });
              }
            } catch (error) {
              console.error(`Error fetching category for parentCategoryId ${parentCategoryId}:`, error);
            }
          }
        }
        newCategoryMap[mainId] = categoriesForItem;
      }
    }

    setCategoryMap(newCategoryMap);
  };


  const clothingCateNotification = async (empData, data, OrdersId, orderNumber) => {
    if (!empData || !data) {
      console.error("Missing empData or data");
      return;
    }
    let orderId = String(orderNumber).padStart(6, '0');
    const supply = true;
    const clientName = clientData ? clientData[0].label : null;
    const employee = empData.find(emp => emp.value === data.createdBy);
    const name = employee ? employee.label : '';
    const email = employee ? employee.email : '';
    const formattedMessage = `Your clothing (shirt) request has been completed and delivered.`;
    const EmailMessage =
      `Hello
     Your request for your company shirts has been completed and your item(s) have been delivered. 
       If there are any issues with these items, please contact your support team.
     Thank You
     Support Team`

    const notifications = [
      {
        Type: "PushNotification",
        Message: formattedMessage,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name
          }
        ],
        extData: { type: "Supplies", id: OrdersId }
      },
      {
        Type: "Email",
        Message: EmailMessage,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];
    await clothingSaveNotification(notifications, supply, orderId, clientName);
  }

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      let updatedSelectedIds;

      if (prevSelectedIds.includes(id)) {
        updatedSelectedIds = prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        updatedSelectedIds = [...prevSelectedIds, id];
      }

      const allIds = newId;
      const allSelected = allIds.length > 0 && allIds.every(id => updatedSelectedIds.includes(id));

      setSelectAllChecked(allSelected);
      return updatedSelectedIds;
    });
  };

  const handleSelectAllChange = () => {
    const allIds = newId;

    if (selectAllChecked) {
      setSelectedIds([]);
    } else {
      setSelectedIds(allIds);
    }

    setSelectAllChecked(!selectAllChecked);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsMenuOpen(false);
  };

  const handleStatusSelect = async (status) => {
    try {
      setSelectedStatus(status);
      handleMenuClose();

      await updateSupplyItemsStatus(
        requestData.id,
        status,
        selectedIds,
        (data) => {
          if (data) {
            setSelectedIds([]);
            setSelectedStatus('');
            setSelectAllChecked(false);
            getRequestData(requestData.id);
          }
        },
        (error) => {
          console.error('Failed to update status:', error);
        }
      );
    } catch (error) {
      console.error('Error handling status select:', error);
    }
  };

  const allStatuses = ['Ordered', 'Shipped', 'Delivered', 'Back Ordered', 'Cancel Item'];

  const Columns = [
    // { id: "checkbox", numeric: true, label: "", isSortable: false },
    {
      id: "categories",
      numeric: false,
      disablePadding: true,
      label: "Categories",
      isSortable: false,
    },
    {
      id: "supplies",
      numeric: false,
      disablePadding: false,
      label: "Suplies",
      isSortable: false,
    },

    {
      id: "deliveryDate",
      numeric: false,
      disablePadding: false,
      label: "Est. Delivery Date",
      isSortable: false,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      isSortable: false,
    },
    {
      id: "checkbox",
      label: (
        <Checkbox
          checked={selectAllChecked}
          onChange={handleSelectAllChange}
        />
      ),
      numeric: false,
      disablePadding: false,
      isSortable: false,
    }
  ];

  const sendRejectNotification = async () => {
    let name;
    let email;
    let orderId = String(data?.orderNumber).padStart(6, '0');
    const supply = true;
    const clientName = clientData ? clientData[0].label : null;
    const employee = empData.find(emp => emp.value === data.createdBy);
    if (employee) {
      name = employee.label;
      email = employee.email;
    }

    let updatedTitles = [];
    const category = categoryMap[data.id];
    if (category) {
      updatedTitles = data.supplyItems.map((supplyItem, index) => {
        const categoryItem = category.find(cat => cat.parentCategoryId === supplyItem.parentCategoryId);
        const title = categoryItem ? `${categoryItem.title} - ${supplyItem.title}` : `${supplyItem.title}`;
        return `${index + 1}. ${title} (${supplyItem.qty})`;
      });
    }

    const updatedTitlesStr = updatedTitles.join('\n');

    const notifications = [
      {
        Type: "PushNotification",
        Message: `Your supply request OrderID ${orderId} has been cancelled. Please check your email.`,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name
          }
        ]
      },
      {
        Type: "Email",
        Message: `Your supply request OrderId ${orderId} has been canceled by the support team.\n 
          Please contact us to resubmit a new request or discuss this order with your support team.\n\n
          Details:\n 
          ${updatedTitlesStr}`,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];
    await saveNotification2(notifications, supply, orderId, clientName);
  };

  const getDeliveryStatus = (supplyItems) => {
    if (!supplyItems || !Array.isArray(supplyItems)) {
      return 'Invalid Supplies';
    }

    const statuses = supplyItems.map(item => item.status);

    const hasDelivered = statuses.includes('Delivered');
    const hasShipped = statuses.includes('Shipped');
    const hasOrdered = statuses.includes('Ordered');
    const hasBackOrdered = statuses.includes('Back Ordered');
    const hasCancelItem = statuses.includes('Cancel Item');

    if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
      return 'Full Delivery';
    }
    if (statuses.every(status => status === 'Cancel Item')) {
      return 'cancelled';
    }
    if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
      return 'Partial Delivery';
    }
    if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
      return 'Full Shipment';
    }
    if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
      return 'Partial Shipment';
    }
    if (hasOrdered && hasCancelItem) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Ordered')) {
      return 'In Process';
    }
    if (statuses.every(status => status === 'Back Ordered')) {
      return 'Back Ordered';
    }
    if (hasOrdered && hasBackOrdered) {
      return 'In Process';
    }
    return 'In Process';
  };

  const handleDateIconClick = (id) => {
    if (openDatePickerId === id) {
      setShowDatePicker(false);
      setOpenDatePickerId(null);
    } else {
      setOpenDatePickerId(id);
      setShowDatePicker(true);
    }
  };

  const handleDateChangeConfirmed = (id, date) => {
    const utcDate = dayjs(date).toISOString();
    updateDeliveryDate(requestData.id, id, utcDate);
    setShowDatePicker(false);
  };

  const handleDateChangeCancelled = () => {
    setShowDatePicker(false);
  };

  const handleArchive = async () => {
    const formData = {
      archive: true
    }
    await updateArchive(requestData.id, formData);
    setDialogOpen(true);
  }

  const handleCloseDialog = async () => {
    setDialogOpen(false);
    navigate(-1);
  };

  const isUndelivered = requestData.supplyItems && requestData.supplyItems.every(item => item.status === 'Delivered' || item.status === 'Cancel Item');

  const isSelected = selectedIds.length > 0;

  const updateDeliveryDate = async (requestId, itemId, date) => {
    try {
      await updateSupplyItemDate(
        requestId,
        itemId,
        date,
        (data) => {
          if (data) {
            getRequestData(requestId);
          }
        },
        (error) => {
          console.error("Failed to update status:", error);
        }
      );
    } catch (error) {
      console.error("Error in handleUpdateStatus:", error);
    }
  };


  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "categories":
        if (rowindex !== 0 && categories[rcellItem?.parentCategoryId] !== categories[requestData.supplyItems[rowindex - 1]?.parentCategoryId]) {
          return <span>{categories[rcellItem?.parentCategoryId]}</span>
        }
        else if (rowindex === 0) {
          return <span>{categories[rcellItem?.parentCategoryId]}</span>
        } else {
          return "";
        }
      case "supplies":
        return `${rcellItem?.title} (${rcellItem?.qty})`;
      case "deliveryDate":
        const data = convertFirestoreTimestampToDate(rcellItem?.deliveryDate);
        const dates = dayjs(data).format('YYYY-MM-DD').isValid;
        return (
          <>
            <span>
              {rcellItem?.deliveryDate ? formatDate(rcellItem?.deliveryDate) : "---"}
            </span>

            <IconButton className="p-5" >
              <DateRangeIcon className="color-primary" style={{ padding: 0 }} fontSize="small" onClick={() => handleDateIconClick(rcellItem?.id)} />
            </IconButton>
            {showDatePicker && openDatePickerId === rcellItem?.id && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DesktopDatePicker']}>
                  <DesktopDatePicker
                    value={data ? dayjs(data) : dayjs(dates).local()}
                    onAccept={(date) => handleDateChangeConfirmed(rcellItem?.id, date)}
                    onClose={() => {
                      handleDateChangeCancelled();
                      setOpenDatePickerId(null);
                    }}
                    format="YYYY-MM-DD"
                  />
                </DemoContainer>
              </LocalizationProvider>
            )}
          </>
        );
      case "status":
        return rcellItem.status;
      case "checkbox":
        return (
          <CheckBoxComponent
            rcellItem={rcellItem}
            rowIndex={rowindex}
            handleCheckboxChange={handleCheckboxChange}
            isChecked={selectedIds.includes(rcellItem?.id)}
          />
        );
      default:
        return rcellItem[hcellItem?.id];
    }
  };
  const datePickerProps = { openDatePickerId, showDatePicker, setShowDatePicker, setOpenDatePickerId, updateDeliveryDate };
  const checkProps = {
    isSelected, handleMenuClick, anchorEl, isMenuOpen, handleMenuClose, allStatuses, selectedStatus, handleStatusSelect,
    isUndelivered, handleArchive, selectAllChecked, handleSelectAllChange
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Supplies"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Supplies Manage Request", to: "/supplies/cleaning/manage" },
          ]}
          BreadCrumbCurrentPageTitle={"View Supplies Request"}
        />

        <div className={`sectionBox`}>
          {!isMobile && !isLandscape && (
            <div className="d-flex flex-justify-end">
              <Button
                color={isSelected ? "primary" : ""}
                className="mr-20"
                variant="contained"
                disabled={!isSelected}
                onClick={handleMenuClick}
              >
                Action
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
              >
                {allStatuses.map(status => (
                  <MenuItem
                    key={status}
                    selected={status === selectedStatus}
                    onClick={() => handleStatusSelect(status)}
                  >
                    {status}
                  </MenuItem>
                ))}
              </Menu>
              <Button
                color={isUndelivered ? "primary" : ""}
                className="mr-20"
                variant="contained"
                onClick={isUndelivered ? handleArchive : null}
                disabled={!isUndelivered}
              >
                Archive
              </Button>
            </div>
          )}

          <div className={isMobile || isLandscape ? "" : "p-18"}>

            {isMobile || isLandscape ? <SupplyRequestDetailsResponsive requestData={requestData} /> : <SupplyRequestDetails requestData={requestData} />}

            <ManageSuppliesDetailsResponsive
              rows={allSupplies}
              headCells={Columns}
              hidePagination={true}
              renderRowCell={renderRowCell}
              supplies={true}
              requestData={requestData}
              categories={categories}
              datePickerProps={datePickerProps}
              checkProps={checkProps}
              selectedIds={selectedIds}
              handleCheckboxChange={handleCheckboxChange}
            />

            <NotesSection
              notes={notes}
              onAddNote={handleNote}
              employee={empName}
              createdBy={userProfile?.id}
              orderId={requestData?.id}
              setNotes={setNotes}
              data={data}
              empData={empData}
              clientData={clientData}
              commonContext={commonContext}
            />

          </div>
        </div>
      </Container>
      <SuccessDialog open={dialogOpen} onClose={handleCloseDialog} />
    </>
  );
};

export default ManageSuppliesRequestDetails;
