// import firebase from "firebase/app";

// // Add the Firebase services that you want to use
// import "firebase/auth";
// import "firebase/storage";
// import "firebase/firestore";
// const firebase_auth = firebase;

// let firebaseConfig = {};

// if (process.env.REACT_APP_CUSTOM_ENV === "prod") {
//   firebaseConfig = {
//     apiKey: "AIzaSyAlm8PnimWaSJcvDpgSgLfoGaGw6FwnAXs",
//     authDomain: "csmg-a0cd2.firebaseapp.com",
//     projectId: "csmg-a0cd2",
//     storageBucket: "csmg-a0cd2.appspot.com",
//     messagingSenderId: "993717203711",
//     appId: "1:993717203711:web:ea7fd8b20da1c55fe412ca",
//   };
  
// } else {
//   firebaseConfig = {
//     apiKey: "AIzaSyCQu0ku4myhKsSAsQ5BX6vseHJ9bPCyAvc",
//     authDomain: "csmg-dev-dc546.firebaseapp.com",
//     projectId: "csmg-dev-dc546",
//     storageBucket: "csmg-dev-dc546.appspot.com",
//     messagingSenderId: "301736105925",
//     appId: "1:301736105925:web:9abf7ed2b39c9b0e85ddac",
//     measurementId: "G-BW78JG2P7G",
//   };
 
// }
// // csmg-dev-dc546@appspot.gserviceaccount.com
// // ./google-cloud-sdk/bin/gcloud auth activate-service-account --key-file=./csmg-dev-dc546-f2eb8404567b.json
// // ./google-cloud-sdk/bin/gcloud config set project csmg-a0cd2 
// // ./google-cloud-sdk/bin/gcloud config set project csmg-dev-dc546
// // ./google-cloud-sdk/bin/gcloud beta firestore export gs://csmg-a0cd2.appspot.com/export-21Dec2023 --project csmg-a0cd2
// // service-301736105925@gcp-sa-firestore.iam.gserviceaccount.com

// // service-993717203711@gcp-sa-firestore.iam.gserviceaccount.com
// //./google-cloud-sdk/bin/gcloud beta firestore export gs://csmg-a0cd2.appspot.com/export-21122023
// // ./google-cloud-sdk/bin/gcloud beta firestore import gs://csmg-a0cd2.appspot.com/export-21Dec2023 --project csmg-dev-dc546
// // ./google-cloud-sdk/bin/gcloud auth login
// // ./google-cloud-sdk/bin/gcloud beta firestore operations list

// // gsutil iam ch serviceAccount:csmg-dev-dc546@appspot.gserviceaccount.com:roles/storage.admin \  gs://csmg-a0cd2.appspot.com

// // Ensure that you do not login twice.
// if (!firebase.length) {
//   firebase.initializeApp(firebaseConfig);
//   firebase_auth.initializeApp(firebaseConfig, "authuser");
// } else {
//   firebase.app(); // if already initialized, use that one
//   firebase_auth.app();
// }
// export { firebase, firebase_auth };

// // export { firebase_auth as firebase };

import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 
let firebaseConfig = {};

if (process.env.REACT_APP_CUSTOM_ENV === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyAlm8PnimWaSJcvDpgSgLfoGaGw6FwnAXs",
    authDomain: "csmg-a0cd2.firebaseapp.com",
    projectId: "csmg-a0cd2",
    storageBucket: "csmg-a0cd2.appspot.com",
    messagingSenderId: "993717203711",
    appId: "1:993717203711:web:ea7fd8b20da1c55fe412ca",
  };
  
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCQu0ku4myhKsSAsQ5BX6vseHJ9bPCyAvc",
    authDomain: "csmg-dev-dc546.firebaseapp.com",
    projectId: "csmg-dev-dc546",
    storageBucket: "csmg-dev-dc546.appspot.com",
    messagingSenderId: "301736105925",
    appId: "1:301736105925:web:9abf7ed2b39c9b0e85ddac",
    measurementId: "G-BW78JG2P7G",
  };
}

let app;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}


const firebase_auth = getAuth(app);
const firebase = getFirestore(app);
const firebase_storage = getStorage(app);

export { app, firebase_auth, firebase, firebase_storage };