const Columns = [
  {
    id: "createdOn",
    numeric: false,
    disablePadding: false,
    label: "Date",
    isSortable: true,
  },
  {
    id: "group",
    numeric: false,
    disablePadding: false,
    label: "Group",
    isSortable: true,
  },
  {
    id: "Type",
    numeric: false,
    disablePadding: false,
    label: "Method",
    isSortable: true,
  },
  {
    id: "Message",
    numeric: false,
    disablePadding: false,
    label: "Notification Message",
    isSortable: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    rowclassName: "text-right",
    className: "text-right pr-30",
  },
];

export { Columns };
