const Columns = [
  {
    id: 'switch',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    isSortable: true,
  },
  {
    id: 'groupName',
    numeric: false,
    disablePadding: true,
    label: 'Channel Name',
    isSortable: true,
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Channel Type',
    isSortable: true,
  },
  {
    id: 'NoOfMembers',
    numeric: false,
    disablePadding: true,
    label: 'No. of Memebers',
    isSortable: true,
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    isSortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
    rowclassName: 'text-right',
    className: 'text-right pr-30',
  },
];

const ColumnsRes = [
  {
    id: 'type',
    numeric: false,
    disablePadding: true,
    label: 'Channel Type',
    isSortable: true,
  },
  {
    id: 'NoOfMembers',
    numeric: false,
    disablePadding: true,
    label: 'No. of Memebers',
    isSortable: true,
  },
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    isSortable: true,
  },
  {
    id: 'switch',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    isSortable: true,
    isCollapsible:true,
  }
];

export { Columns, ColumnsRes };
