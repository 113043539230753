import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "22px 20px 8px 20px",
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "800",
    flex: "1",
    paddingLeft: "3px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={`${classes.root} ${props.modaltitlewrapclasses}`}
      {...other}
    >
      <Typography variant="h6" className={`${classes.dialogTitle}`}>
        {children}
      </Typography>
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  dividers: {
    borderColor: theme.palette.secondaryBorder,
    borderBottom: "none",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DialogComponent(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    props?.ModalActionsSave && props.ModalActionsSave();
    setOpen(false);
  };

  const handleCancel = () => {
    props?.ModalActionsCancel && props.ModalActionsCancel();
    setOpen(false);
  };

  return (
    <div className={props?.buttonWrapClassName}>
      <Button
        size="small"
        variant="text"
        onClick={handleClickOpen}
        className={`${props?.buttonClassName} themeButton`}
        {...props?.buttonProps}
      >
        {props.buttonLabel}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="thameSecondaryDialog"
        maxWidth={"md"}
        {...props?.dialogProps}
      >
        <DialogTitle
          
          id="customized-dialog-title"
          onClose={handleClose}
          modaltitlewrapclasses={props.modaltitlewrapclasses}
        >
          {props?.ModalTitle}
        <Divider/>
        </DialogTitle>
        <DialogContent className="thameDialogContent">
          {React.cloneElement(props.ModalContent, { onClose: handleClose })}
        </DialogContent>
        {(props?.ModalActionsSave || props?.ModalActionsCancel) && (
          <DialogActions>
            {props?.ModalActionsSave && (
              <Button autoFocus onClick={handleSave} color="primary">
                {props.SaveLabel ? props.SaveLabel : "Save"}
              </Button>
            )}
            {props?.ModalActionsCancel && (
              <Button autoFocus onClick={handleCancel} color="primary">
                {props.CancelLabel ? props.CancelLabel : "Save"}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
