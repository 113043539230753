const Columns = [
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
        isSortable: true,
    },
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Description",
        isSortable: true,
    },
    { id: "action", numeric: false, disablePadding: true, label: "Action", rowclassName:"text-right", className:"text-right pr-30" },
];

const ColumnsRes = [
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Description",
        isSortable: true,
    },
    { id: "action", numeric: false, disablePadding: true, label: "Action", rowclassName:"text-right", className:"text-right pr-30" },
];
export { Columns, ColumnsRes };
  