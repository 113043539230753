import React, { useState } from "react";
import { Container, Grid, Typography, TextField } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import Appointments from "./components/Appointments";
import EmpRequests from "./components/EmpRequests";
import moment from "moment";
import Issues from "./components/Issues";
import Notifications from "./components/Notifications";
import ClientSummary from "./components/ClientSummary";
import EmployeeSummary from "./components/EmployeeSummary";
import PieChartOutlineOutlinedIcon from '@mui/icons-material/PieChartOutlineOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import NotificationsList from "./components/Communications/NotificationsList";
import Alerts from "./components/Communications/Alerts";
import IssuesList from "./components/Communications/IssuesList";

const HomeContainer = (props) => {
  const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));
  const [chanFilterDate, setChanFilterDate] = useState(moment().format("YYYY-MM-DD"));

  const handleChannelDateChange = (newValue) => {
    setChanFilterDate(newValue.target.value);
  };
  const handleDateChange = (newValue) => {
    setFilterDate(newValue.target.value);
  };
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Dashboard"
          showBreadcrumb={[{ label: "Home", to: "/" }]}
          showLastSeen={true}
        />
        <div className="dashBoardCustom">
        <Grid container alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="h6">
              <PieChartOutlineOutlinedIcon 
                className="color-primary svg-icon mr-10"
              />
              Overview / Summary
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className="d-flex groupsearch2 float-right">
              <div className=" mh-12">
                <TextField
                  name="date"
                  value={filterDate}
                  fullWidth={true}
                  size="small"
                  variant="outlined"
                  className={`field`}
                  type={"date"}
                  inputProps={{
                    className: "p-10",
                    min: moment().add(-30, "days").format("YYYY-MM-DD"),
                    max: moment().add(15, "days").format("YYYY-MM-DD"),
                  }}
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mb-40" alignItems="stretch">
          <Grid item xs={12} md={3} lg={3}>
            <div className={`sectionBox h-stretch8 radius shadow`}>
              <Appointments />
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <div className={`sectionBox h-stretch8 radius shadow`}>
              <EmpRequests />
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <div className={`sectionBox h-stretch8 radius shadow`}>
              <Issues />
            </div>
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <div className={`sectionBox h-stretch8 radius shadow`}>
              <Notifications />
            </div>
          </Grid>
        </Grid>

        <Grid container spacing={3} className="mb-40">
          <Grid item xs={12} md={12} lg={12}>
            <ClientSummary />
          </Grid>
        </Grid>
        <Grid container spacing={3} className="mb-40">
          <Grid item xs={12} md={12} lg={12} container alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="h6">
                <ChatBubbleOutlineOutlinedIcon
                  className="color-primary mr-10 svg-icon"
                />
                Communication Channel
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="d-flex groupsearch float-right">
                <div className=" mh-12">
                  <TextField
                    name="date"
                    value={chanFilterDate}
                    fullWidth={true}
                    size="small"
                    variant="outlined"
                    className={`field`}
                    type={"date"}
                    inputProps={{
                      className: "p-10",
                      min: moment().add(-30, "days").format("YYYY-MM-DD"),
                      max: moment().add(15, "days").format("YYYY-MM-DD"),
                    }}
                    onChange={handleChannelDateChange}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className={`sectionBox h-stretch9 radius shadow`}>
              <Alerts />
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className={`sectionBox h-stretch9 radius shadow`}>
              <NotificationsList />
            </div>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <div className={`sectionBox h-stretch9 radius shadow`}>
              <IssuesList />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12} className="mb-20">
            <EmployeeSummary />
          </Grid>
        </Grid>
        </div>
      </Container>
    </>
  );
};

export default HomeContainer;
