/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormControl, FormGroup, Input } from "@material-ui/core";
import TaskItemCheckbox from "./TaskItemCheckbox";
import CreateClientTask from "./CreateClientTask";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";

const TaskLists = (props) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [taskList, setTaskList] = React.useState([]);

  useEffect(() => {
    setTaskList(props.taskList);
  }, [props.taskList]);

  let filterdSearchList = taskList?.filter((titem) =>
    String(titem?.title)
      .toLowerCase()
      ?.includes(String(searchTerm).toLowerCase())
  );
  return (
    <>
      <h5 className="stepSectionTitle color-black d-flex flex-center mt-30 pt-20 pb-10">
        {props?.label}
        <div className="ml-auto">
          {props?.search === true && (
            <Input
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              placeholder="Search Task"
              variant="outline"
              disabled={props?.disabled}
            />
          )}
          {props?.custom === true && (
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"flatbutton iconbutton p-0 icbutton linkbutton"}
              buttonProps={{ size: "small", variant: "text", disabled: props?.disabled }}
              buttonLabel={"Add Custom Task"}
              ModalTitle={`Add Custom Task`}
              ModalContent={
                <CreateClientTask
                  Task={{}}
                  shifttitle = {props.shifttitle}
                  onCreateNewTask={props?.onCreateNewTask}
                />
              }
            />
          )}
        </div>
      </h5>
      <div className={`stepSectionContent pl-15 scrollable`}>
        <FormControl component="div" className={"checkboxfieldwrap w-100"}>
          {filterdSearchList?.length > 0 ? (
            <div className={"checkboxGroupwrap column-2"}>
              <FormGroup row>
                {filterdSearchList.map((TaskItem, index) => (
                  <TaskItemCheckbox
                    key={"taskitemcomp" + index}
                    index={index}
                    Task={TaskItem}
                    selected={props?.checkselected(TaskItem?.taskId)}
                    handleTasksCheckChange={props?.handleTasksCheckChange}
                    removeTask={props?.removeTask}
                    onEditTask={props?.onEditTask}
                    shifttitle = {props.shifttitle}
                    disabled={props?.disabled}
                  />
                ))}
              </FormGroup>
            </div>
          ) : (
            <div>No {props.custom === true ? 'Custom' : 'Standard'} Tasks Found</div>
          )}
        </FormControl>
      </div>
    </>
  );
};

export default TaskLists;
