/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./channelColumns";
import TableToolbar from "../../components/Table/TableToolbar";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { getFormatedDate } from "../../services/helpers";
import {
  getChannelList,
  updateChannel,
  deleteChannel,
} from "../../services/apiservices/messages";
import ManagePagesCommonCard from "../../components/ResponsiveCardComponent/ManageListPages/ManageListPagesResponsive";
import { useMediaQuery } from "@mui/material";
import { ConfirmationDeleteChannelDialog } from "../../components/DialogComponent/SupplyDataDialog";

const ManageChannels = (props) => {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const [allChannels, setAllChannels] = React.useState([]);
  const [searchTerm, setsearchTerm] = React.useState("");
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = React.useState(null);
  const [opens, setOpens] = React.useState(null);
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [openData, setOpenData] = React.useState(null);

  const fetchChannelList = async () => {
    commonContext?.setLoader(true);
    getChannelList(
      {},
      (res) => {
        if (res) {
          setAllChannels(res);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
  };
  useEffect(() => {
    fetchChannelList();
  }, []);


  const deleteChannelHandler = async (chItem) => {
    commonContext?.setLoader(true);
    deleteChannel(
      chItem['id'],
      (res) => {
        setDialogConfirmOpen(false);
        setOpenData(null);
        commonContext?.setLoader(false);
        fetchChannelList();
      },
      (resError) => {
        commonContext?.setLoader(false);
        setDialogConfirmOpen(false);
        setOpenData(null);
        fetchChannelList();
      }
    );
};


  const redirectToCreateChannel = (chId) => {
    if (typeof chId !== "undefined" && chId !== "")
      history("/message-board/create-channel/" + chId);
    else history("/message-board/create-channel");
  };
  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "NoOfMembers":
        return rcellItem['employee'] ? rcellItem['employee'].length: 0;
      case "createdOn":
        return getFormatedDate(rcellItem['createdOn']);
      case "action":
        return (
          <>
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateChannel(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="Detele"
              className="danger-color"
              size="small"
              onClick={() => handleDialogOpen(rcellItem)}
            >
              <DeleteIcon />
            </IconButton>
           
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      (isMobile || isLandscape) ? (
      <div className="d-flex flex-direction-column pl-5">
        <div className="d-flex flex-justify-center mb-15">
        <Button
            className="w-norm clientsummry pv-5 fw-bold"
            style={{fontSize:"15px", borderRadius:"2px"}}
            size={"small"}
            variant="contained"
            color="primary"
            onClick={() => redirectToCreateChannel()}
          >
            Add New Channel
        </Button>
        </div>
        <div className="w350">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label="Search"
            variant="outlined"
            placeholder="Search Channel"
            className="input customSearch mb-15"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
      </div>
      ) : (
      <div className="d-flex groupsearch">
        <div className="mh-5">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search Channel..."
            className="input"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
        <Button
          className="flatbutton w-norm"
          size={"small"}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateChannel()}
        >
          Add New Channel
        </Button>
      </div>
    )
    );
  };

  const OnSwitchChange = (rowitem, newstatus) => {
    commonContext?.setLoader(true);
    
    updateChannel(
      rowitem?.id,
      { status: newstatus },
      (res) => {
        if (res?.data?.status === true) {
          commonContext?.setLoader(false);
          fetchChannelList();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        commonContext?.setLoader(false);
        fetchChannelList();
      }
    );
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.acuityId)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.type)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.groupName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allChannels?.filter((item) => checkRowSearch(searchTerm, item))
      : allChannels;


  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };

  const handelTooltipClicks = (id) => {
    setOpens(opens === id ? null : id);
  }

  const handleDialogClose = async () => {
    setDialogConfirmOpen(false);
    setOpenData(null);
  };

  const handleDialogOpen = async (chItem) => {
    setDialogConfirmOpen(true);
    setOpenData(chItem);
  };

  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Channels"
          showBreadcrumb={[
            { label: "Home", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Channels"}
          showLastSeen={true}
        />

        <div className={`sectionBox2`}>
          <TableToolbar
            title={isMobile || isLandscape ? "" : "Manage Channels"}
            rightComponent={tableRightComponent()}
          />
          {(!isMobile && !isLandscape) &&
            <ThemeTable
              rows={filteredList}
              headCells={Columns}
              hidePagination={true}
              renderRowCell={renderRowCell}
              OnSwitchChange={OnSwitchChange}
            />
          }
          {(isMobile || isLandscape) && (
            <>
              {filteredList.map((record, index) => {
                const isOpen = expanded === record?.id;
                return (
                  <ManagePagesCommonCard
                    index={index}
                    record={record}
                    isOpen={isOpen}
                    handleExpandClick={handleExpandClick}
                    redirectToDetails={redirectToCreateChannel}
                    deleteChannelHandler={handleDialogOpen}
                    columns={ColumnsRes}
                    handelTooltipClicks={handelTooltipClicks}
                    opens={opens}
                    Channel={true}
                  />
                );
              })}
            </>
          )}
        </div>
      
      <ConfirmationDeleteChannelDialog 
        open={dialogConfirmOpen} 
        onConfirm={() => deleteChannelHandler(openData)} 
        onClose={handleDialogClose} 
        chItem={openData}
      />

      </Container>
    </>
  );
};
export default ManageChannels;
