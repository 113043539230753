
import React from "react";
import { Card, Divider, List, Typography } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DialogComponent from "../../DialogComponent/DialogComponent";
import DeleteGroup from "../../../app/Employee/Groups/DeleteGroup";
import EditGroup from "../../../app/Employee/Groups/EditGroup";
import { SuccessGroupDeleteDialog } from "../../DialogComponent/SupplyDataDialog";
import AlertsDetails from "../../../app/Alerts/AlertsDetails";



const EmployeeGroupLabel = ({ title }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        <span>{title}</span>
    </Typography>
);

const AlertsLabel = ({ record }) => {
    const clientName = record && typeof record === 'object' ? record.clientname : record;
    return (
        <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
            <span>{clientName}</span>
        </Typography>
    );
};

const ManageGroupsCommonCard = ({ index, record, fetchGroupsList, columns, Group, Alerts, alertList }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [recordsDelete, setRecordsDelete] = React.useState(null);

    const handleCloseDialog = async () => {
        setDialogOpen(false);
    };

    return (
        <React.Fragment key={`record-${index}`}>
            <Card style={{ boxShadow: "none", borderRadius: "0px", border: "1px solid #a9a9a9", width: "97%" }} className="mb-20">
                <div className="d-flex flex-center flex-space-between pv-5 pl-15" style={{ backgroundColor: "#efefef" }}>
                    {Group ? <EmployeeGroupLabel title={record?.title} /> : null}
                    {Alerts ? <AlertsLabel record={record} /> : null}
                    <div>
                        {Group ? <DialogComponent
                            buttonWrapClassName={"d-inline"}
                            buttonClassName={"iconButton color-primary"}
                            buttonProps={{ size: "small" }}
                            buttonLabel={<MoreHorizRoundedIcon className="color-primary" style={{marginLeft:"8px"}}  fontSize="large" />}
                            ModalTitle={"Update Group"}
                            ModalContent={
                                <EditGroup group={record} onSuccess={fetchGroupsList} />
                            }
                        /> :
                            <DialogComponent
                                buttonWrapClassName={"d-inline"}
                                buttonClassName={"iconButton color-primary"}
                                buttonProps={{ size: "small" }}
                                buttonLabel={<MoreHorizRoundedIcon className="color-primary" style={{marginLeft:"8px"}} fontSize="large" />}
                                ModalTitle={"Alert Details"}
                                ModalContent={<AlertsDetails item={record} />}
                            />}
                    </div>
                </div>
                <Divider />
                <List className="mv-15">
                    {columns.map((column, columnIndex) => (
                        <div className="d-flex flex-space-between ph-25 pv-10" key={`column-${columnIndex}`}>
                            {column.id === "empname" && Alerts ? (
                                <Typography variant="body" style={{ fontSize: '16px', fontWeight: "600", color: "#404040" }}>
                                    {column?.label}
                                </Typography>
                            ) : (
                                <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}>
                                    {column?.label}
                                </Typography>
                            )}
                            <div className="w-45">
                                {column.id === "action" && Group ? (
                                    <>
                                        <DialogComponent
                                            buttonClassName={"color-danger p-0 "}
                                            buttonProps={{ size: "small" }}
                                            buttonLabel={<><DeleteOutlineIcon style={{marginLeft:"-5px"}}/> <span className="color-Darkblue text-underLine"> Delete </span></>}
                                            ModalTitle={"Delete Group"}
                                            ModalContent={
                                                <DeleteGroup group={record} onSuccess={fetchGroupsList} setRecordsDelete={setRecordsDelete} setDialogOpen={setDialogOpen} />
                                            }
                                        />
                                        <SuccessGroupDeleteDialog open={dialogOpen} onClose={handleCloseDialog} group={recordsDelete} />
                                    </>
                                ) : column.id === "type" && Alerts ? (
                                    <Typography variant="body" style={{ color: "#404040" }}>
                                        <>{(alertList.find((itm) => itm?.value === record[column?.id]))?.label}</>
                                    </Typography>
                                ) : column.id === "empname" && Alerts ? (
                                    <Typography variant="body" style={{ fontSize: '16px', fontWeight: "600", color: "#404040" }}>
                                        <span>{record[column?.id]}</span>
                                    </Typography>
                                ) : (
                                    <Typography variant="body" style={{ color: "#404040" }}>
                                        <span>{record[column?.id]}</span>
                                    </Typography>
                                )}
                            </div>
                        </div>
                    ))}
                </List>
            </Card>
        </React.Fragment>
    );
};

export default ManageGroupsCommonCard;