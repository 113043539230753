/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState,useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { List, ListItem, Badge } from "@material-ui/core";
import { useLocation } from 'react-router-dom';
import AuthContext from "../../hooks/authContext";
import ChildMenuItem from "./ChildMenuItem";
const MenuItem = (props) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { item, handleDrawerToggle } = props;
  
  const role = auth?.userProfile?.role === 'Employee' ? auth?.userProfile?.employeerole.toLowerCase() : auth?.userProfile?.role;
  const [clickedLink, setClickedLink] = useState('');

  const hasAccess = item?.roles.includes(role) ? true : false;
  
  const hasChild = (typeof item?.childItems !== 'undefined' && item?.childItems?.length > 0) ? true : false;
  let isChildActive = false;

  let isShowBadge = false;
  if(item.to === '/message-board') {
      if(!auth.isReadPrivate || !auth.isReadPublic) {
        isShowBadge = true;
      }
  }
  if(item.to === '/supplies') {
      if(auth.pendingSupplyStatus.length > 0 || auth.highPrioritySupply.length > 0) {
        isShowBadge = true;
      }
  }
  if(item.to === '/issues/manage') {
      if(auth.issueCount.length > 0) {
        isShowBadge = true;
      }
  }

  const [showChild, setShowChild] = useState(false);
  const renderMenuChilditem = (item) => {
    return showChild === true && <List key={"subitem"+item} className="submenuitems">
      {item?.childItems?.map((child,index) => {
        return <ChildMenuItem key={"childmenuitemwrap"+index} item={child} index={index} handleDrawerToggle={handleDrawerToggle}/>
      })}
    </List>
  }
  const showChildMenu = (e) => {
    const dataID = e.target.getAttribute('dataid');
    if(dataID) {
      e.preventDefault();
      if(showChild) {
        setClickedLink('');
      } else {
        setClickedLink(dataID);
      }
      
      setShowChild(!showChild);
    } else {
      setClickedLink('');
    }
  }
  const checkactive = () => {
    if (hasChild && !showChild) {
      let allChildroutes = item?.childItems.map((citem) => citem?.to);
      isChildActive = allChildroutes.includes(location.pathname);
      if(isChildActive) {
        setClickedLink(item.to);
      }
      setShowChild(isChildActive);
    }
  }
  useEffect(() => { 
    checkactive();
  },[])
 
  return ( hasAccess && <>
      <ListItem key={item.label + props.index}>
        {hasChild === true ? 
          <Link to={item.to} dataid={item.to} onClick={showChildMenu} className={item?.classes ? item?.classes :''}>{item?.icon && item.icon}{isShowBadge && <Badge color="secondary" variant="dot" className="MenuBadgeIcon" />} {item.label}<ChevronRightIcon dataid={item.to} onClick={showChildMenu} className={`menuRightArrowIcon ${clickedLink === item.to?'menuRightARrowDownIcon':''}`}/></Link>
          : <NavLink className={`${item?.classes ? item?.classes :''}`} onClick={showChildMenu} to={item.to}>{item?.icon && item.icon} {item.label}</NavLink >
        }
        {typeof item?.childItems !== 'undefined' && item?.childItems?.length > 0 && renderMenuChilditem(item)}
      </ListItem>
    </>
  );
};

export default MenuItem;
