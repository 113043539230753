/**
 * Manage Shifts
 * collection Shift
 */

import { collection, getDocs, doc, getDoc, addDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { COLLECTIONS } from '../config';
import { firebase } from '../firebase-config';

const auth = getAuth();
const collectionName = COLLECTIONS.SHIFTS;

/**
 * Get list of shifts
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getShiftsList(formData, onSuccess, onError) {
  const colRef = collection(firebase, collectionName);
  
  try {
    const querySnapshot = await getDocs(colRef);
    let results = [];
    
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, shiftId: doc.id, ...data });
    });
    
    onSuccess && onSuccess(results);
  } catch (error) {
    console.log('Error getting documents: ', error);
    onError && onError(error);
  }
}

/**
 * Get one shift by ID
 * @param {string} id
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getOneShift(id, onSuccess, onError) {
  const docRef = doc(firebase, collectionName, id);
  
  try {
    const docSnapshot = await getDoc(docRef);
    
    if (!docSnapshot.exists()) {
      onError && onError({ status: false, error: 'Shift not found' });
      return;
    }
    
    let shiftId = docSnapshot.id;
    let data = docSnapshot.data();
    onSuccess && onSuccess({ status: true, data: { ...data, shiftId } });
  } catch (error) {
    onError && onError({ status: false, error });
  }
}

/**
 * Add a new shift
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addShift(formData, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;
  
  try {
    const docRef = await addDoc(collection(firebase, collectionName), {
      ...formData,
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
    });
    
    const responseGroupItem = { ...formData, shiftId: docRef.id };
    onSuccess && onSuccess({ status: true, data: responseGroupItem });
  } catch (error) {
    console.log(`ERROR: ${collectionName} ${error}`);
    onError && onError({ status: false, error });
  }
}

/**
 * Update an existing shift
 * @param {string} id
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateShift(id, formData, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;
  const docRef = doc(firebase, collectionName, id);
  
  const docContent = {
    ...formData,
    updatedAt: new Date().getTime(),
    updatedBy: currentUserId,
  };
  
  try {
    await setDoc(docRef, docContent, { merge: true });
    onSuccess && onSuccess({ status: true, message: 'Updated successfully' });
  } catch (error) {
    if (error.code === 'not-found') {
      onError && onError({ status: false, message: 'Not Found' });
    }
    onError && onError({ status: false, error });
  }
}

/**
 * Delete a shift by ID
 * @param {string} id
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteShift(id, onSuccess, onError) {
  const docRef = doc(firebase, collectionName, id);
  
  try {
    await deleteDoc(docRef);
    onSuccess && onSuccess({ status: true, message: 'Delete successful' });
  } catch (error) {
    onError && onError({ status: false, error });
  }
}
