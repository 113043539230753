import { collection, serverTimestamp, getDocs, doc, deleteDoc, getDoc, setDoc } from 'firebase/firestore';
import { firebase } from '../firebase-config';
import { COLLECTIONS } from "../config";
import { getAuth } from 'firebase/auth';
import { generateUniqueId } from '../helpers';
import moment from 'moment';

const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
};

export async function saveAppointment(appointmentData, recurringDates) {
    try {
        const auth = getAuth();
        // const currentUserId = auth.currentUser?.uid;
        const currentUserId = appointmentData?.userId;

        if (!currentUserId) {
            throw new Error("User is not authenticated.");
        }

        
        const appointmentsCollectionRef = collection(firebase, COLLECTIONS.APPOINMENTS);
        

        if (appointmentData.type === "Recurring Appointment") {
            if (typeof appointmentData.numberOfTimes === 'undefined') {
                throw new Error("numberOfTimes is required for recurring appointments.");
            }
            if (!recurringDates || recurringDates.length === 0) {
                throw new Error("recurringDates is required for recurring appointments.");
            }
            const appointments = [];

            for (const date of recurringDates) {
                const formattedDate = formatDate(date);
                const newId = generateUniqueId();
                const customId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;

                const docRef = doc(appointmentsCollectionRef, customId);
                await setDoc(docRef, {
                    ...appointmentData,
                    date: formattedDate,
                    id: customId,
                    forms: [
                        {
                            values: [
                                {
                                    name: "Shift Name",
                                    value: "Morning"
                                }
                            ]
                        }
                    ],
                    userId: currentUserId,
                    createdAt: serverTimestamp(),
                });

                appointments.push({
                    ...appointmentData,
                    id: customId,
                });
            }

            return {
                success: true,
                data: appointments,
            };
        } else {
            if (typeof appointmentData.numberOfTimes === 'undefined') {
                appointmentData.numberOfTimes = 1;
            }
            const newId = generateUniqueId();
            const customId = `${newId}-${appointmentData.clientId}-${currentUserId}-${moment().format('YYYY-MM-DD')}`;

            const docRef = doc(appointmentsCollectionRef, customId);
            await setDoc(docRef, {
                ...appointmentData,
                userId: currentUserId,
                forms: [
                    {
                        values: [
                            {
                                name: "Shift Name",
                                value: "Morning"
                            }
                        ]
                    }
                ],
                id: customId,
                createdAt: serverTimestamp(),
            });

            return {
                success: true,
                data: [{
                    ...appointmentData,
                    id: customId,
                }],
            };
        }
    } catch (error) {
        console.error("Error saving appointments: ", error);
        return {
            success: false,
            error: error.message || "An error occurred while saving the appointments",
        };
    }
}


export async function getAllAppoinment() {
    try {
        const appointmentsCollectionRef = collection(firebase, COLLECTIONS.APPOINMENTS);
        const snapshot = await getDocs(appointmentsCollectionRef);

        const appointments = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));

        return {
            success: true,
            appointments,
        };

    } catch (error) {
        console.error("Error getting docs", error); return {
            success: false,
            error: error.message || "An error occurred while getting the appointment",
        };
    }
}

export async function getOneAppointment(id) {
    try {
        const appointmentDocRef = doc(firebase, COLLECTIONS.APPOINMENTS, id);
        const appointmentDoc = await getDoc(appointmentDocRef);

        if (!appointmentDoc.exists()) {
            return {
                success: false,
                error: "Appointment not found",
            };
        }

        return {
            success: true,
            appointment: {
                id: appointmentDoc.id,
                ...appointmentDoc.data(),
            },
        };

    } catch (error) {
        console.error("Error getting appointment", error);
        return {
            success: false,
            error: error.message || "An error occurred while getting the appointment",
        };
    }
}


export async function DeleteAppointment(id) {
    if (!id) {
        return {
            success: false,
            error: "No appointment ID provided",
        };
    }

    try {
        const appointmentDocRef = doc(firebase, COLLECTIONS.APPOINMENTS, id); 
        
        await deleteDoc(appointmentDocRef);

        return {
            success: true,
            message: "Appointment deleted successfully",
        };

    } catch (error) {
        console.error("Error deleting document:", error);
        return {
            success: false,
            error: error.message || "An error occurred while deleting the appointment",
        };
    }
}