import React from "react";
import { Grid } from "@material-ui/core";
import { FormatedDates } from "../../../../services/helpers";

const SupplyRequestDetails = (props) => {
  const { requestData } = props;
  return (
    <div className="RequestDetailsWrap bg-gray p-8 mb-20">
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item className="d-flex flex-space-between w-100 supplysWidth gridBorder bg-gray">
          <h4>Order ID</h4>
          <h4>Order Date</h4>
          <h4>Client Name</h4>
          <h4>Employee Name</h4>
          <h4>Priority</h4>
          <h4>Delivery Status</h4>
        </Grid>
        <Grid item className="d-flex flex-space-between w-100 supplysWidth bg-white">
          <p>{requestData?.orderNumber}</p>
          <p>{FormatedDates(requestData?.dateOfRequest)}</p>
          <p>{requestData?.clientName}</p>
          <p>{requestData?.employeeName}</p>
          <p>{requestData?.priority}</p>
          <p>{requestData?.deliveryStatus}</p>
        </Grid>
      </Grid>
    </div>
  );
};

export default SupplyRequestDetails;

export function SupplyRequestDetailsResponsive (props) {
  const {requestData} = props
  return (
    <div className="d-flex flex-space-between RequestDetailsWrapResponsive pv-15 pl-20 pr-30 mb-20">
        <div className="d-flex flex-start flex-direction-column flex-space-around">
          <div className="mb-8">
          <h4>Order ID</h4>
          <p>{requestData?.orderNumber}</p>
          </div>
          <div className="mb-8">
          <h4>Client Name</h4>
          <p>{requestData?.clientName}</p>
          </div>
          <div className="mb-8">
          <h4>Employee Name</h4>
          <p>{requestData?.employeeName}</p>
          </div>      
        </div>
        <div className="d-flex flex-start flex-direction-column flex-space-around">
        <div className="mb-8">
          <h4>Order Date</h4>
          <p>{FormatedDates(requestData?.dateOfRequest)}</p>
          </div>
          <div className="mb-8">
          <h4>Priority</h4>
          <p>{requestData?.priority}</p>
          </div>
          <div className="mb-8">
          <h4>Delivery Status</h4>
          <p>{requestData?.deliveryStatus}</p>
          </div>
        </div>
    </div>
  );
};