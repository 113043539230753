import React, { useState, useEffect } from "react";
import { getClientsList } from "../../../services/apiservices/client";
import MultiSelectem from "../../../components/SelectField/MultiSelectem";
import EmployeeStepsContext from "../../../hooks/EmployeeStepsContext";

const ClientTeam = (props) => {
  const [clientsList, setClientsList] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const stepsContext = React.useContext(EmployeeStepsContext);
  const { handleInputChange } = props;

  const { stepFormErrors, stepFormValues } = stepsContext;
  const onselectClient = (e) => {
    setSelectedClients(e);
    let allvalues = e.map((itrv) => {

      if (!itrv.clientid) {
        return { clientid: itrv, shiftid: "" }
      } else return itrv;
    });
    handleInputChange({ target: { name: "clients", value: allvalues } });
  };

  const callgetClientListapi = async () => {
    await getClientsList(
      {},
      (res) => {
        if (res) {
          setClientsList(res);
        } else {
          setClientsList([]);
        }
      },
      (resError) => {
        setClientsList([]);
      }
    );
  };
  useEffect(() => {
    callgetClientListapi();
  }, []);
  useEffect(() => {
    let cli = {};
    stepFormValues?.clients?.forEach((itmc) => {
      cli[itmc.clientid] = itmc.shiftid;
    });
    
    let clit = stepFormValues?.clients?.map((itmc) => {

      if (itmc.clientid) {
        return itmc?.clientid;
      } else return itmc
    });


    setSelectedClients(stepFormValues?.clients ? clit : '');

  }, [stepFormValues?.clients]);
  let filtercle = clientsList.filter((item) => {
    return selectedClients.includes(item.id);
  });  
  return (
    <>
      <h5 className="stepSectionTitle">Client Team</h5>
      {stepFormValues?.employeerole === "Client Team" ? (
        <>
          <div className="stepSectionContent">
            {typeof stepFormErrors?.clients !== "undefined" &&
              stepFormErrors?.clients !== "" && (
                <span className="error color-danger">
                  {stepFormErrors?.clients}
                </span>
              )}
            {clientsList?.length === 0 ? (
              <p>
                <span className="error color-danger">
                  There aren't any clients created
                </span>
              </p>
            ) : (
              <MultiSelectem
                label="Select clients"
                value={[...selectedClients]}
                className={`field`}
                name={"clients"}
                onChange={(e) => onselectClient(e)}
                selectAllOption={true}
                options={[
                  ...clientsList?.map((item) => {
                    return {
                      value: item?.id,
                      label: `${item?.clientName}`,
                    };
                  }),
                ]}
              />
            )}
          </div>

          <div className="clear">
            {filtercle.map((itclient) => {
              const keys = Object.keys(itclient.shiftData);
              let opt = [];
              keys.forEach((key, index) => {

                opt.push({
                  value: key,
                  label: `${itclient.shiftData[key][Object.keys(itclient.shiftData[key])[0]].title}${(itclient.shiftData[key][Object.keys(itclient.shiftData[key])[0]].titlen) > 0 ? itclient.shiftData[key][Object.keys(itclient.shiftData[key])[0]].titlen : ''}`,
                })
              }
              );
              return (
                <>
                </>
              );
            })}

          </div>
        </>
      ) : (<p>
        <span className="error color-danger">
          This section only available for Employee Role -- Client Team.
        </span>
      </p>)}
    </>
  );
};

export default ClientTeam;
