const Columns = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order Id",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: true,
  },
  {
    id: "supplies",
    numeric: false,
    disablePadding: true,
    label: "Supplies",
    isSortable: true,
  },
  {
    id: "dateOfRequest",
    numeric: false,
    disablePadding: false,
    label: "Date Of Request",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Approve/Reject",
    // rowclassName: "text-right",
    // className: "text-right pr-30",
  },
];

const ColumnsRes = [
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name",
    isSortable: true,
  },
  {
    id: "supplies",
    numeric: false,
    disablePadding: true,
    label: "Supplies",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "dateOfRequest",
    numeric: false,
    disablePadding: false,
    label: "Date Of Request",
    isSortable: true,
    isCollapsible: true,
  }
];

export { Columns, ColumnsRes };
