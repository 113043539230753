// import { createMuiTheme } from "@material-ui/core/styles";
import { createTheme } from '@material-ui/core/styles'
const theme = createTheme({
  palette: {
    primary: { 500: "#078037" },
    secondaryBorder: "#DEEDEA",
  },
  spacing: 5,
  typography: {
    fontFamily: [
      "Raleway",
      "MarkOT",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": "Raleway",
      },
    },
  },
});

export default theme;
