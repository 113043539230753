import { collection, getDocs, doc, getDoc, addDoc, setDoc, deleteDoc, arrayRemove } from "firebase/firestore";
import { firebase, firebase_auth } from "../firebase-config";
import { COLLECTIONS } from '../config';

const collectionName = COLLECTIONS.GROUPS;

const handleError = (error, onError) => {
  console.error("Error: ", error);
  onError && onError({ status: false, error: error.message || error });
};

export const getGroupsList = async (options = {}, onSuccess, onError) => {
  try {
    const querySnapshot = await getDocs(collection(firebase, COLLECTIONS.GROUPS));
    const results = querySnapshot.docs.map(doc => ({
      groupId: doc.id,
      ...doc.data(),
    }));
    if (typeof onSuccess === 'function') {
      onSuccess(results);
    } else {
      console.error("onSuccess is not a function");
    }
  } catch (error) {
    handleError(error, onError);
  }
};

export const getOneGroup = async (id, onSuccess, onError) => {
  try {
    const docRef = doc(firebase, collectionName, id);
    const docSnapshot = await getDoc(docRef);
    if (!docSnapshot.exists()) {
      return handleError({ message: "Group not found" }, onError);
    }
    const data = { ...docSnapshot.data(), groupId: docSnapshot.id };
    onSuccess && onSuccess({ status: true, data });
  } catch (error) {
    handleError(error, onError);
  }
};

export const addGroup = async (formData, onSuccess, onError) => {
  try {
    const currentUserId = firebase_auth.currentUser.uid;
    const docRef = await addDoc(collection(firebase, collectionName), {
      ...formData,
      employeeId: [],
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
    });
    const responseGroupItem = { ...formData, groupId: docRef.id };
    onSuccess && onSuccess({ status: true, data: responseGroupItem });
  } catch (error) {
    handleError(error, onError);
  }
};

export const updateGroup = async (id, formData, onSuccess, onError) => {
  try {
    const currentUserId = firebase_auth.currentUser.uid;
    const docRef = doc(firebase, collectionName, id);
    const docContent = {
      ...formData,
      updatedAt: new Date().getTime(),
      updatedBy: currentUserId,
    };
    await setDoc(docRef, docContent, { merge: true });
    onSuccess && onSuccess({ status: true, message: "Updated successfully" });
  } catch (error) {
    handleError(error, onError);
  }
};

export const deleteGroup = async (id, onSuccess, onError) => {
  try {
    const docRef = doc(firebase, collectionName, id);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.exists()) {
      return handleError({ message: "Group not found" }, onError);
    }

    const data = docSnapshot.data();
    if (data?.employeeId?.length > 0) {
      const promises = data.employeeId.map(empid =>
        setDoc(doc(firebase, COLLECTIONS.EMPLOYEE, empid), {
          group: arrayRemove(id)
        }, { merge: true })
      );
      await Promise.all(promises);
    }

    await deleteDoc(docRef);
    onSuccess && onSuccess({ status: true, message: "Delete successful" });
  } catch (error) {
    handleError(error, onError);
  }
};
