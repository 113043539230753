/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import confirm from "../../../../components/DialogComponent/confirm";
import { EventEmitter } from "../../../../services/event.service";
const useStyles = makeStyles((theme) => ({
  shiftTitle: {
    display: "flex",
    alignItems: "center",
    padding: "15px",
    backgroundColor: "rgb(0,128,65)",
    color: "#fff",
  },
  selectwrap: {
    background: "#fff",
    color: "#000",
    minWidth: "170px",
  },
  shiftTaskListwrap: {
    display: "flex",
    alignItems: "center",
  },
  shiftTaskList: {
    marginTop: "10px",
    marginBottom: "20px",
    flex: "1",
    fontSize: "16px",
    lineHeight: "20px",
  },
}));
function SummaryListItem(props) {
  const classes = useStyles();
  const { item, List } = props;


  useEffect(() => {
    let taskslist = [];
    List?.map((listitem) => {
      taskslist = [...taskslist, ...listitem?.tasks];

      return listitem?.tasks;
    });

    getTasksTitles(taskslist);
  }, [List, item]);

  const getTasksTitles = (tasks) => {
    let allTasks = [...props?.standardTasks, ...props?.customtaskList];

    let selectedTasks = [
      ...allTasks.filter((sitem) => tasks?.includes(sitem?.taskId)),
    ];

    selectedTasks = [
      ...selectedTasks.map((sitem) => {
        return sitem?.title;
      }),
    ];

    if (selectedTasks?.length > 0) {
      return selectedTasks;

    } else {
      return "";
    }
  };

  const OnEditItem = (item) => {


    if (item) {
      EventEmitter.dispatch("EditSummaryFrequencyItem", item);
      EventEmitter.dispatch("scrolltoCreateTaskItemTop");
    }
  };
  const removeTaskGroupSummaryList = (item) => {


    if (item && props?.removeSummaryListItem) {
      props?.removeSummaryListItem(item);

    }

  };
  return (
    <>
      {List?.map((itmett) => {

        let allTasks = [...props?.standardTasks, ...props?.customtaskList];

        let selectedTasks = [
          ...allTasks.filter((sitem) => itmett?.tasks?.includes(sitem?.taskId)),
        ];

        selectedTasks = [
          ...selectedTasks.map((sitem) => {
            return sitem?.title;
          }),
        ];

        return (<> <div className={classes.shiftTitle}>

          {itmett.shift.title}{itmett.shift.titlen > 0 ? -itmett.shift.titlen : ''}-{itmett.frequency}
        </div>
          <div className={classes.shiftTaskListwrap}>
            <div className={classes.shiftTaskList}>{selectedTasks?.join(", ")}</div>
            <div className="actions ml-auto"><IconButton
              className="fs-14"
              size="small"
              variant="text"
              onClick={() => OnEditItem(itmett)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </IconButton>
              <IconButton
                size="small"
                className="color-danger fs-14"
                onClick={() =>
                  confirm(
                    "Are you sure you want to delete the task group?"
                  ).then(() => {
                    removeTaskGroupSummaryList(itmett);
                  })
                }
              >
                <FontAwesomeIcon icon={faTimesCircle} />
              </IconButton></div>
          </div>

        </>

        )

      })}

    </>
  );
}

export default function TaskSummaryList(props) {
  let all_frequencies = props?.taskFrequencies ? props?.taskFrequencies : props?.SummaryFrequencyListarr;
  return (
    <ul>
      {all_frequencies.map((listItem, index) => {
        let List = props?.SummaryList.filter(
          (sitem) => sitem?.frequency === listItem
        );
        return (
          List?.length > 0 && <li key={"summarylistitem" + index}>
            <SummaryListItem
              item={listItem}
              List={List ? List : []}
              {...props}
            />
          </li>
        );
      })}
    </ul>
  );
}
