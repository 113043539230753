import { Container } from "@material-ui/core";
import React from "react";

const Footer = () => (
  <div className={'footerWrap'}>
    <Container maxWidth={false} disableGutters={true}>
      {""}
    </Container>
  </div>
);

export default Footer;
