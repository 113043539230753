import {  firebase  } from '../firebase-config';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { COLLECTIONS } from '../config';

export async function getalertsList(onSuccess, onError) {
  const alertsQuery = query(
      collection(firebase, COLLECTIONS.ALERT),
      orderBy('createdOn', 'desc')
  );

  try {
      const querySnapshot = await getDocs(alertsQuery);

      let results = [];
      querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
      });

      onSuccess && onSuccess(results);
  } catch (error) {
      console.error('Error getting documents: ', error);
      onError && onError(error);
  }
}

// export async function getalertsListByParam(param, onSuccess, onError) {
//   firebase
//     .firestore()
//     .collection(collection)
//     .orderBy('createdOn', 'desc')
//     .get()
//     .then((querySnapshot) => {
//       let results = [];
//       querySnapshot.forEach((doc) => {
//         let data = doc.data();
//         results.push({ id: doc.id, ...data });
//       });

//       onSuccess && onSuccess(results);
//     })
//     .catch((error) => {
//       console.log('Error getting documents: ', error);
//       onError && onError(error);
//     });
// }
