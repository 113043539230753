import React, { useEffect, useState } from "react";
import { getFormatedDate } from "../../services/helpers";

const NotificationDetails = (props) => {
  const [itemdata, setItemdata] = useState({});

  useEffect(() => {
    setItemdata(props.item);
  }, [props.item]);
  const emplist = itemdata?.Employee ? itemdata?.Employee?.map((eitem) => eitem.name) : [];
  const empliststring = emplist.join(', ')
  const getClientNames = (clientData) => {
    if (Array.isArray(clientData)) {
      return clientData.map((item) => item.name).filter(Boolean);
    } else if (clientData && typeof clientData === 'object' && clientData.name) {
      return [clientData.name];
    }
    return [];
  };
  
  const clientList = getClientNames(itemdata?.Client);
  const clientString = clientList.join(', ');
  return (
    <div className="p-15">
      <p>
        <strong>date: </strong> {getFormatedDate(itemdata?.createdOn)}
      </p>
      <p>
        <strong>group: </strong> {itemdata?.Group?.title}
      </p>
      <p>
        <strong>method: </strong> {itemdata?.Type === "Push" ? "SMS" : itemdata?.Type === "PushNotification" ? "Push Notification": itemdata?.Type === "Both" ? "All (Push Notification, Email, SMS)" : itemdata?.Type}
      </p>
      <p>
        <strong>client: </strong> {clientString}
      </p>
      <p>
        <strong>message: </strong> <div className="nl2br">{itemdata?.Message}</div>
      </p>
      <p>
        <strong>Employees / Receipents: </strong> {empliststring}
      </p>
      
    </div>
  );
};
export default NotificationDetails;
