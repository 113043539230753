import Axios from "axios";
import { EventEmitter } from "./event.service";
import { getLoginToken, clearLoginToken } from "./storage";
const axiosInstance = Axios.create();

const errorHandler = (error) => {
  let token = getLoginToken();
  if (token !== "" && error?.response?.status === 401) {
    EventEmitter.dispatch("logoutUser");
    clearLoginToken();
    window.location.href = "/login";
  }
};

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    errorHandler(error);
    return Promise.reject(error);
  }
);
export default axiosInstance;
