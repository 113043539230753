import axiosInstance from '../axios';
import { getLoginToken } from '../storage';
import { BaseUrl } from '../config';
import { firebase } from '../firebase-config';
import { collection, getDocs, query, where, doc, setDoc } from 'firebase/firestore';
const moment = require("moment");

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  withCredentials: true,
};

export async function acuitygetEmployees(formData, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const res = await axiosInstance.get(`${BaseUrl}acuitygetEmployees`, {
      params: { ...formData },
      headers: { ...defaultHeaders, ...authHeaders },
    });
    // console.log(res);
    onSuccess && onSuccess(res);
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Get Appontments list from acuity throu cloud function
 * @param {*} formData : may be use to pass data for filter
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function acuitygetAppointments(formData, onSuccess, onError) {
  const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
  try {
    const res = await axiosInstance.get(
      `${BaseUrl}acuitygetAppointmentsAdminnew`,
      {
        params: { ...formData },
        headers: { ...defaultHeaders, ...authHeaders },
      }
    );

    let resultsStore = [];
    const colRef = collection(firebase, "timesheets");
    const appointmentQuery = query(colRef, where("appointmentDate", "in", [moment(formData.minDate).format("MMMM D, YYYY")]));

    const querySnapshot = await getDocs(appointmentQuery);
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      resultsStore.push({ id: doc.id, ...data });
    });

    const resData = res?.data?.data;
    const cancelledAppts = [];
    const filterData = resData?.filter((acuityAppt) => {
      const findEle = resultsStore.find(
        (storeAppt) => {
          if (storeAppt?.apoointmentDetails?.canceled) {
            cancelledAppts.push(storeAppt.id);
          }
          return acuityAppt.id.toString() === storeAppt.appointmentId.toString();
        }
      );

      if (findEle) {
        if (acuityAppt?.noShow) {
          cancelledAppts.push(findEle.id);
        }
        return false;
      }
      if (acuityAppt?.noShow) return false;

      return true;
    });

    cancelledAppts?.map((storApptId) => {
      const refToDel = doc(colRef, storApptId); // Create a document reference for deletion
      setDoc(refToDel, {}, { merge: true }); // Use merge to avoid deleting the document outright
      return storApptId;
    });

    await Promise.all(filterData.map(async (appointmentData) => { // Use Promise.all to handle async operations in parallel
      const employee = [];
      const employeeQuery = query(
        collection(firebase, "users"),
        where("acuityId", "in", [
          appointmentData.calendarID,
          appointmentData.calendarID.toString(),
        ])
      );

      const empSnapshot = await getDocs(employeeQuery);
      empSnapshot.forEach((doc) => {
        let data = doc.data();
        employee.push({ id: doc.id, ...data });
      });

      if (employee.length > 0) {
        const clients = [];
        const clientQuery = query(
          collection(firebase, "clientsNew"),
          where("firstName", "==", appointmentData.firstName ? appointmentData.firstName.trim() : ""),
          where("lastName", "==", appointmentData.lastName ? appointmentData.lastName.trim() : "")
        );

        const clientSnapshot = await getDocs(clientQuery);
        clientSnapshot.forEach((doc) => {
          let data = doc.data();
          clients.push({ id: doc.id, ...data });
        });

        if (clients.length <= 0) {
          const altClientQuery = query(
            collection(firebase, "clientsNew"),
            where("clientName", "==", appointmentData.firstName)
          );

          const altClientSnapshot = await getDocs(altClientQuery);
          altClientSnapshot.forEach((doc) => {
            let data = doc.data();
            clients.push({ id: doc.id, ...data });
          });
        }

        if (clients.length > 0 && !appointmentData?.noShow) {
          const clientData = clients[0];
          const shiftFields = appointmentData.forms[0].values;
          let ShiftName = "Morning";
          shiftFields.forEach((item) => {
            if (item.name.trim() === "ShiftName" || item.name.trim() === "Shift Name") {
              ShiftName = item.value;
            }
          });

          let clientShiftData = {};
          let ShiftId = "";
          for (let shiftDataKey in clientData.shiftData) {
            const shiftData = clientData.shiftData[shiftDataKey];
            for (let shift in shiftData) {
              if (shiftData[shift].title === ShiftName) {
                ShiftId = shiftDataKey;
                clientShiftData = { ...clientShiftData, ...shiftData };
              }
            }
          }

          let finalResult = [];
          const finalQuery = query(colRef, where("appointmentId", "in", [
            appointmentData.id,
            appointmentData.id.toString(),
          ]));

          const finalSnapshot = await getDocs(finalQuery);
          finalSnapshot.forEach((doc) => {
            let data = doc.data();
            finalResult.push({ id: doc.id, ...data });
          });

          let timeSheetData = {
            appointmentId: appointmentData.id.toString(),
            clientId: clients[0].id,
            email: employee[0].email,
            isWorking: false,
            completed: false,
            lastUpdatedAt: firebase.FieldValue.serverTimestamp(),
            location: { lat: 32.0, lng: 32.0 },
            shiftId: ShiftId,
            tasks: clientShiftData,
            userId: employee[0].id,
            appointmentDateTime: appointmentData.datetime,
            appointmentDate: appointmentData.date,
            appointmentDuration: appointmentData.duration,
            apoointmentDetails: appointmentData,
          };

          if (finalResult.length > 0) {
            await setDoc(doc(colRef, finalResult[0].id), timeSheetData);
          } else {
            const id = `${timeSheetData.appointmentId}-${timeSheetData.clientId}-${timeSheetData.shiftId}-${timeSheetData.userId}-${new Date().toISOString().split("T")[0]}`;
            timeSheetData.createdAt = firebase.FieldValue.serverTimestamp();
            await setDoc(doc(colRef, id), timeSheetData, { merge: true });
          }
        }
      }
    }));

    onSuccess && onSuccess(res);

  } catch (res_1) {
    console.log("res_1>>", res_1);
    onError && onError(res_1);
  }
}
