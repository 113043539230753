import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import UpdateIcon from '@mui/icons-material/Update';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { Grid } from "@material-ui/core";
import { getDocCount } from "../../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../../services/config";
import styles from "../../dashboard.module.scss";
import moment from 'moment';
import { Link } from "react-router-dom";
import { getAllEmployee } from "../../../../services/apiservices/employee";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "36ch",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

export default function IssuesList() {
  const classes = useStyles();
  const [issueData, setIssueData] = useState([]);
  const [status, setStatus] = useState("High");
  const [employees, setEmployees] = useState([]);
  const statusList = [
    { label: "Urgent", value: "High" },
    { label: "Normal", value: "Low" },


  ];
  const onChangeInput = (e) => {
    setStatus(e.target.value);
  };
  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.ISSUES,
      (res) => {
        setIssueData(res ? res : []);

      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
    getAllEmployee((result) => {
      setEmployees(result ? result?.data : []);
     },
     (resError) => {
      console.log("reserror", resError);
    })

  }, []);

  const chagedtime = (time) => {
    const timeno = moment(time);
    var duration = ` ${moment(timeno).format('MMM DD, YY')}${'\n'} ${moment(timeno).format('hh:mm a')} `

    return duration;
  }

  const sortedIssues = issueData
    .filter(issue => issue.priority === status && issue.status !== "Closed")
    .sort((a, b) => {
      const issueNumberComparison = b.issueNumber - a.issueNumber;
      if (issueNumberComparison !== 0) {
        return issueNumberComparison;
      }
      return new Date(b.createdOn) - new Date(a.createdOn);
    })
    .slice(0, 3);

  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe paddal">
            Issues
          </p>
        </Grid>
        <Grid item xs={12} sm={5}>
          <SingleSelect
            value={status}
            className={`field newonr`}
            name={"status"}
            onChange={(e) => onChangeInput(e)}
            options={statusList}
          />
        </Grid>
      </Grid>
      <List dense className='ulhideis'>
        {sortedIssues.map((value, index) => {
          const Data = employees.find(emp => emp?.id === value?.updatedBy);
          const Name = Data ? `${Data?.firstName} ${Data?.lastName}` : null;
          return (
            <ListItem dense alignItems="flex-start" className={` paddnonoti nopadd ${styles.listItem}`} key={index} >
              <ListItemAvatar>
                <Link to={"/issues/manage"}>
                <Avatar alt="Remy Sharp">{(value?.issueNumber)}</Avatar></Link>
              </ListItemAvatar>
              <ListItemText
                primary={value?.client.name ? value?.client.name : ""}
                secondary={
                  <React.Fragment>
                    <Grid container className="marisu">
                      <Grid item sm={8} md={8} >
                        <Typography
                          component="span"
                          variant="body2"
                          className={`${classes.inline} ${'capit'}`}
                          color="textPrimary"
                        >
                          {value?.employee.name}
                        </Typography>
                      </Grid>
                      <Grid item sm={14} md={4} className={`text-right ${value?.priority === 'High' ? 'color-danger' : 'color-dark'}`}>
                        &#9679; {value?.priority ? value?.priority : ""}
                      </Grid>
                    </Grid>
                    <Typography
                      component="span"
                      variant="body2"
                      className={'capitis'}
                      color="textPrimary"
                    >{value?.description ? value?.description.substring(0, 30) + "..." : ""}</Typography>
                    <Grid container className="marisu">

                      <Grid item sm={12} md={12} className="">
                        <Typography variant="body2" className="margne">Last Updated By : {Name}</Typography>
                        <Typography variant="body2" className="margne"><UpdateIcon className='greencol iconcoln svg-icon' />Last Updated : {chagedtime(value.openedOn)}</Typography>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }
              />
            </ListItem>
          );
        })}
      </List>
      <div className="mv-25"></div>
      <p className="viewLinks">
        <Link className="link" to="/issues/manage">View all Issues</Link></p>
    </>
  );
}
