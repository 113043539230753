/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { TablePagination } from "@material-ui/core";
import { getComparator, stableSort, FormatedDates } from "../../services/helpers";
import { useMediaQuery } from "@mui/material";

const ThemeTablen = (props) => {
  const [state, setState] = useState({
    order: "asc",
    orderBy: "",
    page: 0,
    rowsPerPage: 10,
  });
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  useEffect(() => {
    setState({ ...state, page: 0 });
  }, [props, props?.filterBy]);


  const handleChangePage = (event, newPage) => {
    setState({ ...state, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    let size = parseInt(event.target.value, 10);
    setState({ ...state, page: 0, rowsPerPage: size });
  };

  const { rows, hidePagination } = props;
  let rowsList = rows?.length > 0 ? rows : [];
  const { order, orderBy, page, rowsPerPage } = state;
  const finalData = !hidePagination
    ? stableSort(rowsList, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : stableSort(rowsList, getComparator(order, orderBy));

  
  const emailAddresses = finalData[0]?.email || [];
  const formattedEmails = emailAddresses.join(', ');
  
  return (
    <>
      {!isMobile && !isLandscape && (
        <div className="mainresp">
          <div className="leftc">
            <div className="textl"><span>Status</span>{finalData[0]?.status}</div>
            <div className="textl issueWrap"><span>Issue#</span>{finalData[0]?.issuueId}</div>
            <div className="textl"><span>Client Name</span>{finalData[0]?.clientName}</div>
            <div className="textl"><span>Raise by</span>{finalData[0]?.employeeName}</div>
          </div>
          <div className="rightc">
            <div className="textl"><span>Date Opened</span>{FormatedDates(finalData[0]?.openedOn)}</div>
            <div className="textl"><span>Last Updated</span>{FormatedDates(finalData[0]?.updatedAt)}</div>
            <div className="textl"><span>Priorty</span>{finalData[0]?.priority}</div>
            <div className="textl"><span>Esclated</span>{finalData[0]?.escalated_to_admin ? "Yes" : "No"}</div>
            <div className="textl">{formattedEmails}</div>
          </div>
        </div>
      )}
      {(isMobile || isLandscape) &&
        <div className="mainresp d-flex flex-direction-column">
          <div className="d-flex flex-space-between mb-10">
            <span className="theameColors fw-bold">Status</span>
          <div className="clientsummry theameVColors">{finalData[0]?.status}</div>
          </div>
          <div className=" d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Issue#</span>
            <div className="clientsummry theameVColors">{finalData[0]?.issuueId}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Client Name</span>
            <div className="clientsummry theameVColors">{finalData[0]?.clientName}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Raise by</span>
            <div className="clientsummry theameVColors">{finalData[0]?.employeeName}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Date Opened</span>
            <div className="clientsummry theameVColors">{FormatedDates(finalData[0]?.openedOn)}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Last Updated</span>
            <div className="clientsummry theameVColors">{FormatedDates(finalData[0]?.updatedAt)}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
            <span className="theameColors fw-bold">Priorty</span>
            <div className="clientsummry theameVColors">{finalData[0]?.priority}</div>
          </div>
          <div className="d-flex flex-space-between mv-10">
          <span className="theameColors fw-bold">Esclated</span>
          <div className="clientsummry theameVColors">{finalData[0]?.escalated_to_admin ? "Yes" : "No"}</div>
          </div>
          <div className="d-flex mv-10">
          <div className="theameVColors">{formattedEmails}</div>
          </div>
        </div>
      }

      {!hidePagination && rows?.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};
export default ThemeTablen;
