import React from "react";
import { List } from "@material-ui/core";
import { sidebarMenuList } from "../../services/routes";
import MenuItem from "./MenuItem";


const SidebarMenu = ({props, handleDrawerToggle}) => {
  
  return (
    <List className="sidebarMenuWrap">
      {sidebarMenuList.map((item, index) => (
        <MenuItem key={"menuitemwrap"+index} item={item} index={index} handleDrawerToggle={handleDrawerToggle}/>
      ))}
    </List>
  );
};

export default SidebarMenu;
