import { Container } from "@material-ui/core";
import React, { useContext } from "react";
import AuthContext from "../../hooks/authContext";

const NotFound = () => {
  const auth = useContext(AuthContext);
  return <Container maxWidth="lg" className="sectionBox">
    <h3 className={` text-center`}>Not Found</h3>
    {auth?.status !== true && <p className={` text-center`}><a href="/login">Login</a></p>}
  </Container>
};

export default NotFound;
