import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

// Function to download the Excel file
export const downloadExcel = (clientCSV, csvHeaders, wb, ws, fileName, emailSend, name, fullpage) => {
  return new Promise((resolve, reject) => {

    let file = fileName.split("_");
    excelNames(name, file, ws);

    ws.addRow([]);

    if (fullpage) {
      ws.addRow(csvHeaders);

      clientCSV.forEach(row => {
        ws.addRow(row);
      });

      ws.mergeCells('A1:H1');
      ws.mergeCells('A2:H2');
      ws.mergeCells('A3:H3');

      ws.columns.forEach(column => {
        column.width = 8;
        column.alignment = { wrapText: true };
      });
    } else {
      const finalExcelData = [
        [...csvHeaders],
        ...clientCSV,
      ];

      finalExcelData.forEach((row, index) => {
        ws.addRow(row);
      });

      ws.mergeCells('A1:H1');
      ws.mergeCells('A2:H2');
      ws.mergeCells('A3:H3');
    }

    wb.xlsx.writeBuffer().then(async (excelBuffer) => {
      let v = base64DownloaderMiscWeb(excelBuffer, fileName, emailSend);
      resolve(v);
    }).catch(error => {
      reject(error);
    });
  });
};

const excelNames = (name, file, ws) => {
  const isEmployee =  name ? 'Employee' : 'Client';
  const nameIndex = isEmployee ? 2 : 1;
  const reportName = file[0];
  const reportPeriod = file.length === 3 ? `Report Period: ${file[2]}` : '';

  const setCellValues = (index, personType) => {
    ws.getCell('A1').value = `${personType} Name: ${file[index]}`;
    ws.getCell('A1').font = { bold: true };
    ws.getCell('A2').value = `Report Name: ${reportName}`;
    ws.getCell('A2').font = { bold: true };

    if (reportPeriod) {
      ws.getCell('A3').value = reportPeriod;
      ws.getCell('A3').font = { bold: true };
    } else {
      const startDate = file[index + 1] ? moment(file[index + 1]).format('YYYY-MM-DD') : 'N/A';
      const endDate = file[index + 2] ? moment(file[index + 2]).format('YYYY-MM-DD') : 'N/A';

      const reportPeriodMessage = file[index + 1] 
        ? (file[index + 2]
            ? `Report Period: ${startDate} to ${endDate}`
            : `Report Period: ${startDate}`)
        : 'Report Period: N/A';

      ws.getCell('A3').value = reportPeriodMessage;
      ws.getCell('A3').font = { bold: true };
    }
  };

  if (file.length === 5) {
    setCellValues(nameIndex, isEmployee ? 'Employee' : 'Client');
  } else if (file.length === 4) {
    setCellValues(nameIndex - 1, isEmployee ? 'Employee' : 'Client'); // Adjust index for 4 items
  } else {
    ws.getCell('A1').value = `${isEmployee ? 'Employee' : 'Client'} Name: ${file[1]}`;
    ws.getCell('A1').font = { bold: true };
    ws.getCell('A2').value = `Report Name: ${reportName}`;
    ws.getCell('A2').font = { bold: true };
    ws.getCell('A3').value = reportPeriod || `Report Period: ${file[2]}`; // Default report period for < 4 items
    ws.getCell('A3').font = { bold: true };
  }
};

const base64DownloaderMiscWeb = (excelBuffer, fileName, emailSend) => {
  if (emailSend) {
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    return blob;
  }
  const blob = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const linkSource = URL.createObjectURL(blob);
  const downloadLink = document?.createElement('a')
    ? document?.createElement('a')
    : { href: '', download: '', click: () => true };
  downloadLink.href = linkSource;
  downloadLink.download = `${fileName}.xlsx`;
  downloadLink.click();

  return null;
};


export const downloadPDF = (clientCSV, csvHeaders, fileName, emailSend, name, fullpage) => {
  let doc;

  if (fullpage) {

    const headerCount = csvHeaders.length;
    let pageWidth, pageHeight;

    if(headerCount <= 10){
      pageWidth = 500;   
      pageHeight = 350; 
    }
    else if (headerCount > 10 && headerCount <= 48) {
      pageWidth = 700;   
      pageHeight = 500; 
    } else if (headerCount > 48 && headerCount <= 75) {
      pageWidth = 1200; 
      pageHeight = 800;
    } else if (headerCount > 75 && headerCount <= 130) {
      pageWidth = 1800; 
      pageHeight = 1200;
    } else if (headerCount > 130 && headerCount <= 170) {
      pageWidth = 2500;
      pageHeight = 1500; 
    }

    doc = new jsPDF('l', 'mm', [pageHeight, pageWidth]);

    let file = fileName.split("_");
     names(name, file, doc);

    autoTable(doc, {
      startY: 50,
      head: [[...csvHeaders]],
      body: clientCSV,
      tableWidth: 'wrap',
      styles: {
        fontSize: headerCount > 40 ? 5 : 8,
        halign: 'center'
      },
      headStyles: {
        fontSize: headerCount > 40 ? 5 : 8,
        fillColor: [41, 128, 186],
      },
      margin: { top: 40, left: 5, right: 5, bottom: 10 },
      theme: 'grid'
    });

  } else {
    doc = new jsPDF();
    let file = fileName.split("_");
     names(name, file, doc);

    autoTable(doc, {
      startY: 50,
      head: [[...csvHeaders]],
      body: clientCSV,
      styles: {
        halign: 'center'
      }
    });
  }

  if (emailSend) {
    const pdfContent = doc.output('dataurlstring');
    return pdfContent;
  } else {
    doc.save(`${fileName}.pdf`);
  }
};

const names = (name, file, doc) => {
  const role = name ? 'Employee' : 'Client';
  const nameIndex = file.length <= 4 ? 1 : 2;

  const setFontAndText = (text, x, y) => {
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(text, x, y);
  };

  setFontAndText(`${role} Name : ${file[nameIndex]} ${role}`, 14, 20);
  setFontAndText(`Report Name : ${file[0]}`, 14, 28);

  let reportPeriod = 'Report Period : N/A';

    if (file.length === 4) {
        const startDate = file[nameIndex + 1] ? moment(file[nameIndex + 1]).format('YYYY-MM-DD') : 'N/A';
        const endDate = file[nameIndex + 2] ? moment(file[nameIndex + 2]).format('YYYY-MM-DD') : 'N/A';

        reportPeriod = file[nameIndex + 1]
            ? file[nameIndex + 2]
                ? `Report Period : ${startDate} to ${endDate}`
                : `Report Period : ${startDate}`
            : 'Report Period : N/A';
    } else if (file.length === 3) {
        reportPeriod = `Report Period : ${file[2]}`;
    }

  doc.text(reportPeriod, 14, 36);
};
