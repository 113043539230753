import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { COLLECTIONS } from "../config";
import { firebase } from "../firebase-config";

const collectionName = COLLECTIONS.STANDARDTAKS;

/**
 * Get Tasks list from standardtasks collection (shown while creating client)
 * @param {*} formData 
 * @param {*} onSuccess 
 * @param {*} onError 
 */
export const getStandardTasksList = async (formData, onSuccess, onError) => {
  const colRef = collection(firebase, collectionName);
  
  try {
    const querySnapshot = await getDocs(colRef);
    const results = [];
    
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ taskId: doc.id, ...data });
    });
    
    onSuccess && onSuccess(results);
  } catch (error) {
    console.log("Error getting documents: ", error);
    onError && onError(error);
  }
};

/**
 * Get Task by id
 */
export const getStandardTask = async (id, onSuccess, onError) => {
  const colRef = collection(firebase, collectionName);
  const docRef = doc(colRef, id);
  
  try {
    const docSnapshot = await getDoc(docRef);
    
    if (!docSnapshot.exists()) {
      onError && onError({ status: false, error: "Task not found" });
      return;
    }
    
    let data = docSnapshot.data();
    onSuccess && onSuccess({ status: true, data: { taskId: docSnapshot.id, ...data } });
  } catch (error) {
    onError && onError({ status: false, error });
  }
};
