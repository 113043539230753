/**
 * Storage need to be used to store data to localstorage
 * 
 * userProfile
 */

// import Cookies from "cookies-js";
const TOKENKEY = "pcuserToken";

export function setLoginToken(token) {
  if (typeof token !== "undefined" && token !== "null" && token !== "") {
    localStorage.setItem(TOKENKEY, token);//, { expires: 2 * 60 * 24 });
  }else{
    clearLoginToken();
  }
}

export function getLoginToken() {
  let token = localStorage.getItem(TOKENKEY);
  if (typeof token !== "undefined" && token !== null && token !== "") {
    return token;
  } else {
    return '';
  }
}

export function clearLoginToken() {
  localStorage.removeItem(TOKENKEY, "");
}
