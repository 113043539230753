import React, { Suspense, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import NotFound from "../components/NotFound/NotFound";
import Unauthorized from "../components/Unauthorized/Unauthorized";
import Loader from "../components/AppLoader/Loader";
import DefaultLayouts from "../layouts/defaultLayouts";
import BlankLayouts from "../layouts/blankLayouts";
import AuthContext from "../hooks/authContext";
import { routes } from "../services/routes";
import PublicLayouts from "../layouts/publicLayouts";
/**
 * Manage App routes layout and authentication
 * @param {*} props
 * @returns
 */
const AppRoutes = (props) => {
  const auth = useContext(AuthContext);
  const role =
    auth?.userProfile?.role === "Employee"
      ? auth?.userProfile?.employeerole.toLowerCase()
      : auth?.userProfile?.role;

  const CheckSecureRoute = (route) => {
    const RouteComponent = route.component;
    if (route.secure === true) {
      if (
        auth.status === true &&
        (route?.roles.includes(role) ? true : false)
      ) {
        return <RouteComponent {...props} />;
      } else {
        return auth?.isLoading !== true && <Unauthorized />;
      }
    } else {
      return <RouteComponent {...props} />;
    }
  };

  const routerItem = (route) => {
    if (route.layout === "blank") {
      return (
        <Route
          key={route.index}
          path={route.path}
          element={
            <BlankLayouts {...props}>{CheckSecureRoute(route)}</BlankLayouts>
          }
          render={(props) => (
            <BlankLayouts {...props}>{CheckSecureRoute(route)}</BlankLayouts>
          )}
          exact={route.exact}
        />
      );
    } else if (route.layout === "public") {
      return (
        <Route
          key={route.index}
          path={route.path}
          element={
            <PublicLayouts {...props}>{CheckSecureRoute(route)}</PublicLayouts>
          }
          render={(props) => (
            <PublicLayouts {...props}>{CheckSecureRoute(route)}</PublicLayouts>
          )}
          exact={route.exact}
        />
      );
    } else {
      return (
        <Route
          key={route.index}
          path={route.path}
          element={
            <DefaultLayouts {...props} isSecure={true}>
              {CheckSecureRoute(route)}
            </DefaultLayouts>
          }
          render={(props) => (
            <DefaultLayouts {...props} isSecure={true}>
              {CheckSecureRoute(route)}
            </DefaultLayouts>
          )}
          exact={route.exact}
        />
      );
    }
  };

  const renderRoutes = () => {
    return routes.map((route) => {
      return routerItem(route);
    });
  };

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {renderRoutes()}
        <Route
          render={(props) => (
            <DefaultLayouts {...props} isSecure={true}>
              <NotFound {...props} />
            </DefaultLayouts>
          )}
        />
      </Routes>
    </Suspense>
  );
};

export const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

export default withRouter(AppRoutes);
