/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router";
import { Button, Container, Typography } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTablen from "../../../components/Table/ThemeTablen";
import CommonContext from "../../../hooks/commonContext";
import { FormatedDates, getFormatedTime } from "../../../services/helpers";
import {
  closeIssueApi,
  getOneIssue,
} from "../../../services/apiservices/issues.js";
import { DetailsColumns } from "../IssuesManageTableColumns";
import TableToolbar from "../../../components/Table/TableToolbar";
import confirm from "../../../components/DialogComponent/confirm";
import EscalateForm from "./EscalateForm";
import ViewAttachments from "./ViewAttachments";
import { getClientsList } from "../../../services/apiservices/client.js";
import { getAllEmployee } from "../../../services/apiservices/employee.js";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
const useStyles = makeStyles({
   customTooltip: {
    position: "absolute",
    margin: "auto",
    top: "70%",
    // bottom:"-50px",
    left: "176px",
    backgroundColor: '#fff',
    padding: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    zIndex: 100,
    width: "250px",
  
    '@media (max-width: 767px)': {
      width: "300px",
      height: "fit-content",
      overflowWrap: "break-word",
      top: "92%",
      left: 0,
      position: "absolute",
      margin: "auto",
      backgroundColor: '#fff',
      padding: '10px',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 100,
    },
    '&::after': { 
      content: "''",
      position: "absolute",
      top: "10px", 
      left: "-14px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "#fff transparent transparent transparent", 
      transform: "scale(2.2) rotate(90deg)",
    '@media (max-width: 767px)': {
       top: "-15px", 
       left: "10%",
       marginLeft: "5px",
       transform: "scale(2.2) rotate(180deg)",
    }, 
    },
  },
});


const RecipientsTooltip = ({ recipients, styles }) => {
  if (!recipients || recipients.length === 0) return null;

  const recipientsString = recipients.join(", ");

  return <div className={styles.customTooltip}>
    {recipientsString}</div>;
};


function IssueDetails(props) {
  let history = useNavigate();
  const { issueId } = useParams();
  const commonContext = useContext(CommonContext);
  const [IssueDetail, setIssueDetail] = useState([]);
  const [showTooltip, setShowTooltip] = useState(null);
  const styles = useStyles();
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');


  const handleMouseOver = (responseItem) => {
    setShowTooltip(responseItem);
  };

  const handleMouseLeave = () => {
    setShowTooltip(null);
  };

  const Emails = [
    "damon@procleanings.com",
    "hazel@procleanings.com",
    "nina@procleanings.com"
  ];

  const fetchIssuesDetails = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      let emails = [];
      let employee = [];
      let client = [];
      let AllEmpEmails = [];
      await getClientsList(
        {},
        (res) => {
          if (res) {
            client = res;
          }
        })
      await getAllEmployee((res) => {
        AllEmpEmails = res?.data;
        employee = res.data.filter(emp => Emails.includes(emp.email));
      })
      await getOneIssue(
        issueId,
        async (res) => {
          if (res && res.status === true) {
            const matchedClient = client.find(client => client.id === res.data.client.id);
            emails = matchedClient ? [matchedClient.contact[0].email] : [];
            let details = {
              ...res.data,
              issuueId: `${'IS-'}${String(res.data?.issueNumber).padStart(4, '0')}`,
              employeeName: res.data?.employee?.name,
              clientName: res.data?.client?.name,
              dateOfRequest: res.data?.dateOfRequest,
              clientData: emails,
              clientsDetails: [matchedClient],
              employeeData: employee,
              AllEmpEmails: AllEmpEmails
            };
            let responses =
              details?.responses &&
              [...Object.keys(details?.responses)].map((key) => {
                return { ...details?.responses[key], id: key };
              });
            responses = responses
              ? responses.sort((a, b) => (a.respondOn > b.respondOn ? 1 : -1))
              : [];
            setIssueDetail({ ...details, responses: responses });
            commonContext?.setLoader(false);
          } else {
            commonContext?.setLoader(false);
          }
        },
        (resError) => {
          console.log("reserror", resError);
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  useEffect(() => {
    fetchIssuesDetails();
  }, [issueId]);


  const getEmailDisplay = (item) => {
    if (item?.escalatedTo && item?.escalated === "Respond from Email") {
      return (
        <div className={`d-flex ${isMobile || isLandscape ? "flex-start" : "flex-center"}`} style={{ overflowWrap: "anywhere" }}>
          <div><MailIcon style={{ fontSize: 16, verticalAlign: 'middle', color: "gray" }} /></div>
          <span style={{ color: "blue", width: "90%", lineHeight: "20px" }}>{item?.respondBy?.email}</span></div>
      )
    }
    return <strong>{item?.respondBy?.name}</strong>;
  };

  const closeIssue = async () => {
    if (issueId && issueId !== "") {
      commonContext?.setLoader(true);
      await closeIssueApi(
        issueId,
        async (res) => {
          history('/issues/manage');
        },
        (resError) => {
          commonContext?.setLoader(false);
        }
      );
      commonContext?.setLoader(false);
    }
  };
  const OnClickCloseIssue = async () => {
    await confirm("Are you sure you want to Close this issue  ?").then(
      () => {
        closeIssue();
        console.log("close issue");
      },
      () => console.log("cancel!")
    );
  };

  return (
    <Container>
      <PageTitle
        pageTitle="Issue Management Detail"
        showBreadcrumb={[
          { label: "Home", to: "/" },
          { label: "Issue Manage", to: "/issues/manage" },
        ]}
        BreadCrumbCurrentPageTitle={"Edit Issue"}
      />

      <div className={`sectionBox deissue1`}>
        <TableToolbar
          title=""
          leftComponent={
            <Typography variant="h6">
            </Typography>
          }
        />
        <div className="w-100 d-flex flex-direction-rowReverse">
          <Button
            variant="contained"
            className="themebutton flatbutton bg-alert"
            onClick={() => OnClickCloseIssue()}
          >
            Close Issue
          </Button>
        </div>

        <ThemeTablen
          rows={[IssueDetail]}
          headCells={DetailsColumns}
          hidePagination={true}
        />
        <EscalateForm
          IssueDetail={IssueDetail}
          fetchIssuesDetails={fetchIssuesDetails}
        />
        <div className="mh-15 alertselect">
          <div className="issueDetails mb-20">
            {IssueDetail?.responses &&
              IssueDetail?.responses
                .sort((a, b) => b.respondOn - a.respondOn)
                .map((responseItem, index) => {
                  return (
                    <div key={index} className="mb-15 responc position-relative">
                      <Typography variant="subtitle1">
                        <em>
                          {responseItem.escalatedTo ? (
                            <div className="d-flex flex-space-between">
                              <span className="w-65">
                                {getEmailDisplay(responseItem)}
                              </span>{" "}
                              <span className="w-20">{FormatedDates(responseItem.respondOn)}{" "}
                                {getFormatedTime(responseItem.respondOn)}</span>
                            </div>
                          ) : (
                            <div className="d-flex flex-space-between">
                              <span className="w-65">
                                {getEmailDisplay(responseItem)}
                              </span>{" "}
                              <span className="w-20">{FormatedDates(responseItem.respondOn)}{" "}
                                {getFormatedTime(responseItem.respondOn)}</span>
                            </div>
                          )}
                        </em>
                      </Typography>
                      <Typography variant="body1" className="pv-12">
                        {responseItem?.escalatedTo && responseItem?.escalated === "Respond from Email" ? responseItem?.details.split("On")[0].trim() : responseItem?.description}
                      </Typography>
                      <div className="d-flex flex-space-between flex-end">
                        <div
                          className="issuelink"
                          onMouseOver={() => handleMouseOver(responseItem)}
                          onMouseLeave={handleMouseLeave}
                        >
                          Included Recipients({responseItem?.recipients?.length || 0})
                        </div>
                        {showTooltip === responseItem && (
                          <>
                            {responseItem?.recipients?.length > 0 ? (
                              <>
                                <RecipientsTooltip recipients={responseItem.recipients} styles={styles} />
                              </>
                            ) : null}
                          </>
                        )}
                        <div style={{ width: isMobile || isLandscape ? "40%" : "" }}>
                          <ViewAttachments attachments={responseItem.attachments} />
                        </div>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </div>
    </Container>
  );
}

export default IssueDetails;
