import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControlLabel,
  Checkbox,
  Popover,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
  faInfo,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import CreateClientTask from "./CreateClientTask";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import confirm from "../../../../components/DialogComponent/confirm";

const useStyles = makeStyles((theme) => ({
  infobutton: {
    borderRadius: "50%",
    border: "1px solid #000",
    width: "18px",
    height: "18px",
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    marginRight: "6px",
    padding: "2px",
    fontSize: "11px",
    lineHeight: "11px",
  },
  disabled: {
    color: "#eee",
    borderColor: "#eee",
    pointerEvent: "none",
  },
  infobuttondanger: {
    borderColor: "#f62d51",
  },

  itemTaskwrap: {
    display: "flex",
    alignItems: "center",
  },
  root: {
    maxWidth: 345,
    minWidth: 120,
  },
  header: {
    background: "#e4e4e4",
    padding: "10px 12px",

    "& span": {
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "20px",
    },
  },
}));

const TaskItemCheckbox = (props) => {
  const classes = useStyles();
  const { Task, index } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const onChangeCheckbox = (event) => {
    props?.handleTasksCheckChange(event, Task);
  };
  const onDeleteTask = (Task) => {
    confirm("Are you Sure to delete this task").then(() => {
      props.removeTask(Task);
    });
  };

  return (
    <>
      <div
        key={"comTasklistitem" + index}
        className={`chkboxItemGroup ${classes?.itemTaskwrap} ${
          Task?.custom === true ? "customtask" : ""
        }`}
      >
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              color="primary"
              checked={props?.selected}
              onChange={onChangeCheckbox}
              name={Task?.title}
              disabled={props?.disabled}
            />
          }
          label={Task?.title}
        />

        {Task?.description && (
          <div
            className={`${classes?.infobutton} ${
              props?.disabled ? classes?.disabled : ""
            }`}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon={faInfo} aria-describedby={id} />
          </div>
        )}
        {Task?.custom === true && (
          <>
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"flatbutton iconbutton p-0 icbutton"}
              buttonProps={{
                size: "small",
                variant: "text",
                disabled: props?.disabled,
              }}
              buttonLabel={
                <div className={classes?.infobutton}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </div>
              }
              ModalTitle={`Edit ${Task?.title}`}
              ModalContent={
                <CreateClientTask
                  taskId={Task?.taskId}
                  Task={Task}
                  shifttitle = {props.shifttitle}
                  OnEditTask={props?.onEditTask}
                  onCreateNewTask={props?.onCreateNewTask}
                />
              }
            />
            <IconButton
              disabled={props?.disabled}
              className={`color-danger ${classes?.infobutton} ${classes?.infobuttondanger}`}
              onClick={() => onDeleteTask(Task)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </>
        )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card className={classes?.root}>
          <CardHeader title={Task?.title} className={classes?.header} />
          <CardContent>
            <h5 className="m-0 p-0">Notes:</h5>
            {Task?.description}
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};
export default TaskItemCheckbox;
