import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Box, Typography } from "@material-ui/core";

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  top: {
    color: "#1a90ff",
    animationDuration: "550ms",
    position: "absolute",
    left: 0,
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function FacebookCircularProgress(props) {
  const classes = useStylesFacebook();

  return (
    <Box
    position="relative"
    display="inline-flex"
    className={`circularprogress ${props.wrapclass}`}
  >
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={64}
        thickness={3}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        className={`actual ${classes.top}`}
        classes={{
          circle: classes.circle,
        }}
        size={64}
        thickness={3}
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)} ${props.signt==='yes'? "%":""}`}</Typography>
      </Box>
    </Box>
  );
}

export default function CustomizedProgressBars(props) {
  return <FacebookCircularProgress {...props} />;
}
