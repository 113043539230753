/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Container, Tooltip } from "@material-ui/core";
import PageTitle from "../../../../components/pageTitle/PageTitle";
import TableToolbar from "../../../../components/Table/TableToolbar";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import moment from "moment-timezone";
import { CSVLink } from "react-csv";
import styles from "../../Report.module.scss"
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import { getTimesheetListReport } from "../../../../services/apiservices/timesheets";
import { getUserListBasedOnIds } from "../../../../services/apiservices/users";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import * as Excel from 'exceljs';
import Popover from "@mui/material/Popover";
import { downloadPDF, downloadExcel } from "../../DownloadReportUtils";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import ResponsiveTable from "../../../../components/ResponsiveCardComponent/ReportsComponent/ClientReports/ResponsiveClientTable";
import { useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';


const useStyles = makeStyles({
    customTooltip: {
        fontSize: '80%',
        padding: '5px',
    },
});


const ActionRender = ({ rcellItem, clientsListCSV, setDownloadCSVData, downloadPDF, downloadExcel, startDate, endDate, handleEmailIconClick }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    return (
        <>
            <div className={styles.Action}>
                <Link to={"/emailreports"} rel="noopener" className={styles.Action}>
                    <EmailRoundedIcon onClick={() => handleEmailIconClick(rcellItem)} />
                </Link>
                <MoreHorizRoundedIcon onClick={handlePopoverOpen} />
            </div>
            <Popover
                id="popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    // vertical: 'left',
                    horizontal: "left",
                }}
                transformOrigin={{
                    // vertical: 'left',
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        boxShadow: "none",

                        // padding: '50px', // Add some padding
                    },
                }}
            >
                <div className={styles.box}>
                    <div className={styles.box2}>Download</div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            left: 20,
                        }}
                    >
                        <div>
                            <CSVLink
                                data={clientsListCSV}
                                filename={`CheckInCheckOutReport_${rcellItem.employeeName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}.csv`}
                                className={styles.Link}
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                    setDownloadCSVData(event, done, rcellItem)
                                }}
                            >
                                csv
                            </CSVLink>
                        </div>
                        <div>
                            <button
                                onClick={() => { downloadExcel(rcellItem); }}
                                className={styles.Link}
                                style={{ background: 'none', border: 'none', fontSize: 'small', textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                Excel
                            </button>
                        </div>
                        <div>
                            <button
                                onClick={() => { downloadPDF(rcellItem); }}
                                className={styles.Link}
                                style={{ background: 'none', border: 'none', fontSize: 'small', textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                Pdf
                            </button>
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};

function CheckInCheckOut(props) {

    const csvHeaders = [
        "Employee Name",
        "Date",
        "Check In Time",
        "Start Time",
        "Delta",
        "Check Out Time",
        "End Time",
        "Delta",
    ];

    const [employeeList, setEmployeeList] = useState([]);
    const [employeeListMaster, setEmployeeListMaster] = useState([]);
    const [clientsListCSV, setClientListCSV] = useState([csvHeaders]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [empName, setEmpName] = useState("All");
    const [empNameList, setEmpNameList] = useState([
        { label: "All", value: "All" },
    ]);
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const [detailedView, setDetailedView] = useState(null);

    const getColumns = () => {
        const columns = [
            {
                id: "employeeName",
                numeric: false,
                disablePadding: false,
                label: "Name",
                isSortable: false,
            },
            {
                id: "appointmentDate",
                numeric: false,
                disablePadding: false,
                label: "Date",
                isSortable: false,
            },
            {
                id: "checkInTime",
                numeric: false,
                disablePadding: false,
                label: "Check In Time",
                isSortable: false,
            },
            {
                id: "startTime",
                numeric: false,
                disablePadding: false,
                label: "Start Time",
                isSortable: false,
            },
            {
                id: "deltaCheckIn",
                numeric: false,
                disablePadding: false,
                label: "Delta",
                isSortable: false,
            },
            {
                id: "checkOutTime",
                numeric: false,
                disablePadding: false,
                label: "Check Out Time",
                isSortable: false,
            },
            {
                id: "endTime",
                numeric: false,
                disablePadding: false,
                label: "End Time",
                isSortable: false,
            },
            {
                id: "deltaCheckOut",
                numeric: false,
                disablePadding: false,
                label: "Delta",
                isSortable: false,
            },
            {
                id: "Action",
                numeric: false,
                disablePadding: false,
                label: "Action",
                isSortable: false,
            },
        ];

        if (isMobile || isLandscape) {
            return columns.filter(col => col.id !== "employeeName" && col.id !== "appointmentDate" && col.id !== "Action");
        }

        return columns;
    };

    const getDatatoDownload = (rcellItem) => {
        let clientCSV = [];
        if (!Array.isArray(rcellItem)) {
            clientCSV = employeeList?.filter(
                (item) => item.employeNameId === rcellItem.employeNameId
            );
            clientCSV = clientCSV?.map((item) => {
                return [
                    item?.employeeName,
                    item?.appointmentDate,
                    item?.checkInTime,
                    item?.startTime,
                    item?.deltaCheckIn,
                    item?.checkOutTime,
                    item?.endTime,
                    item?.deltaCheckOut,
                ];
            });
        } else {
            clientCSV = rcellItem?.map((item) => {
                return [
                    item?.employeeName,
                    item?.appointmentDate,
                    item?.checkInTime,
                    item?.startTime,
                    item?.deltaCheckIn,
                    item?.checkOutTime,
                    item?.endTime,
                    item?.deltaCheckOut,
                ];
            });
        }
        return clientCSV;
    }

    const getDatatoFullDownload = (rcellItem) => {
        if (rcellItem) {
            employeeList?.filter(
                (item) => item.employeNameId === rcellItem.employeNameId
            );
            return [
                rcellItem?.employeeName,
                rcellItem?.appointmentDate,
                rcellItem?.checkInTime,
                rcellItem?.startTime,
                rcellItem?.deltaCheckIn,
                rcellItem?.checkOutTime,
                rcellItem?.endTime,
                rcellItem?.deltaCheckOut,
            ];
        } else {
            return [
                rcellItem?.employeeName,
                rcellItem?.appointmentDate,
                rcellItem?.checkInTime,
                rcellItem?.startTime,
                rcellItem?.deltaCheckIn,
                rcellItem?.checkOutTime,
                rcellItem?.endTime,
                rcellItem?.deltaCheckOut,
            ];
        }
    }

    const downloadForExcel = async (rcellItem, emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Check In Check Out Report');
        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `CheckInCheckOutReport_${rcellItem[0]?.employeeName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        } else {
            fileName = `CheckInCheckOutReport__${rcellItem?.employeeName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        }
        let name = true;
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend, name)
    }

    const downloadForPDF = async (rcellItem, emailSend) => {
        const clientCSV = getDatatoDownload(rcellItem);
        let fileName;
        if (Array.isArray(rcellItem)) {
            fileName = `CheckInCheckOutReport_${rcellItem[0]?.employeeName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        } else {
            fileName = `CheckInCheckOutReport__${rcellItem?.employeeName}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`;
        }
        let name = true;
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend, name);
    }

    const setDownloadCSVData = (event, done, rcellItem) => {
        const clientCSV = getDatatoDownload(rcellItem);

        const reportName = "Check-In Check-Out Report";
        const employeName = Array.isArray(rcellItem) ? `${rcellItem[0]?.employeeName}` : `${rcellItem?.employeeName}`;
        const reportPeriod = `${moment(startDate).format("YYYY-MM-DD")} to ${moment(endDate).format("YYYY-MM-DD")}`;

        // Create the report details rows
        const reportDetails = [
            [`Employee Name: ${employeName}`],
            [`Report Name: ${reportName}`],
            [`Report Period: ${reportPeriod}`],
            [],
            [...csvHeaders],
            ...clientCSV
        ];

        // Set the CSV data
        setClientListCSV(reportDetails);
        done();
    };

    const downloadForFullPDF = async (emailSend) => {
        const clientCSV = employeeList.map((item) => getDatatoFullDownload(item));
        let empNamefordwonLoad = 'All'

        if (empName !== 'All') {
            empNamefordwonLoad = empNameList.filter((item) => item.value === empName)[0].label
        }
        let fileName = `CheckInCheckOutReport_${empNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
        let name = true;
        return await downloadPDF(clientCSV, csvHeaders, fileName, emailSend, name);
    }

    const downloadForFullExcel = async (emailSend) => {
        let wb = new Excel.Workbook();
        let ws = wb.addWorksheet('Check In Check Out Report');
        let empNamefordwonLoad = 'All'

        const clientCSV = employeeList.map((item) => getDatatoFullDownload(item));

        if (empName !== 'All') {
            empNamefordwonLoad = empNameList.filter((item) => item.value === empName)[0].label
        }
        let fileName = `CheckInCheckOutReport_${empNamefordwonLoad}_${moment(startDate).format("YYYY-MM-DD")}_${moment(endDate).format("YYYY-MM-DD")}`
        let name = true;
        return await downloadExcel(clientCSV, csvHeaders, wb, ws, fileName, emailSend, name)
    }


    const handleEmailIconClick = async (rcellItem) => {
        const emailSend = true;
        const excelBlob = await downloadForExcel(rcellItem, emailSend);
        let name;
        if (Array.isArray(rcellItem)) {
            name = rcellItem[0]?.employeeName;
        } else {
            name = rcellItem?.employeeName;
        }

        localStorage.setItem("FileName", "CheckIn/CheckOut Report");

        localStorage.setItem("ReportsName", "Employee Report");

        localStorage.setItem("Name", name);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);

        const pdfBlob = await downloadForPDF(rcellItem, emailSend);
        localStorage.setItem("pdfData", pdfBlob);
        setIsLoading(true);

        // Convert the Excel blob to a base64-encoded string
        const reader = new FileReader();
        reader.readAsDataURL(excelBlob);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("emailData", base64Data);

            // Navigate to the email reports page
            navigate("/emailreports");
        };
    };


    const handelEmailFullReport = async () => {
        const emailSend = true;
        localStorage.setItem("FileName", "CheckIn/CheckOut Report");

        localStorage.setItem("ReportsName", "Employee Report");

        let empNamefordwonLoad = 'All'

        if (empName !== 'All') {
            empNamefordwonLoad = empNameList.filter((item) => item.value === empName)[0].label
        }
        localStorage.setItem("Name", empNamefordwonLoad);

        localStorage.setItem("Date", startDate);

        localStorage.setItem("EndDate", endDate);

        setIsLoading(true);
        const fullReport = await downloadForFullPDF(emailSend);
        localStorage.setItem("fullReport", fullReport);

        const fullExcelReport = await downloadForFullExcel(emailSend);
        const reader = new FileReader();
        reader.readAsDataURL(fullExcelReport);
        reader.onloadend = function () {
            const base64Data = reader.result;
            localStorage.setItem("fullExcelReport", base64Data);

            navigate("/emailreports");
        }
    }

    const handlePdfClick = async (records, emailSend) => {
        await downloadForPDF(records, emailSend);
    }

    const handleExcelClick = async (records, emailSend) => {
        await downloadForExcel(records, emailSend);
    }

    const handleCSvClick = async (event, done, records) => {
        await setDownloadCSVData(event, done, records);
    };

    const handelEmailClicks = async (records) => {
        await handleEmailIconClick(records);
    }

    const handleEmpNameChange = (e) => {

        const str = e.target.value;

        setEmpName(str);
        if (str === "All") {
            setEmployeeList(employeeListMaster);
        } else {
            setEmployeeList(() =>
                employeeListMaster.filter((itme) => {
                    let returnFlg = true;
                    if (returnFlg) {
                        returnFlg = str.includes(itme?.employeNameId);
                    }
                    return returnFlg;
                })
            );
        }
    };



    useEffect(() => {
        let paramStartDate = "";
        let paramEndDate = "";

        paramStartDate = startDate;
        paramEndDate = endDate;
        getReportListData(paramStartDate, paramEndDate);
    }, [startDate, endDate]);

    // Function to format time difference
    function formatTimeDifference(hours, minutes) {
        if (hours === 0 && minutes === 0) {
            return "0";
        } else {
            // the sign based on the hours and minutes
            const sign = hours > 0 || (hours === 0 && minutes > 0) ? "+" : "-";
            // Ensure both hours and minutes are positive for display
            const absHours = Math.abs(hours);
            const absMinutes = Math.abs(minutes);
            return `${sign}${absHours} : ${absMinutes.toString().padStart(2, '0')}`;
        }
    }

    const mapWidhtEmpList = async (userIds, timeSheet) => {
        getUserListBasedOnIds(userIds, (result) => {
            const employee = result.map((item) => {
                return { value: item?.id, label: `${item?.firstName} ${item?.lastName}` };
            });
            setEmpNameList([{ label: "All", value: "All" }, ...employee]);

            const finaleData = timeSheet.map((resItem) => {
                const userTimeSheet = result.filter(
                    (itm) => itm.id === resItem.userId
                );

                const checkInTime = moment.unix(resItem?.checkInDateTime?.seconds).tz(resItem?.apoointmentDetails?.calendarTimezone).format("hh:mm a");

                const checkOutTime = moment.unix(resItem?.checkOutDateTime?.seconds).tz(resItem?.apoointmentDetails?.calendarTimezone).format("hh:mm a");

                const startTimes = resItem?.apoointmentDetails?.time;

                const endTimes = resItem?.apoointmentDetails?.endTime;

                const checkInStartTimeDiff = moment.duration(moment(startTimes, 'hh:mm a').diff(moment(checkInTime, 'hh:mm a')));
                const checkInStartTimeDiffHours = !checkInStartTimeDiff.hours() ? 0 : checkInStartTimeDiff.hours();
                const checkInStartTimeDiffMinutes = !checkInStartTimeDiff.minutes() ? 0 : checkInStartTimeDiff.minutes();


                const checkOutEndTimeDiff = moment.duration(moment(endTimes, 'hh:mm a').diff(moment(checkOutTime, 'hh:mm a')));
                const checkOutEndTimeDiffHours = !checkOutEndTimeDiff.hours() ? 0 : checkOutEndTimeDiff.hours();
                const checkOutEndTimeDiffMinutes = !checkOutEndTimeDiff.minutes() ? 0 : checkOutEndTimeDiff.minutes();

                resItem["employeeName"] = `${userTimeSheet[0]?.firstName} ${userTimeSheet[0]?.lastName}`
                resItem["employeNameId"] = resItem?.userId;
                resItem["date"] = resItem?.appointmentDateTime;
                resItem["checkInTime"] = checkInTime === "Invalid date" ? "Not Available" : checkInTime;
                resItem["checkOutTime"] = checkOutTime === "Invalid date" ? "Not Available" : checkOutTime;
                resItem["startTime"] = startTimes;
                resItem["endTime"] = endTimes;
                resItem["deltaCheckIn"] = formatTimeDifference(checkInStartTimeDiffHours, checkInStartTimeDiffMinutes);
                resItem["deltaCheckOut"] = formatTimeDifference(checkOutEndTimeDiffHours, checkOutEndTimeDiffMinutes);

                return resItem;
            });



            let sortedData = sortingFunction(finaleData);
            setEmployeeListMaster(sortedData);

            setEmployeeList(() =>
                sortedData?.filter((itme) => {
                    let returnFlg = true;
                    if (empName !== "All" && returnFlg) {
                        returnFlg = itme.employeNameId === empName;
                    }
                    return returnFlg;
                })
            );
        });
    };

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.employeeName < b.employeeName) return -1;
            if (a.employeeName > b.employeeName) return 1;

            if (a.appointmentDateTime < b.appointmentDateTime) return -1;
            if (a.appointmentDateTime > b.appointmentDateTime) return 1;
            return 0;
        });

        return result;
    };

    const getReportListData = async (startDt, endDt) => {
        const param = {
            startDate: `${dayjs(startDt).format("YYYY-MM-DD")}T00:00:00`,
            endDate: `${dayjs(endDt).format("YYYY-MM-DD")}T23:59:59`
        };

        getTimesheetListReport(
            param,
            (result) => {
                setIsLoading(false);
                const empIds = [];
                for (let i = 0; i < result.length; i++) {
                    if (empIds.indexOf(result[i].userId) === -1) {
                        empIds.push(result[i].userId);
                    }
                }
                mapWidhtEmpList(empIds, result);
            },
            (err) => {
                setIsLoading(false);
                console.log("err>>", err);
            }
        );
    };

    const classes = useStyles();
    const delta = (rcellItem, hcellItem) => {
        let className = "";
        const value = rcellItem[hcellItem?.id];

        let tooltipTitle = "On Time";
        if (value !== undefined && value !== "0") {
            if (value.startsWith("+")) {
                className = styles.deltaclr1;
                tooltipTitle = `Early`;
            } else if (value.startsWith("-")) {
                className = styles.deltaclr3;
                tooltipTitle = `Late`;
            } else {
                className = styles.deltaclr2;
                tooltipTitle = "On Time"
            }
        } else {
            className = styles.deltaclr2;
            tooltipTitle = "On Time";
        }

        return (
            <Tooltip title={tooltipTitle} classes={{ tooltip: classes.customTooltip }}>
                <div className={className}>{value !== undefined ? value : ""}</div>
            </Tooltip>
        );
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "employeeName":
                if (
                    rowindex !== 0 &&
                    employeeList[rowindex - 1].employeeName !== rcellItem.employeeName
                ) {
                    return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
                } else if (rowindex === 0) {
                    return <div className="b-none">{rcellItem[hcellItem?.id]} </div>;
                } else {
                    return "";
                }
            case "deltaCheckIn":
                return delta(rcellItem, hcellItem);
            case "deltaCheckOut":
                return delta(rcellItem, hcellItem);
            case "checkInTime":
                return (
                    <div>
                        {rcellItem[hcellItem?.id] !== 'Not Available' ? (
                            <Link target="_blank" rel="noopener">
                                <a
                                    className={styles.timeZone}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://maps.google.com/?q=${rcellItem?.location?.lat},${rcellItem?.location?.lng}`}
                                >{rcellItem[hcellItem?.id]}</a></Link>
                        ) : (
                            rcellItem[hcellItem?.id]
                        )}
                    </div>
                );
            case "checkOutTime":
                return (
                    <div>
                        {rcellItem[hcellItem?.id] !== 'Not Available' ? (
                            <Link target="_blank" rel="noopener">
                                <a
                                    className={styles.timeZone}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={`https://maps.google.com/?q=${rcellItem?.shiftend_location?.lat},${rcellItem?.shiftend_location?.lng}`}
                                >
                                    {rcellItem[hcellItem?.id]}
                                </a>
                            </Link>
                        ) : (
                            rcellItem[hcellItem?.id]
                        )}
                    </div>
                );
            case "Action":
                if (isMobile || isLandscape) {
                    if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return null;
                    }
                } else {
                    if (
                        rowindex !== 0 &&
                        employeeList[rowindex - 1].employeNameId !== rcellItem.employeNameId
                    ) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else if (rowindex === 0) {
                        return (
                            <ActionRender
                                rcellItem={rcellItem}
                                clientsListCSV={clientsListCSV}
                                setDownloadCSVData={setDownloadCSVData}
                                downloadPDF={downloadForPDF}
                                downloadExcel={downloadForExcel}
                                startDate={startDate}
                                endDate={endDate}
                                handleEmailIconClick={handleEmailIconClick}
                            />
                        );
                    } else {
                        return "";
                    }
                }
            default:
                return <div className="b-none">{rcellItem[hcellItem?.id]}</div>;
        }
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex w320 editEmp issueAlign ph-8">
                <div className="mr-24 d-flex flex-center"> <FilterAltIcon className="mv-7 issueicon" /> Filters </div>
                <div className="reportsMargin mv-7">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Start Date"
                            className="customDate customDatePicker"
                            value={startDate}
                            onChange={(newValue) => {
                                setStartDate(newValue);
                                if (newValue) {
                                    setEndDate(moment());
                                } else {
                                    setEndDate(null);
                                }
                            }}
                            maxDate={moment()}
                            views={['year', 'month', 'day']}
                            inputFormat="YYYY-MM-DD"
                        />
                    </LocalizationProvider>
                </div>
                <div className="reportsMargin mv-7">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="End Date"
                            className="customDate customDatePicker"
                            value={endDate}
                            onChange={(newValue) => {
                                setEndDate(newValue);
                                if (!newValue) {
                                    setStartDate(null);
                                }
                            }}
                            maxDate={moment()}
                            views={['year', 'month', 'day']}
                            inputFormat="YYYY-MM-DD"
                        />
                    </LocalizationProvider>
                </div>
                <div className="reportsMargin mv-7">
                    <SingleSelect
                        value={empName}
                        className={`field reportsSingleSelect`}
                        name={"employeeName"}
                        label={"Employee Name"}
                        onChange={(e) => handleEmpNameChange(e)}
                        options={empNameList}
                    />
                </div>
            </div>
        );
    };


    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Check In / Check Out Reports"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                        { label: "Reports" },
                        { label: "Employee Reports", to: "/reports/employeereport" }
                    ]}
                    BreadCrumbCurrentPageTitle={"Check In / Check Out Reports"}
                    showLastSeen={true}
                ></PageTitle>


                <div className={`sectionBox2`}>
                    {!detailedView && (
                        <TableToolbar
                            rightComponent={tableRightComponent()}
                        />
                    )}
                    <ResponsiveTable
                        rows={employeeList}
                        getColumns={getColumns}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        detailedView={detailedView}
                        setDetailedView={setDetailedView}
                        fileName={"CheckIn/CheckOut Report"}
                        onClickPdf={handlePdfClick}
                        onClickExcel={handleExcelClick}
                        onClickCsv={handleCSvClick}
                        clientsListCSV={clientsListCSV}
                        onClickEmail={handelEmailClicks}
                    />
                </div>

                {!detailedView && (
                    <div className={styles.report}>
                        <div className={styles.download1}>
                            <EmailRoundedIcon />
                            <div>
                                <Link to={employeeList.length !== 0 && "/emailreports"} rel="noopener">
                                    <Button
                                        className={styles.actions}
                                        disabled={employeeList.length === 0}
                                        onClick={() => {
                                            handelEmailFullReport()
                                        }}
                                    >
                                        Full Report
                                    </Button>
                                </Link>
                            </div>
                        </div>
                        <div className={styles.download1}>
                            <PictureAsPdfIcon />
                            <div>
                                <Button
                                    className={styles.actions}
                                    disabled={employeeList.length === 0}
                                    onClick={() => {
                                        downloadForFullPDF();
                                    }}
                                >
                                    Download Full Report
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Container>
        </>
    );

}
export default CheckInCheckOut;