import React from "react";
const stepsArray = [
  {
    id: 1,
    title: "Profile",
    subTitle: "Step 1",
    NextButtonText: "Proceed to Step 2",
    CancelButtonText: "Cancel",
  },
  {
    id: 2,
    title: "Client Team",
    subTitle: "Step 2",
    NextButtonText: "Proceed to Step 3",
    CancelButtonText: "Cancel",
  },
  {
    id: 3,
    title: "Assign Group",
    subTitle: "Step 3",
    NextButtonText: "Proceed to Step 4",
    CancelButtonText: "Cancel",
  },
  {
    id: 4,
    title: "Link to Acuity Profile",
    subTitle: "Step 4",
    NextButtonText: "Submit",
    CancelButtonText: "Cancel",
  },
];

// Creating the context object and passing the default values.
const EmployeeStepsContext = React.createContext({
  stepsArray: stepsArray,
  stepFormValues: {},
  stepFormErrors: {},
  currentStep: 1,
  setStepFormValue: () => {},
  setStepFormError: () => {},
  goPrev: () => {},
  goNext: () => {},
});

export default EmployeeStepsContext;
