const Columns = [
  {
    id: "isuueId",
    numeric: false,
    disablePadding: true,
    label: "Issue #",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client name",
    isSortable: true,
  },
  {
    id: "openedOn",
    numeric: false,
    disablePadding: false,
    label: "Open Date",
    isSortable: true,
  },
  {
    id: "closedOn",
    numeric: false,
    disablePadding: false,
    label: "Close Date",
    isSortable: true,
  },  
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },  
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

const ColumnsRes = [
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isSortable: true,
  },
  {
    id: "openedOn",
    numeric: false,
    disablePadding: false,
    label: "Open Date",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "closedOn",
    numeric: false,
    disablePadding: false,
    label: "Close Date",
    isSortable: true,
    isCollapsible: true,
  },   
  {
    id: "unArchive",
    numeric: false,
    disablePadding: true,
    label: "unArchive",
    isCollapsible: true,
  },
];
export { Columns, ColumnsRes };
