import React from "react";
import { Button, TextField } from "@material-ui/core";
import { addGroup } from "../../../services/apiservices/groups";

const AddNewGroup = (props) => {
  const [newGroup, setNewGroup] = React.useState("");
  const [newGroupDescription, setNewGroupDescription] = React.useState("");
  const [errors, setErrors] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const onAddGroup = async () => {
    if (newGroup !== "") {
      setIsLoading(true);
      await addGroup(
        {
          title: newGroup,
          description: newGroupDescription,
        },
        (res) => {
          setIsLoading(false);
          props?.onAddSuccess();
          props?.onClose();
        },
        (resError) => {
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
      setErrors({ newGroup: "Title is Required" });
    }
  };

  return (
    <div className="p-15">
      <div className="mb-15">
        <TextField
          size="small"
          fullWidth={true}
          name="newGroup"
          id="newGroup"
          label="Group Title"
          variant="outlined"
          placeholder="Group Title"
          onChange={(event) => {
            setErrors({ newGroup: "" });
            setNewGroup(event.target.value);
          }}
          value={newGroup ? newGroup : ""}
        />
      </div>
      {typeof errors?.newGroup !== "undefined" && errors?.newGroup !== "" && (
        <span className="small error color-danger">{errors?.newGroup}</span>
      )}

      <div>
        <TextField
          size="small"
          multiline={true}
          fullWidth={true}
          rows={4}
          className="mb-10"
          name="newGroupDescription"
          id="newGroupDescription"
          label="Group Description"
          variant="outlined"
          placeholder="Group Description"
          onChange={(event) => {
            setNewGroupDescription(event.target.value);
          }}
          value={newGroupDescription ? newGroupDescription : ""}
        />
      </div>
      <div className="mv-12 d-flex flex-justify-flexend pv-10">
        <Button
          className="flatbutton"
          type="button"
          variant={"contained"}
          onClick={onAddGroup}
          color="primary"
          disabled={isLoading ? true: false}
        >
          {isLoading ? "Loading..." : "Add Group"}
        </Button>
        <Button
          className="flatbutton ml-10"
          type="button"
          variant={"contained"}
          disabled={isLoading ? true: false}
          onClick={props?.onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default AddNewGroup;
