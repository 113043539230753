import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AppShortcutOutlinedIcon from '@mui/icons-material/AppShortcutOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import UpdateIcon from '@mui/icons-material/Update';
import { Grid, Link, List, ListItem, Typography } from "@material-ui/core";
import React, { useState } from "react";
import SingleSelect from "../../../../components/SelectField/SingleSelect";
import styles from "../../dashboard.module.scss";
import { getDocCount } from "../../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../../services/config";
import moment from 'moment';
import { useMediaQuery } from "@mui/material";
import {  getEmployeesListBasedOnId } from '../../../../services/apiservices/employee';

function NotificationsList(props) {
  const [frequency, setFrequency] = useState("Email");
  const [notificationData, setNotificationData] = useState([]);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  const frequencyList = [
    { label: "Push Notification", value: "PushNotification" },
    { label: "Email", value: "Email" },
    { label: "SMS", value: "Push" },
    { label: "All", value: "Both" },
  ];

  const onChangeInput = (e) => {
    setFrequency(e.target.value);
  };

  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.NOTIFICATIONS,
      async (res) => {
        const sortedActivities = res.sort((a, b) => b.createdOn - a.createdOn);

        const createdByIds = [...new Set(sortedActivities
          .map(item => item.createdBy)
          .filter(id => id !== undefined))];

        if (createdByIds.length > 0) {
          getEmployeesListBasedOnId(createdByIds, (employeeData) => {
            const notificationsWithNames = sortedActivities.map(notification => {
              const employee = employeeData.find(emp => emp?.id === notification?.createdBy);
              return {
                ...notification,
                employeeName: employee ? employee.firstName : ""
              };
            });

            setNotificationData(notificationsWithNames);
           
          }, (error) => {
            console.error('Error fetching employees:', error);
          });
        } else {
          setNotificationData(sortedActivities);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }, []);

  const filterData = (noti) => {
    if (frequency === "PushNotification") {
      return noti.Type === "PushNotification";
    }
    if (frequency === "Email") {
      return noti.Type === "Email";
    }
    if (frequency === "Push") {
      return noti.Type === "Push";
    }
    if (frequency === "Both") {
      return noti.Type === "Both";
    }
    return false;
  };

  const chagedtime = (time) => {
    const timeno = moment(time);
    var duration = ` ${moment(timeno).format('MMM DD, YY')}${'\n'} ${moment(timeno).format('hh:mm a')}`

    return duration;
  }

  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={5}>
          <p className="p-0 m-0 mb-5 fonthe paddal">
            Notifications
          </p>
        </Grid>
        <Grid item xs={12} sm={7} className={isMobile || isLandscape ?  "mr-5" : ""}>
          <SingleSelect
            //   label="status"
            value={frequency}
            className={`field newonr`}
            name={"frequency"}
            onChange={(e) => onChangeInput(e)}
            options={frequencyList}
          />
        </Grid>
      </Grid>
      <List dense className="ulhide">
        {notificationData
          .filter((noti) => filterData(noti))
          .map((noti, index) => {
            return (<ListItem className={`${styles.listItem} paddnonoti`}>
              <div className="w-100">
                {!isMobile && !isLandscape ?
                  <Grid container spacing={1} className="d-flex flex-space-between">
                    <Grid item xs={12} sm={6} className="fonnotif" >
                      {noti.Type === 'PushNotification' && <><NotificationsIcon className='greencol svg-icon' /> <span style={{ color: "#202020", fontWeight: "450" }}>Push Notification</span></>}
                      {noti.Type === 'Email' && <><EmailOutlinedIcon className='greencol svg-icon' />  <span style={{ color: "#202020", fontWeight: "450" }}>Email</span></>}
                      {noti.Type === 'Push' && <><AppShortcutOutlinedIcon className='greencol svg-icon' />  <span style={{ color: "#202020", fontWeight: "450" }}>SMS</span></>}
                      {noti.Type === 'Both' && frequency === 'Both' &&
                        <><NotificationsIcon className='greencol svg-icon' /><EmailOutlinedIcon className='greencol svg-icon' />
                          <AppShortcutOutlinedIcon className='greencol svg-icon' /><span style={{ color: "#202020", fontWeight: "450" }}>All</span></>}
                    </Grid>
                    <Grid item xs={12} sm={4} className="fonnotif">
                      <PeopleAltOutlinedIcon className='greencol svg-icon' /> <span style={{ color: "#202020", fontWeight: "450" }}>{noti?.employeeName  ? noti?.employeeName : "Auditor"}</span>
                    </Grid>
                  </Grid>
                  :
                  <Grid container spacing={1} className="d-flex flex-space-between">
                    <div>
                      <Grid item xs={12} sm={6} className="fonnotif d-flex flex-center" >
                        {noti.Type === 'PushNotification' && <><NotificationsIcon className='greencol svg-icon' /> <span style={{ color: "#202020", fontWeight: "450" }}>Push Notification</span></>}
                        {noti.Type === 'Email' && <><EmailOutlinedIcon className='greencol svg-icon' />  <span style={{ color: "#202020", fontWeight: "450" }}>Email</span></>}
                        {noti.Type === 'Push' && <><AppShortcutOutlinedIcon className='greencol svg-icon' />  <span style={{ color: "#202020", fontWeight: "450" }}>SMS</span></>}
                        {noti.Type === 'Both' && frequency === 'Both' &&
                          <><NotificationsIcon className='greencol svg-icon' /><EmailOutlinedIcon className='greencol svg-icon' />
                            <AppShortcutOutlinedIcon className='greencol svg-icon' /><span style={{ color: "#202020", fontWeight: "450" }}>All</span></>}
                      </Grid>
                    </div>
                    <Grid item xs={3} sm={4} className="fonnotif d-flex flex-center">
                      <PeopleAltOutlinedIcon className='greencol svg-icon' /> <span style={{ color: "#202020", fontWeight: "450" }}>{noti?.employeeName  ? noti?.employeeName : "Auditor"}</span>
                    </Grid>
                  </Grid>
                }
                <Typography variant="body1" className="fonnotif">{noti.Message.length > 75 ? (noti.Message).substring(0, 75) + "..." : noti.Message}</Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} className="fonnotif">
                    <Typography variant="body2" className="margne"><UpdateIcon className='greencol iconcoln svg-icon' />{chagedtime(noti.createdOn)}</Typography>
                  </Grid>
                </Grid>
              </div>
            </ListItem>)
          })
        }
      </List>
      <div className="mv-25"></div>
      <p className="viewLinks">
        <Link className="link mt-20" href="/notifications/manage">View all Notifications</Link></p>
    </>
  );
}

export default NotificationsList;
