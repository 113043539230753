import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import AuthContext from "../hooks/authContext";
import CommonContext from "../hooks/commonContext";
import Loader from "../components/AppLoader/Loader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
  },
}));

const BlankLayouts = (props) => {
  const auth = useContext(AuthContext);
  const [layoutLoader, setLayoutLoader] = useState(false);
  const classes = useStyles();
  return (
    <>
      {auth?.status === true && <Navigate to="/dashboard" />}
      <CommonContext.Provider
        value={{
          loader: layoutLoader,
          setLoader: setLayoutLoader,
        }}
      >
        <div className={`BlankContainer `}>
          {layoutLoader === true && <Loader />}
          <div className={classes.root}>
            <CssBaseline />
            {props.children}
          </div>
        </div>
      </CommonContext.Provider>
    </>
  );
};

export default BlankLayouts;
