import { collection, orderBy, onSnapshot, addDoc, doc, setDoc, deleteDoc, getDoc, query, where, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import moment from 'moment';
import { COLLECTIONS } from "../config";
import { firebase } from "../firebase-config";

const auth = getAuth();

const msg_collection = COLLECTIONS.CHAT_CHANNELS;
const chat_collection = COLLECTIONS.CHAT_MESSAGES;

/**
 * Get Tasks list from standardtasks collection (shown while create client)
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getChannelList(param, onSuccess, onError) {
  const q = query(collection(firebase, msg_collection), orderBy("createdOn", "desc"));

  return onSnapshot(q, (querySnapshot) => {
    let results = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    onSuccess && onSuccess(results);
  }, (err) => {
    onError && onError(err);
    console.error(`Encountered error: ${err}`);
  });
}

/**
 * Send channel and save it to the database
 * @param {*} formData : data to be saved and sent as channels
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function saveChannel(formData, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;

  try {
    const docRef = await addDoc(collection(firebase, msg_collection), {
      ...formData,
      createdBy: currentUserId,
      createdOn: new Date().getTime(),
    });
    const docdata = { ...formData, id: docRef.id };
    onSuccess && onSuccess({ status: true, data: docdata });
  } catch (error) {
    console.error(`ERROR: ${msg_collection} ${error}`);
    onError && onError({ status: false, error });
  }
}

/**
 * Update channel in the database
 * @param {*} id
 * @param {*} formData : data to be saved and sent as update channel
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateChannel(id, formData, onSuccess, onError) {
  const currentUserId = auth.currentUser.uid;
  const channelRef = doc(firebase, msg_collection, id);

  try {
    await setDoc(channelRef, {
      ...formData,
      updatedAt: new Date().getTime(),
      updatedBy: currentUserId,
    }, { merge: true });

    const docdata = { ...formData, id };
    onSuccess && onSuccess({ status: true, data: docdata });
  } catch (error) {
    console.error(`ERROR: ${msg_collection} ${error}`);
    onError && onError({ status: false, error });
  }
}

/**
 * Permanently Remove Channel from the database
 * @param {*} id
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteChannel(id, onSuccess, onError) {
  const channelRef = doc(firebase, msg_collection, id);

  try {
    await deleteDoc(channelRef);
    onSuccess && onSuccess({ status: true, message: "Delete successfully" });
  } catch (error) {
    onError && onError({ status: false, error });
  }
}

/**
 * Get details of a particular Channel
 * @param {*} id : id of Channel
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getChannel(id, onSuccess, onError) {
  try {
    const channelRef = doc(firebase, msg_collection, id);
    const channelSnap = await getDoc(channelRef);
    
    if (!channelSnap.exists()) {
      onError && onError({ status: false, error: "Channel not found" });
    } else {
      const data = channelSnap.data();
      onSuccess && onSuccess({ status: true, data: { id: channelSnap.id, ...data } });
    }
  } catch (error) {
    onError && onError({ status: false, error });
  }
}

/**
 * Send message to channel and save it to the database
 * @param {*} formData : Message Data {channelId, sender:{id, firstName, lastName}, type:{text/image}, readBy:[], message, image}
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function sendMessageToChannel(formData, onSuccess, onError) {
  try {
    const docRef = await addDoc(collection(firebase, chat_collection), {
      ...formData,
      createdOn: new Date().getTime(),
      updatedOn: new Date().getTime(),
    });
    const docdata = { ...formData, id: docRef.id };
    onSuccess && onSuccess({ status: true, data: docdata });
  } catch (error) {
    console.error(`ERROR: ${chat_collection} ${error}`);
    onError && onError({ status: false, error });
  }
}

/**
 * Get particular Channel Message
 * @param {*} channelId : id of Channel
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getChannelMessage(channelId, onSuccess, onError) {
  try {
    const q = query(collection(firebase, chat_collection), where("channelId", "==", channelId), orderBy("updatedOn"));
    const querySnapshot = await getDocs(q);
    let results = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });

    onSuccess && onSuccess(results);
  } catch (error) {
    onError && onError({ status: false, error });
  }
}

/**
 * Get messages and mark them as read by the current user
 * @param {*} channelId
 * @param {*} onSuccess
 * @param {*} onError
 */
export function getMessages(channelId, onSuccess, onError) {
  getChannel(channelId, (channel) => {
    const channelData = channel.data;
    const currentUserId = auth.currentUser.uid;

    if (channelData.readMsgUsers) {
      if (!channelData.readMsgUsers.includes(currentUserId)) {
        channelData.readMsgUsers.push(currentUserId);
        updateChannel(channelId, channelData);
      }
    } else {
      channelData.readMsgUsers = [currentUserId];
      updateChannel(channelId, channelData);
    }
  });

  const q = query(collection(firebase, chat_collection), where("channelId", "==", channelId), orderBy("updatedOn"));

  return onSnapshot(q, (querySnapshot) => {
    let results = [];
    let imagesObject = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();
      
      let isNeedtoAdd = true;

      if (data.type === 'image') {
        if (imagesObject.length > 0) {
          if (data.sender.userId === imagesObject[0].sender.userId) {
            let duration = moment.duration(moment(data.createdOn).diff(moment(imagesObject[0].createdOn)));
            let diffMins = duration.asMinutes();

            if (diffMins <= 1 && data.message === "") {
              imagesObject.push({ id: doc.id, ...data });
              isNeedtoAdd = false;
            } else if (diffMins <= 1 && data.message !== "") {
              imagesObject.push({ id: doc.id, ...data });
              isNeedtoAdd = true;
            } else {
              isNeedtoAdd = true;
            }
          }
        } else {
          imagesObject.push({ id: doc.id, ...data });
          isNeedtoAdd = false;
        }
      }

      if (isNeedtoAdd) {
        if (imagesObject.length > 0) {
          if (doc.id === imagesObject[imagesObject.length - 1].id) {
            results.push({ ...imagesObject[imagesObject.length - 1], imagesObj: imagesObject });
            imagesObject = [];
          } else {
            results.push({ ...imagesObject[imagesObject.length - 1], imagesObj: imagesObject });
            if (data.type === 'image') {
              imagesObject = [];
              imagesObject.push({ id: doc.id, ...data });
            } else {
              results.push({ id: doc.id, ...data });
              imagesObject = [];
            }
          }
        } else {
          results.push({ id: doc.id, ...data });
          imagesObject = [];
        }
      } else {
        const lastDoc = querySnapshot.docs[querySnapshot.size - 1];
        if (lastDoc.id === doc.id) {
          if (imagesObject.length > 0) {
            results.push({ ...imagesObject[imagesObject.length - 1], imagesObj: imagesObject });
            imagesObject = [];
          }
        }
      }
    });
    onSuccess && onSuccess(results);
  }, (err) => {
    onError && onError(err);
    console.error(`Encountered error: ${err}`);
  });
}
