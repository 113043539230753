/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tr, Td } from "react-super-responsive-table";
import {
  Checkbox,
  Divider,
  IconButton,
  Switch,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import { useMediaQuery } from '@mui/material';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
const ThemeTableRow = (props) => {
  const { rindex, headCells } = props;
  const [isEditRow, setEditRow] = useState(false);
  const [rowItem, setRowItem] = useState({});
  const [switchStatus, setSwitchStatus] = React.useState(true);
  const isMobile = useMediaQuery('(max-width:767px)');

  const currentCategoryId = props.rcellItem?.parentCategoryId;
  const prevCategoryId = rindex > 0 ? props.requestData?.supplyItems[rindex - 1]?.parentCategoryId : null;
  const showBorder = currentCategoryId !== prevCategoryId;

  useEffect(() => {
    setEditRow(false);
    setRowItem(props?.rcellItem);
    setSwitchStatus(
      props?.rcellItem?.status ? props?.rcellItem?.status : false
    );
  }, [props?.rcellItem]);

  const onCancel = () => {
    setRowItem(props?.rcellItem);
    setEditRow(false);
  };

  const saveRow = () => {
    setEditRow(false);
  };

  const handleSwitchChange = (event) => {
    let newstatus = !switchStatus;
    setSwitchStatus(newstatus);
    props?.OnSwitchChange && props?.OnSwitchChange(props?.rcellItem, newstatus);
  };

  const handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setRowItem({ ...rowItem, [name]: value });
  };

  const onSelectRow = (row) => {
    props?.onSelectRow && props?.onSelectRow(row);
  };

  const handleChangeRowChecked = (e) => {
    const {
      target: { value },
    } = e;
    let selectedRows = props?.selectedRows;
    let isalreadySelected = selectedRows?.some((sritem) => sritem === value);
    if (isalreadySelected) {
      selectedRows = selectedRows?.filter((sritem) => sritem !== value);
    } else {
      selectedRows.push(value);
    }
    selectedRows = selectedRows?.filter((val, id, array) => {
      return array.indexOf(val) === id;
    });
    onSelectRow(selectedRows);
  };

  const renderCellContent = (content) => {
    return <div className="themeCellContent">{content}</div>;
  };

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    if (hcellItem?.isEditable === true && isEditRow === true) {
      return renderCellContent(
        hcellItem?.editField(hcellItem?.id, rcellItem[hcellItem?.id], "", {
          onChange: handleInputChange,
        })
      );
    } else if (hcellItem.id === "action") {
      return renderCellContent(
        <div
          className={`d-block w-100 ${
            hcellItem?.rowclassName ? hcellItem?.rowclassName : ""
          }`}
        >
          {props.allowEditRow === true && (
            <>
              {isEditRow === true ? (
                <>
                  <IconButton
                    className="btn-small color-primary ml-10"
                    size="small"
                    variant="text"
                    type="submit"
                    disableRipple={true}
                    disableFocusRipple={true}
                    onClick={() => saveRow()}
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    className="btn-small color-primary ml-10 mr-5"
                    size="small"
                    variant="text"
                    disableRipple={true}
                    disableFocusRipple={true}
                    onClick={() => onCancel()}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  size={"small"}
                  className="btn-small iconButton color-primary fs-13"
                  onClick={() => setEditRow(!isEditRow)}
                >
                  <EditIcon />
                </IconButton>
              )}
            </>
          )}
          {props?.renderRowCell &&
            props?.renderRowCell(rcellItem, hcellItem, index, rowindex)}
        </div>
      );
    } else if (hcellItem.id === "switch") {
      return renderCellContent(
        <AntSwitch
          checked={switchStatus}
          onChange={handleSwitchChange}
          color="primary"
          name="switchStatus"
          inputProps={{ "aria-label": "switchStatus" }}
        />
      );
    } else if (props?.renderRowCell) {
      return renderCellContent(
        props?.renderRowCell(rcellItem, hcellItem, index, rowindex)
      );
    } else {
      switch (hcellItem.id) {
        case "checkbox":
          let ischecked = props?.selectedRows?.some(
            (findsomeitem) => String(findsomeitem) === String(rcellItem?.id)
          );
          return renderCellContent(
            <Checkbox
              color="primary"
              value={rcellItem?.id}
              checked={ischecked}
              onChange={handleChangeRowChecked}
            />
          );
        default:
          return renderCellContent(rcellItem[hcellItem?.id]);
      }
    }
  };

  return (
    <>
      <Tr
        onClick={(e) => onSelectRow(rowItem)}
        key={"rowItem" + rindex}
        className={`${
          rowItem["status"] === "Active" ? "primary" : "disabled"
        } ${props?.selectedRows === rowItem ? "selectedrow" : ""} ${props.supplies && showBorder ? "border-class" : ""}`}
      >
        {headCells?.map((hcellItem, index) => (
          <Td
            key={"rowCell" + index}
            className={`${
              hcellItem?.rowclassName ? hcellItem?.rowclassName : ""
            }`}
          >
            {renderRowCell(rowItem, hcellItem, index, rindex)}
          </Td>
        ))}
      </Tr>
    {isMobile && props.divider ? <Divider/> : null}
    </>
  );
};
export default ThemeTableRow;
