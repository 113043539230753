const Columns = [
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: true,
  },
  {
    id: "isuueId",
    numeric: false,
    disablePadding: true,
    label: "Issue #",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: true,
  },
  {
    id: "lastupdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    isSortable: true,
  },
  {
    id: "lastupdatedtime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    isSortable: false,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    rowclassName: "",
    className: "pr-30",
  },
];

const ColumnsRes = [
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "lastupdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "lastupdatedtime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    isSortable: false,
    isCollapsible: true,
  }
];

const DetailsColumns = [
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: false,
  },
  {
    id: "issuueId",
    numeric: false,
    disablePadding: true,
    label: "Issue #",
    isSortable: false,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: false,
  },
  {
    id: "openedOn",
    numeric: false,
    disablePadding: false,
    label: "Open Date",
    isSortable: false,
  },
  {
    id: "lastupdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    isSortable: false,
  },
  {
    id: "lastupdatedtime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    isSortable: false,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: false,
  },
];

export { Columns, ColumnsRes, DetailsColumns};
