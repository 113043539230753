/**
 * Used for perform or call function based on event for different components
 */
const EventEmitter = {
  events: {},
  dispatch: function (event, data) {
    if (!this.events[event]) return;
    this.events[event].forEach((callback) => callback(data));
  },
  subscribe: function (event, callback) {
    if (!this.events[event]) this.events[event] = [];
    this.events[event].push(callback);
  },
  unsubscribe: function (event, callback) {
    if (this.events[event] && this.events[event][callback]) {
      this.events[event].filter((callbackitem) => callbackitem !== callback);
    }
  },
};
module.exports = { EventEmitter };
