import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { firebase} from '../firebase-config';

/**
 * Get Count of documents in a collection
 * @param {string} doc - The name of the Firestore collection
 * @param {Function} onSuccess - Callback for successful document retrieval
 * @param {Function} onError - Callback for handling errors
 */
export async function getDocCount(doc, onSuccess, onError) {
  const colRef = collection(firebase, doc);

  try {
    const querySnapshot = await getDocs(colRef);
    const results = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    onSuccess && onSuccess(results);
  } catch (error) {
    onError && onError({ status: false, error: error.message });
  }
}

/**
 * Get documents ordered by createdOn field
 * @param {string} doc - The name of the Firestore collection
 * @param {Function} onSuccess - Callback for successful document retrieval
 * @param {Function} onError - Callback for handling errors
 */
export async function getDocCountAl(doc, onSuccess, onError) {
  const colRef = collection(firebase, doc);
  const q = query(colRef, orderBy('createdOn', 'desc'));

  try {
    const querySnapshot = await getDocs(q);
    const results = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    onSuccess && onSuccess(results);
  } catch (error) {
    onError && onError({ status: false, error: error.message });
  }
}
