import React, {useEffect} from "react";
import { Button, TextField } from "@material-ui/core";
import { updateGroup } from "../../../services/apiservices/groups";

const EditGroup = (props) => {
  const [newGroup, setNewGroup] = React.useState(props?.group?.title);
  const [newGroupDescription, setNewGroupDescription] = React.useState(
    props?.group?.description
  );
  const [errors, setErrors] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const InitFields = () => {
    setNewGroup(props?.group?.title);
    setNewGroupDescription(props?.group?.description);
  }

  useEffect(()=>{
    InitFields();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props?.group])
  const onEditGroup = async () => {
    if (props?.group?.groupId) {
      setIsLoading(true);
      await updateGroup(
        props?.group?.groupId,
        {
          title: newGroup,
          description: newGroupDescription,
        },
        (res) => {
          setIsLoading(false);
          props?.onSuccess && props?.onSuccess();
          props?.onClose && props?.onClose();
        },
        (resError) => {
          setIsLoading(false);
        }
      );
    } else {
      setIsLoading(false);
      setErrors({ newGroup: "Title is Required" });
    }
  };

  return (
    <div className="p-15">
      <div className="mb-15">
        <TextField
          size="small"
          fullWidth={true}
          name="newGroup"
          id="newGroup"
          label="Group Title"
          variant="outlined"
          placeholder="Group Title"
          onChange={(event) => {
            setErrors({ newGroup: "" });
            setNewGroup(event.target.value);
          }}
          value={newGroup ? newGroup : ""}
        />
      </div>
      {typeof errors?.newGroup !== "undefined" && errors?.newGroup !== "" && (
        <span className="small error color-danger">{errors?.newGroup}</span>
      )}

      <div>
        <TextField
          size="small"
          fullWidth={true}
          multiline={true}
          rows={4}
          name="newGroupDescription"
          id="newGroupDescription"
          label="Group Description"
          variant="outlined"
          className="mb-15"
          placeholder="Group Description"
          onChange={(event) => {
            setNewGroupDescription(event.target.value);
          }}
          value={newGroupDescription ? newGroupDescription : ""}
        />
      </div>
      <div className="mv-12 d-flex flex-justify-flexend pv-10">
        <Button
          className="flatbutton"
          type="button"
          variant={"contained"}
          color="primary"
          onClick={onEditGroup}
          disabled={isLoading ? true : false}
        >
          {isLoading ? "Loading..." : "Update Group"}
        </Button>
        <Button
          className="flatbutton ml-10"
          type="button"
          variant={"contained"}
          disabled={isLoading ? true : false}
          onClick={props?.onClose}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
export default EditGroup;
