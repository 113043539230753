/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { TextField, Button } from "@material-ui/core";
import moment from "moment";
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CreateClientTask = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [taskDataError, setTaskDataError] = useState({});
  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setTaskData({ ...taskData, [name]: value });
    setTaskDataError({ ...taskDataError, [name]: "" });
  };
  const handleInputDateChange = (name, newDate) => {
    let newDateF = moment(newDate).format("MM/DD/YYYY");

    setTaskData({ ...taskData, [name]: newDateF });
    setTaskDataError({ ...taskDataError, [name]: "" });
  };

  const initEditData = (Task) => {
    setTaskData({ title: Task?.title, description: Task?.description, startDate: Task?.startDate, endDate: Task?.endDate });
  };

  useEffect(() => {
    if (
      props?.Task &&
      typeof props?.Task?.taskId !== "undefined" &&
      props?.Task?.taskId !== ""
    ) {
      setIsEditMode(true);
      initEditData(props?.Task);
    }
  }, [props?.Task, props?.Task?.taskId]);

  const validate = () => {
    let valid = true;
    let allErrors = taskDataError;
    if (typeof taskData?.title === "undefined" || taskData?.title === "") {
      valid = false;
      allErrors = { ...allErrors, title: "Task Name is Required" };
    } else {
      allErrors = { ...allErrors, title: "" };
    }
    if (
      typeof taskData?.description === "undefined" ||
      taskData?.description === ""
    ) {
      valid = false;
      allErrors = {
        ...allErrors,
        description: "Task Description is Required",
      };
    } else {
      allErrors = { ...allErrors, description: "" };
    }
    setTaskDataError(allErrors);
    return valid;
  };

  const onAddTask = () => {
    if (true === validate()) {
      if (isEditMode === true) {
        props?.OnEditTask && props?.OnEditTask(props?.Task?.taskId, taskData);
      } else {
        props?.onCreateNewTask && props?.onCreateNewTask(taskData);
      }
      props?.onClose();
    }
  };
  const onCancelTaskSave = () => {
    props?.onClose();
  };
  return (
    <>
      <div className="stepSectionContent p-12">
        <div className="newTaskFieldsWrap mt-10 mb-20">
          <label>Task Name </label>
          <div className="formFieldwrap formFieldwrap taskname">
            <TextField
              fullWidth={true}
              size="small"
              name="title"
              id="title"
              label="New Task Name"
              variant="outlined"
              placeholder="New Task Name"
              onChange={handleInputChange}
              value={taskData?.title ? taskData?.title : ""}
            />
            {typeof taskDataError?.title !== "undefined" &&
              taskDataError?.title !== "" && (
                <span className="small error color-danger">
                  {taskDataError?.title}
                </span>
              )}
          </div>
        </div>

        <div className="newTaskFieldsWrap formFieldwrap mb-20 ">
          <label>Description</label>
          <div className="formFieldwrap taskdescription">

            <TextField
              fullWidth={true}
              size="small"
              name="description"
              id="description"
              label="New Task Description"
              variant="outlined"
              placeholder="New Task Description"
              onChange={handleInputChange}
              value={taskData?.description ? taskData?.description : ""}
            />
            {typeof taskDataError?.description !== "undefined" &&
              taskDataError?.description !== "" && (
                <span className="small error color-danger">
                  {taskDataError?.description}
                </span>
              )}
          </div>
        </div>
        {props.shifttitle === "Opentasks" && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="newTaskFieldsWrap formFieldwrap mb-20">
              <label>Task Start Date</label>
              <div className="formFieldwrap taskdescription">
                <DatePicker
                  label="Start Date"
                  inputFormat="MM/dd/yyyy"
                  mask="__:__ _M"
                  value={taskData?.startDate || null}
                  onChange={(date) => handleInputDateChange("startDate", date)}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        placeholder="Start Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    ),
                  }}
                />
                {typeof taskDataError?.startDate !== "undefined" &&
                  taskDataError?.startDate !== "" && (
                    <span className="small error color-danger">
                      {taskDataError?.startDate}
                    </span>
                  )}
              </div>
            </div>

            <div className="newTaskFieldsWrap formFieldwrap mb-20">
              <label>Task End Date</label>
              <div className="formFieldwrap taskdescription">
                <DatePicker
                  label="End Date"
                  inputFormat="MM/dd/yyyy"
                  mask="__:__ _M"
                  value={taskData?.endDate || null}
                  onChange={(date) => handleInputDateChange("endDate", date)}
                  slots={{
                    textField: (params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        placeholder="End Date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    ),
                  }}
                />
                {typeof taskDataError?.endDate !== "undefined" &&
                  taskDataError?.endDate !== "" && (
                    <span className="small error color-danger">
                      {taskDataError?.endDate}
                    </span>
                  )}
              </div>
            </div>
          </LocalizationProvider>
        )}
        <div className="newTaskFieldsWrap formFieldwrap mb-20  ">
          <Button
            variant="contained"
            color="primary"
            className="flatbutton themebutton button-primary buttonblack mr-15"
            onClick={onAddTask}
          >
            {isEditMode === true ? "Update" : "Save"}
          </Button>
          <Button
            variant="contained"
            className="flatbutton themebutton"
            onClick={onCancelTaskSave}
          >
            Cancel
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateClientTask;
