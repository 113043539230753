/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { loginWithEmail } from "../../services/firebase";

import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  FormControlLabel,
  Checkbox,
  CssBaseline,
} from "@material-ui/core";
import validator from "validator";
import Input from "@material-ui/core/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPencilAlt, faLock } from "@fortawesome/free-solid-svg-icons";

import { Navigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import AuthContext from "../../hooks/authContext";
import loginBg from "../../assets/images/pc_loginBg.jpg";
import cmsGrayTextLogin from "../../assets/images/csmGrayTextLogin.png";
const styles = (theme) => ({
  pageroot: {
    background: "url(" + loginBg + ") top center no-repeat",
    backgroundSize: "cover",
    height: "100vh",
    paddingTop: theme.spacing(2),
  },
  paper: {},
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    background: "#078037",
    color: "#fff",
    "&:hover": {
      background: "#078037",
    },
  },
});
const SignIn = (props) => {
  const auth = useContext(AuthContext);
  let history = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [state, setState] = useState({
    formState: { username: "", password: "" },
    loading: false,
    sRedirect: "",
    remember: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name !== "") {
      setState({ ...state, formState: { ...state.formState, [name]: value } });
    }
  };
  const setInitState = () => {
    setState({
      ...state,
      remember: localStorage.remember,
      sRedirect: "",
      formState: {
        ...state.formState,
        username: localStorage.username,
        // password: localStorage.password,
      },
    });
  };
  useEffect(() => {
    if (localStorage.remember && localStorage.username !== "") {
      setInitState();
    }
  }, []);
  const redirecifloggedin = () => {
    history("/dashboard");
  };
  useEffect(() => {
    if (auth.status === true) {
      redirecifloggedin();
    }
  }, [auth.status]);

  const onChangeCheckbox = (event) => {
    setState({ ...state, remember: event.target.checked });
  };

  const userlogin = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
    setState({ ...state, loading: true });
    const { remember } = state;
    const { username, password } = state.formState;
    if (username !== "" && password !== "") {
      if (remember) {
        localStorage.username = username;
        localStorage.password = password;
        localStorage.remember = remember;
      } else {
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        localStorage.removeItem("remember");
      }
      if (!validator.isEmail(username)) {
        setErrorMessage("Email Address is not Valid");
        setState({
          ...state,
          sRedirect: "",
          loading: false,
        });
      } else {
        let loginRes = await loginWithEmail(username, password);
        if (loginRes?.token && loginRes?.token !== "") {
          setState({ ...state, loading: false });
          setTimeout(() => {
            auth.login(loginRes?.token);
            history("/dashboard");
          });
        } else {
          setErrorMessage("Email or password not match");
          setState({ ...state, sRedirect: "", loading: false });
        }
      }
    } else {
      setErrorMessage("Email and/or Password are required");
      setState({
        ...state,
        sRedirect: "",
        loading: false,
      });
    }
    setState({ ...state, loading: false });
  };

  const { formState, loading, remember, sRedirect } = state;
  const { classes } = props;
  return (
    <div className={classes.pageroot}>
      {sRedirect !== "" && <Navigate to={sRedirect} />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={`${classes.paper} loginwrap sectionBox`}>
          <img className="cmslogo" src={cmsGrayTextLogin} alt="PROTeam Login" />
          
          <form className={classes.form} noValidate>
            <div className="formFieldGroup">
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.username}
                autoComplete="email"
                className="input"
                name="username"
                id="username"
                placeholder="Email"
                startAdornment={
                  <span className="input-adornment">
                    <FontAwesomeIcon icon={faUser} />
                  </span>
                }
              />
            </div>
            <div className="formFieldGroup">
              <Input
                fullWidth={true}
                disableUnderline={true}
                onChange={handleInputChange}
                value={formState.password}
                autoComplete="current-password"
                className="input"
                name="password"
                id="password"
                placeholder="Password"
                type="password"
                startAdornment={
                  <span className="input-adornment">
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </span>
                }
              />
            </div>

            <div className="loginbottom">
              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    checked={Boolean(remember)}
                    onChange={onChangeCheckbox}
                  />
                }
                label="Remember me"
              />
              <a href="/forgotpassword">
                <FontAwesomeIcon icon={faLock} /> Forgot password?
              </a>
            </div>
            <Button
              type="submit"
              fullWidth={true}
              className={`${classes.submit}`}
              color={"primary"}
              variant={"contained"}
              onClick={userlogin}
              disabled={loading ? true : false}
            >
              {loading ? "Loading..." : "LOG IN"}
            </Button>
            {errorMessage !== "" && (
              <Typography className="text-center" color="error" variant="body2">
                {errorMessage}
              </Typography>
            )}
            {successMessage !== "" && (
              <Typography
                className="text-center"
                color="primary"
                variant="body2"
              >
                {successMessage}
              </Typography>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(SignIn);
