// import React from "react";
// import ReactDOM from "react-dom";
// // import dotEnv from "dotenv";
// import reportWebVitals from "./reportWebVitals";
// import App from "./app/App";
// import "./assets/styles/styles.scss";

// // dotEnv.config();

// ReactDOM.render(<App />, document.getElementById("root"));
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./app/App";
import reportWebVitals from './reportWebVitals';
import "./assets/styles/styles.scss";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
