import { collection, getDocs, query, where, doc, setDoc, Timestamp, writeBatch, deleteField } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { firebase } from "../firebase-config";
import { COLLECTIONS } from "../config";
import axiosInstance from '../axios';
import { BaseUrl } from '../config';

/**
 * Get Document list from client collection
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */

export const getTimesheetList = async (formData, onSuccess, onError) => {
  const colRef = collection(firebase, COLLECTIONS.TIMESHEETS);

  if (formData?.appointmentDate) {
    const q = query(colRef, where("appointmentDate", "==", formData?.appointmentDate));
    try {
      const querySnapshot = await getDocs(q);
      const results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    } catch (error) {
      console.error("Error getting documents: ", error);
      onError && onError(error);
    }
  } else {
    try {
      const querySnapshot = await getDocs(colRef);
      const results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    } catch (error) {
      console.error("Error getting documents: ", error);
      onError && onError(error);
    }
  }
};

export const getTimesheetListReport = async (formData, onSuccess, onError) => {
  const colRef = collection(firebase, COLLECTIONS.TIMESHEETS);
  const q = query(
    colRef,
    where("appointmentDateTime", ">=", formData.startDate),
    where("appointmentDateTime", "<=", formData.endDate),
    where("completed", "==", true)
  );
  
  try {
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      results.push({ id: doc.id, ...data });
    });
    onSuccess && onSuccess(results);
  } catch (error) {
    console.error("Error getting documents: ", error);
    onError && onError(error);
  }
};

export const timeSheetUpdate = async (id, formData, onSuccess, onError) => {
  const colRef = collection(firebase, COLLECTIONS.TIMESHEETS);
  const documentRef = doc(colRef, id);

  try {
    await setDoc(documentRef, {
      ...formData,
      updatedAt: new Date().getTime(),
    }, { merge: true });

    const responseItem = formData;
    let shiftTitle = "";

    for (let taskId in formData.tasks) {
      shiftTitle = formData.tasks[taskId]["title"];
    }

    if (shiftTitle !== "") {
      const data = formData.apoointmentDetails.forms[0];
      let shiftFields = {};
      data.values.map((itm) => {
        itm.id = itm.fieldID;
        if (itm.name.trim() === "ShiftName" || itm.name.trim() === "Shift Name") {
          itm.value = shiftTitle !== "" ? shiftTitle : itm.name.value;
          shiftFields = itm;
        }
        return itm;
      });

      await axiosInstance.post(
        `${BaseUrl}acuityUpdateAppointments`,
        { forms: [shiftFields], apptId: formData.appointmentId }
      );
    }
    onSuccess && onSuccess({ status: true, data: responseItem });
  } catch (error) {
    if (error.code === 5) {
      onError && onError({ status: false, message: "Not Found" });
    }
    onError && onError({ status: false, error: error.code });
  }
};


export const timeSheetUpdateTime = async (id, formData, onSuccess, onError) => {
  try {

    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;

    if (!id) {
      throw new Error("Document ID is required");
    }

    const colRef = collection(firebase, COLLECTIONS.TIMESHEETS);
    // Prepare update data
    const updateData = {
      ...formData,
      updatedBy: currentUserId,
      updatedAt: Timestamp.now(),
    };

    if (formData.checkInDateTime) {
      updateData.checkInUpdatedBy = currentUserId;
    }
    if (formData.checkOutDateTime) {
      updateData.checkOutUpdatedBy = currentUserId;
    }

    await setDoc(doc(colRef, id), updateData, { merge: true });

    onSuccess && onSuccess({ status: true, data: updateData });
  } catch (error) {
    console.error("Error updating timesheet:", error);

    if (error.code === 'not-found') {
      onError && onError({ status: false, message: "Document Not Found" });
    } else {
      onError && onError({ status: false, error: error.message });
    }
  }
};

export const timeSheetDeleteTime = async (userId, date, fieldsToDelete, onSuccess, onError) => {
  try {
    const auth = getAuth();
    const currentUserId = auth.currentUser?.uid;

    if (!currentUserId) {
      throw new Error("User is not authenticated");
    }

    const collectionRef = collection(firebase, COLLECTIONS.TIMESHEETS);
    const q = query(
      collectionRef,
      where('apoointmentDetails.id', '==', userId),
      where('appointmentDate', '==', date)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      onSuccess && onSuccess({ status: true, message: "No documents found with the specified user ID and date" });
      return;
    }

    const batch = writeBatch(firebase);
    let updatesMade = false;

    querySnapshot.forEach((docSnapshot) => {
      const docRef = docSnapshot.ref;
      const docData = docSnapshot.data();
      const updateData = {
        updatedBy: currentUserId,
        updatedAt: Timestamp.now(),
      };

      let shouldUpdate = false;

      // Loop through fields to delete
      fieldsToDelete.forEach((field) => {
        if (docData.hasOwnProperty(field)) {
          updateData[field] = deleteField();
          shouldUpdate = true;
        }
      });

      if (shouldUpdate) {
        batch.update(docRef, updateData);
        updatesMade = true;
      }
    });

    if (!updatesMade) {
      onSuccess && onSuccess({ status: true, message: "No fields to delete" });
    } else {
      await batch.commit();
      onSuccess && onSuccess({ status: true, message: "Fields deleted successfully from all relevant documents" });
    }
  } catch (error) {
    console.error("Error deleting fields:", error);
    onError && onError({ status: false, error: error.message });
  }
};


export const onTimeUpdateTimesheetList = async (
  formData,
  onSuccess,
  onError
) => {
  const colRef = collection(firebase, COLLECTIONS.TIMESHEETS);
  const q = query(colRef, where("appointmentDateTime", "<", "2023-08-07T00:00:00-0400"));

  try {
    const querySnapshot = await getDocs(q);
    const results = [];
    let test = false;
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      if (!data?.completed) {
        if (test) {
          // console.log(moment.tz(data.appointmentDateTime, "America/New_York").format('YYYY-MM-DDTHH:mm:ssZ'));
          // console.log(moment(data.appointmentDateTime).toDate());
          // data.checkInDateTime = firebase.firestore.Timestamp.fromDate(moment(data.appointmentDateTime).tz(data?.apoointmentDetails?.duration?.calendarTimezone || 'America/New_York').toDate());
          // data.checkOutDateTime = firebase.firestore.Timestamp.fromDate(moment(data.appointmentDateTime).tz(data?.apoointmentDetails?.duration?.calendarTimezone || 'America/New_York').add(data?.apoointmentDetails?.duration, 'minutes').toDate());
          // data.completed = true;
          // colRef.doc(doc.id).set(data, { merge: true });
          // doc.update({...data});
          // colRef.set(data, { merge: true });
          // console.log(data);
          // console.log(doc.id);
          // test = true;
        }
      }
    });
    onSuccess && onSuccess(results);
  } catch (error) {
    console.error("Error getting documents: ", error);
    onError && onError(error);
  }
};
