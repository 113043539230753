/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { TextField, Typography } from "@material-ui/core";
import EmployeeStepsContext from "../../../hooks/EmployeeStepsContext";
import { acuitygetEmployees } from "../../../services/apiservices/acuity";
import SingleSelect from "../../../components/SelectField/SingleSelect";

const AcuityProfile = (props) => {
  const stepsContext = React.useContext(EmployeeStepsContext);
  const [selectedEmployee, setSelectedEmployee] = React.useState("");
  const [manualAcuityId, setmanualAcuityId] = React.useState("");
  const [allEmployees, setAllEmployees] = React.useState([]);
  const { handleInputChange } = props;
  const { stepFormErrors, stepFormValues } = stepsContext;

  useEffect(() => {
    acuitygetEmployees(
      {},
      (res) => {
        if (res?.data?.status === true) {
          let allemp = [];
          if (res?.data?.data) {
            allemp = res?.data?.data?.filter((empitem) => empitem?.name !== "");
            allemp = allemp?.map((empitem) => {
              return {
                label: `${empitem?.name} (#${empitem?.id})`,
                // label: `${empitem?.name} (${empitem?.email},${empitem?.id})`,
                value: empitem?.id,
              };
            });
          }
          
          if (stepFormValues?.acuityId !== "") {
            let selected = allemp.filter(
              (empitem) =>
                Number(empitem.value) === Number(stepFormValues?.acuityId)
            );

            if (selected && selected?.length > 0) {
              setmanualAcuityId("");
              setSelectedEmployee(stepFormValues?.acuityId);
            } else {
              setmanualAcuityId(stepFormValues?.acuityId);
              setSelectedEmployee("");
            }
          }
          setAllEmployees(allemp);
          
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }, []);

  useEffect(() => {
    if (stepFormValues?.acuityId !== "") {
      let selected = allEmployees.filter(
        (empitem) => Number(empitem.value) === Number(stepFormValues?.acuityId)
      );
      if (selected && selected?.length > 0) {
        setmanualAcuityId("");
        setSelectedEmployee(stepFormValues?.acuityId);
      } else {
        setmanualAcuityId(stepFormValues?.acuityId);
        setSelectedEmployee("");
      }
    }
  }, [allEmployees, stepFormValues]);

  const onSelectEmployee = (event) => {
    const {
      target: { value },
    } = event;
    handleInputChange({ target: { name: "acuityId", value: value } });
    setmanualAcuityId("");
    setSelectedEmployee(value);
  };
  const onManualSetAcuityid = (event) => {
    const {
      target: { value },
    } = event;
    handleInputChange({ target: { name: "manualAcuityId", value: value } });
    setSelectedEmployee("");
    setmanualAcuityId(value);
  };
  return (
    <>
      <h5 className="stepSectionTitle">Acuity Profile</h5>
      <div className="stepSectionContent">
        <SingleSelect
          label="Select Employee"
          value={selectedEmployee}
          className={`field`}
          name={"selectedEmployee"}
          onChange={(e) => onSelectEmployee(e)}
          options={[...allEmployees]}
        />
        <Typography className="text-center pv-10" variant="body2">
          Or
        </Typography>
        <div className="formFieldwrap">
          <TextField
            fullWidth={true}
            size="small"
            name="acuityid"
            id="acuityid"
            label="Acuity Id"
            variant="outlined"
            placeholder="Acuity Id"
            onChange={onManualSetAcuityid}
            value={manualAcuityId ? manualAcuityId : ""}
          />
          {typeof stepFormErrors?.acuityId !== "undefined" &&
            stepFormErrors?.acuityId !== "" && (
              <span className="small error color-danger">
                {stepFormErrors?.acuityId}
              </span>
            )}
        </div>
      </div>
    </>
  );
};

export default AcuityProfile;
