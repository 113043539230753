/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  TextField,
} from "@material-ui/core";
import confirm from "../../../components/DialogComponent/confirm";
import { addIssueResponse } from "../../../services/apiservices/issues";
import FileUploadComponent from "../../../components/Fields/FileUploadComponent";
import AttachmentsView from "../../../components/AttachmentsView/AttachmentsView";
import AuthContext from "../../../hooks/authContext";
import CommonContext from "../../../hooks/commonContext";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import {saveNotification2} from "../../../services/apiservices/notifications";
import ViewAttachments from "./ViewAttachments";
import { cleanMessage, datetimest, FormatedDates, getFormatedTime } from "../../../services/helpers";


function EscalateForm(props) {
  const commonContext = useContext(CommonContext);
  const { userProfile } = useContext(AuthContext);
  const history = useNavigate();
  const [escalated, setEscalated] = useState("");
  const [formData, setFormData] = useState({});
  const [alternativeData, setAlternativeData] = useState({});
  const [errors, setFormError] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [email, setEmail] = useState("");
  const [clients, setClients] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [newEmployees, setNewEmployees] = useState([]);
  const [clientName, setClientName] = useState([]);
  const [employeeName, setEmployeeName] = useState([]);
  const [newClientName, setNewClientName] = useState([]);
  const [newEmployeeName, setNewEmployeeName] = useState([]);

  const { IssueDetail } = props;

  const Emails = [
    "damon@procleanings.com",
    "hazel@procleanings.com",
    "nina@procleanings.com"
  ];

  const mapClientData = client => ({
    id: client.id,
    name: client.clientName,
    email: client.contact[0].email || "" 
  });

  const mapEmployeeData = employe => ({
    id: employe.id,
    name: `${employe.firstName} ${employe.lastName}`,
    email: employe.email || "" 
  });

  useEffect(() => {
    if (IssueDetail) {
      const mappedEmployees = IssueDetail.employeeData?.map(mapEmployeeData) || [];
      const mappedClients = IssueDetail.clientsDetails?.map(mapClientData) || [];

      const names = mappedClients.map(client => client.name);
      setClientName(names);
      const empName = mappedEmployees.map(emp => emp.name)
      setEmployeeName(empName);

      setEmployees(mappedEmployees);
      setClients(mappedClients);

      const { clients: matchingClients, employees: matchingEmployees } = getContacts(IssueDetail);

      const clientsData = matchingClients.map(client => client.name);
      setNewClientName(clientsData);
      const empData = matchingEmployees.map(emp => emp.name);
      setNewEmployeeName(empData);

      setNewEmployees(matchingEmployees);
      setNewClients(matchingClients);
    }
  }, [IssueDetail]);

  const getContacts = (issueDetail) => {
    const clients = issueDetail?.clientsDetails || [];
    const employees = issueDetail?.AllEmpEmails || [];
    const emails = issueDetail?.email || []; 

    const matchingClients = clients.filter(client =>
      emails?.includes(client.contact[0].email)
    ).map(mapClientData);

    const matchingEmployees = employees.filter(employee =>
      emails?.includes(employee.email)
    ).map(mapEmployeeData);

    return {
      clients: matchingClients,
      employees: matchingEmployees
    };
  };

  const onClearData = () => {
    setFormData({});
    setAlternativeData({});
    setFormError({});
    setEscalated("");
    setAttachments([]);
    setEmail("");
    setEmployees([]);
    setClients([]);
    setNewEmployees([]);
    setNewClients([]);
    setClientName([]);
    setEmployeeName([]);
    setNewClientName([]);
    setNewEmployeeName([]);
  };

  const onCancel = async () => {
    await confirm("Are you sure you want to cancel this message?").then(
      () => {
        onClearData();
        history("/issues/manage");
      },
      () => {
        console.log("cancel!");
      }
    );
  };

  const checkValid = () => {
    let errorMessages = errors;
    let isValid = true;

    if (
      typeof formData?.description === "undefined" ||
      formData?.description === ""
    ) {
      isValid = false;
      errorMessages = {
        ...errorMessages,
        description: "Field is required",
      };
    } else {
      errorMessages = { ...errorMessages, description: "" };
    }

    if (escalated === "Add Email" && email.trim() === "") {
      isValid = false;
      errorMessages = {
        ...errorMessages,
        email: "Email is required",
      };
    } else {
      errorMessages = { ...errorMessages, email: "" };
    }

    setFormError(errorMessages);
    return isValid;
  };

  let paddedIssueId;
  let newDate;
  let newTime;
  let newTimestamp;

  const emailSend = () => {
    let emailAddress = [];
    let recipients = [];

    const ClientEmails = IssueDetail?.clientData ? IssueDetail?.clientData : null;
    const EmployeeEmails = IssueDetail.employeeData?.map(emp => emp.email);
  
    const ValidEmail = [...ClientEmails, ...EmployeeEmails];

    switch (escalated) {
      case "None":
      case "":
        emailAddress = IssueDetail.email || [];
        recipients = [...newClientName, ...newEmployeeName];
        break;
      case "Client Contact":
        emailAddress = IssueDetail.clientData;
        recipients = clientName;
        break;
      case "Internal Managers":
        emailAddress = Emails;
        recipients = employeeName;
        break;
      case "All Contacts":
        emailAddress = [...IssueDetail.clientData, ...Emails];
        recipients = [...clientName, ...employeeName];
        break;
      case "Add Email":
        emailAddress = email.split(",").map(email => email.trim());
        recipients = [];
        break;
      default:
        emailAddress = IssueDetail.email || [];
        recipients = [];
    }
  
    if (IssueDetail.email?.length) {
      emailAddress = [...emailAddress, ...IssueDetail.email];
      recipients = [...new Set([...recipients, ...newClientName, ...newEmployeeName])];
    }
  
    formData.to = [...new Set(emailAddress)];

    const details = formData.to ? formData.to : null;
    const UnMatchedEmail = details.filter(emails => !ValidEmail.includes(emails));

    recipients = [...new Set([...recipients, ...UnMatchedEmail])];

    formData.recipients = [...new Set(recipients)];

    if (IssueDetail.responses && IssueDetail.responses.length > 0) {
      const sortedResponses = IssueDetail.responses.slice().sort((a, b) => b.respondOn - a.respondOn);
      const latestResponse = sortedResponses[0];

      paddedIssueId = String(IssueDetail.issueNumber).padStart(4, '0');
      newDate = FormatedDates(datetimest());
      newTime = getFormatedTime(datetimest());
      newTimestamp = `${newDate}, ${newTime}`;

      let previousDate = FormatedDates(latestResponse?.respondOn);
      let previousTime = getFormatedTime(latestResponse?.respondOn);
      let previousTimestamp = `${previousDate}, ${previousTime}`;

      alternativeData.newTimestamp = newTimestamp;
      alternativeData.previousTimestamp = previousTimestamp;
      alternativeData.currentMessage = cleanMessage(formData.description).trim();
      alternativeData.previousMessage = cleanMessage(latestResponse?.description || '').trim();

      formData.details = `New Message - Issue${paddedIssueId} (${newTimestamp})${alternativeData.currentMessage}, Previous Message - Issue${paddedIssueId} (${previousTimestamp})${alternativeData.previousMessage}`;
    } else {
      paddedIssueId = String(IssueDetail.issueNumber).padStart(4, '0');
      newDate = FormatedDates(datetimest());
      newTime = getFormatedTime(datetimest());
      newTimestamp = `${newDate}, ${newTime}`;

      alternativeData.newTimestamp = newTimestamp;
      alternativeData.currentMessage = cleanMessage(formData.description).trim();

      formData.details = `New Message - Issue${paddedIssueId} (${newTimestamp})\n\n${alternativeData.currentMessage}`;
    }
  };

  const sendNotification = async () => {

    let DataClient = [];
    let DataEmployee = [];
    let All = [];

  const { AllEmpEmails } = IssueDetail;
  
  const emailsArray = email.split(',').map(e => e.trim());

  const emailObjects = emailsArray.map(email => 
    AllEmpEmails.find(emp => emp?.email === email)
  ).filter(emailObject => emailObject && emailObject?.FCMToken);

  const details = emailObjects.map(emailObject => ({
    id: emailObject?.id || "",
    name: `${emailObject?.firstName || ""} ${emailObject?.lastName || ""}`,
    email: emailObject?.email || ""
  }));

  switch (escalated) {
    case "None":
    case "":
      DataClient = newClients;
      DataEmployee = newEmployees;
      break;
    case "Client Contact":
      DataClient = clients;
      DataEmployee = [];
      break;
    case "Internal Managers":
      DataClient = [];
      DataEmployee = employees;
      break;
    case "All Contacts":
      DataClient = clients;
      DataEmployee = employees;
      break;
    case "Add Email":
      DataClient = [];
      DataEmployee = details;
      break;
    default:
      DataClient = [];
      DataEmployee = [];
      break;
  }

  All = [...newClients, ...newEmployees];
  const combinedData = [...DataEmployee, ...DataClient, ...All];
  const uniqueData = Array.from(new Map(combinedData.map(item => [item?.id, item])).values());
  const paddedIssueId = String(IssueDetail?.issueNumber).padStart(4, '0');

    const notifications = [{
      Type: "PushNotification",
      Message: `New Message - Issue #${paddedIssueId}. Please check your email.`,
      read: false,
      Employee: uniqueData,
      extData: { type: "Issue", id: IssueDetail?.id }
    }];

    await saveNotification2(notifications);
  };

    
    const onResponse = async () => {
      if (!checkValid()) return;
    
      try {
        const confirmResponse = await confirm("Are you sure you want to respond to this issue?");
        if (!confirmResponse) {
          console.log("cancel!");
          return;
        }
    
        commonContext?.setLoader(true);
    
        const promises = [];
    
        if (escalated && escalated !== "None" && escalated !== "") {
          formData.escalated = escalated;
          promises.push(emailSend());
          promises.push(sendNotification());
        }

        if ((!escalated || escalated === "None" || escalated === "") && IssueDetail.escalated_to_admin === true) {
          promises.push(emailSend());
          promises.push(sendNotification());
        }
    
        if (attachments?.length) {
          formData.attachments = attachments;
        }

        alternativeData.clientNames = clientName ? clientName[0] : null;
    
        const respondBy = {
          id: userProfile.id,
          name: `${userProfile.firstName} ${userProfile.lastName}`,
        };
    
        await Promise.all(promises);
    
        await addIssueResponse(
          IssueDetail.id,
          formData,
          respondBy,
          IssueDetail.issueNumber,
          alternativeData,
          (res) => {
            onClearData();
            props.fetchIssuesDetails();
            commonContext?.setLoader(false);
          },
          (error) => {
            console.log("error", error);
            commonContext?.setLoader(false);
          }
        );
      } catch (error) {
        console.log("error", error);
        commonContext?.setLoader(false);
      }
    };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setFormError({ ...errors, [name]: "" });
  };

  const onChangeInputEscalated = (e) => {
    const { value } = e.target;
    setEscalated(value);

    if (value === "None" || value.trim() === "" || value !== "Add Email") {
      setEmail("");
    }

    if (value === "None" || value.trim() === ""){
      setFormData({ ...formData, escalatedTo: "" });
    } else {
      setFormData({ ...formData, escalatedTo: "Escalate" });
    }
  };

  const onFileUpload = (files) => {
    setAttachments(prevAttachments => {
      const newAttachments = [...prevAttachments, ...files];
      if (newAttachments.length > 5) {

        return prevAttachments;
      }
      return newAttachments;
    });
  };

  const handleDelete = (fileToDelete) => {
    setAttachments((prevAttachments) => {
      return prevAttachments.filter((file) => file !== fileToDelete);
    });
  };

  return (
    <div className="issueDetails mb-20 formtxtbtm">
      <h3 className="formtxt">Description</h3>
      <p>{IssueDetail?.description}</p>
      <ViewAttachments attachments={IssueDetail?.attachments}/>
      <Divider fullWidth className="mt-10"/>
      <FormControl fullWidth className="mv-15 deissue">
        <TextField
          id="outlined-issue-details-input"
          label="Message"
          multiline
          rows={4}
          name="description"
          value={formData?.description || ""}
          variant="outlined"
          onChange={onChangeInput}
        />
        {errors?.description && (
          <span className="small error color-danger">{errors?.description}</span>
        )}
      </FormControl>

      <div className="d-flex flex-center delete">
        <div className="wNew-50 d-flex flex-center delete">
          <span className="fw-bold w-30">Escalate Issues?</span>
          <SingleSelect
            label="Escalate Issues?"
            value={escalated || ""}
            name={"Escalate Issues?"}
            onChange={onChangeInputEscalated}
            options={[
              { label: "None", value: "" },
              { label: "Client Contact", value: "Client Contact" },
              { label: "Internal Managers", value: "Internal Managers" },
              { label: "All Contacts (Client contacts, Internal managers)", value: "All Contacts" },
              { label: "Add Email (separate by commas,)", value: "Add Email" },
            ]}
          />
        </div>
        {escalated === "Add Email" && (
        <>
          <TextField
            name="email"
            value={email}
            className="w-100 ml-15 issuesPadding"
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
          />
          {errors?.email && (
            <span className="small error color-danger">{errors?.email}</span>
          )}
        </>
        )}
      </div>
      <FormControl fullWidth className="mv-15">
        <FormLabel className="mr-20 mb-5 strong">Attachments</FormLabel>
        <AttachmentsView attachments={attachments} onDelete={handleDelete}/>
        <FileUploadComponent attachments={attachments} onuploadfile={onFileUpload}/>
      </FormControl>

      <div className="buttonswrap">
        <Button
          variant="contained"
          className="flatbutton themebutton mr-15"
          color="primary"
          onClick={onResponse}
        >
          Response
        </Button>
        <Button
          variant="contained"
          className="flatbutton themebutton mr-15"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default EscalateForm;
