
const Columns = [
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'clientname',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'empname',
    numeric: false,
    disablePadding: false,
    label: 'Employee Name',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
    rowclassName: 'text-right',
    className: 'text-right pr-30',
  },
];

const ColumnsRes = [
  {
    id: 'createdOn',
    numeric: false,
    disablePadding: false,
    label: 'Date',
  },
  {
    id: 'empname',
    numeric: false,
    disablePadding: false,
    label: 'Employee Name',
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Title',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
];

export { Columns, ColumnsRes };
