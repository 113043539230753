import React from "react";
import "./loadingStyle.css";

const Loader = (props) => {
  return <div className="loading">Loading&#8230;</div>;
};

export const LoaderABS = (props) => {
  return <div className="loading-absolute">Loading&#8230;</div>;
};

export default Loader;
