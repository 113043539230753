/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import moment from "moment";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  TextField,
  Button,
} from "@material-ui/core";

import SingleSelect from "../../../components/SelectField/SingleSelect";
import validator from "validator";
import confirm from "../../../components/DialogComponent/confirm";
import { getuniqueId, splitTimeValues } from "../../../services/helpers";
const CreateShift = (props) => {
  const ShiftDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [isEditMode, setEditmode] = React.useState(false);
  const [shiftData, setShiftData] = React.useState({});
  const [shiftDataError, setShiftDataError] = React.useState({});
  const [shiftDaysstate, setShiftDays] = React.useState();
  const [startTime, setStartTime] = React.useState();
  const [endTime, setEndTime] = React.useState();
  const { allShifts } = props;

  const initEditdata = () => {
    let shiftData = props?.shiftData ? props?.shiftData : {};
    if (
      allShifts?.length > 0 &&
      typeof props?.edit !== "undefined" &&
      props?.edit !== ""
    ) {
      setEditmode(true);
      let isCustom = allShifts?.filter(
        (asitem) => asitem.title === props?.shiftData?.title
      );

      if (!isCustom.length > 0) {
        shiftData.shift = "Custom Shift";
        shiftData.customShiftName = props?.shiftData?.title;
      } else {
        shiftData.shift = props?.shiftData?.title;
      }
      let sTime = splitTimeValues(shiftData?.startTime);
      let startTimeedit = moment().set('hour', sTime?.hour).set('minute', sTime?.minute);
      setStartTime(startTimeedit);
      let eTime = splitTimeValues(shiftData?.endTime);
      let endTimeedit = moment().set('hour', eTime?.hour).set('minute', eTime?.minute);
      setEndTime(endTimeedit);
    }
    setShiftData({ ...shiftData });

  };
  useEffect(() => {
    if (allShifts?.length > 0) initEditdata();
  }, [allShifts, props?.edit, props?.shiftData]);

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    let shiftDatas = { ...shiftData, [name]: value };
    let shiftDatasError = { ...shiftDataError, [name]: "" };
    if (name === "shift") {
      let alltitlse = props.shiftsList.map((title) => {
        let ct;
        if (title.title === shiftDatas.shift) {
          ct = title.title

        }
        return ct;
      });

      alltitlse = alltitlse.filter(function (element) {
        return element !== undefined;
      });
      let totalcon = alltitlse.length;

      shiftDatas = { ...shiftDatas, customShiftName: "" };
      shiftDatas = { ...shiftDatas, titlen: totalcon };
      shiftDatasError = { ...shiftDatasError, customShiftName: "" };

    }


    setShiftData(shiftDatas);
    setShiftDataError(shiftDatasError);

  };



  const handleShiftCheckChange = (event) => {
    const {
      target: { name, checked },
    } = event;
    let selectedDays = shiftData?.day ? shiftData?.day : [];
    let finditem = selectedDays.includes(name);
    if (checked && !finditem) {
      selectedDays = [...selectedDays, name];
    } else if (finditem && !checked) {
      selectedDays = [...selectedDays.filter((ditem) => ditem !== name)];
    }



    setShiftData({ ...shiftData, day: selectedDays });
    setShiftDataError({ ...shiftDataError, day: "" });



  };

  const validate = () => {

    let isvalid = true;
    let errorMessages = shiftDataError;

    if (typeof shiftData?.shift === "undefined" || shiftData?.shift === "") {
      isvalid = false;
      errorMessages = { ...errorMessages, shift: "Shift is Required" };
    } else if (shiftData?.shift === "Custom Shift") {
      if (
        typeof shiftData?.customShiftName === "undefined" ||
        shiftData?.customShiftName === ""
      ) {
        isvalid = false;
        errorMessages = {
          ...errorMessages,
          customShiftName: "Custom Shift Data is Required",
        };
      } else if (
        !validator.isLength(shiftData?.customShiftName, { min: 0, max: 15 })
      ) {
        isvalid = false;
        errorMessages = {
          ...errorMessages,
          customShiftName:
            "Custom Shift Name should not longer then 15 character",
        };
      }
    } else {
      errorMessages = { ...errorMessages, shift: "" };
    }

    if (!shiftData?.day?.length > 0) {
      isvalid = false;
      errorMessages = {
        ...errorMessages,
        day: "Shift Day is Required",
      };
    } else {
      errorMessages = { ...shiftDataError, day: "" };
    }
    if (
      typeof shiftData?.startTime === "undefined" ||
      shiftData?.startTime === ""
    ) {
      isvalid = true;
      errorMessages = { ...errorMessages, startTime: "" };
    } else {
      errorMessages = { ...errorMessages, startTime: "" };
    }

    if (
      typeof shiftData?.endTime === "undefined" ||
      shiftData?.endTime === ""
    ) {
      isvalid = true;
      errorMessages = { ...errorMessages, endTime: "" };
    } else {
      errorMessages = { ...errorMessages, endTime: "" };
    }


    if (
      typeof shiftData?.startDate === "undefined" ||
      shiftData?.startDate === ""
    ) {
      isvalid = true;
      errorMessages = { ...errorMessages, startDate: "" };
    } else {
      errorMessages = { ...errorMessages, startDate: "" };
    }

    if (
      typeof shiftData?.endDate === "undefined" ||
      shiftData?.endDate === ""
    ) {
      isvalid = true;
      errorMessages = { ...errorMessages, endDate: "" };
    } else {
      errorMessages = { ...errorMessages, endDate: "" };
    }

    setShiftDataError(errorMessages);
    return isvalid;
  };

  const allShiftsOptions = [
    ...allShifts,
    {
      title: "Custom Shift",
      value: "Custom Shift",
    },
  ].map((ShiftItem) => {
    return {
      label: `${ShiftItem.title}`,
      value: ShiftItem?.title,
      shiftItem: ShiftItem,
    };
  });
  const clearShiftForm = () => {
    setShiftData({});
    setShiftDataError({});
    setStartTime(null);
    setEndTime(null);
    setShiftDays(
      shiftDaysstate?.map((sitem) => {
        return { ...sitem, checked: false };
      })
    );
  };

  const proceedtoCreateShift = (saveShiftData) => {

    let startDateF = "";
    let endDateF = "";
    let newShift = {
      title:
        saveShiftData?.shift === "Custom Shift"
          ? saveShiftData?.customShiftName
          : saveShiftData?.shiftData?.title,
      titlen: saveShiftData?.titlen,
      shiftId: saveShiftData?.shiftId,
      day: saveShiftData?.day,
      status: saveShiftData?.status,
      startTime: moment(startTime).format('hh:mm A'),
      endTime: moment(endTime).format('hh:mm A'),
      startDate: startDateF,
      endDate: endDateF,
      opentasksstatus: saveShiftData?.shift === "Opentasks" ? true : false,
    };

    if (isEditMode === true && props.onCreateShift) {
      props.onCreateShift(props?.edit, newShift);
      props?.onClose();
    } else {
      props.onCreateShift && props.onCreateShift(newShift);
      clearShiftForm();
    }
  };

  const saveShift = () => {
    if (true === validate()) {
      let newid = getuniqueId("shift");
      if (isEditMode === true) {
        newid = props?.edit;
      }
      let selectedShiftData = allShiftsOptions?.filter(
        (sftItem) => sftItem?.value === shiftData?.shift
      );
      selectedShiftData = selectedShiftData[0].shiftItem;

      let saveShiftData = {
        shiftId: newid,
        shift: shiftData?.shift,

        day: shiftData?.day,
        shiftData: selectedShiftData,
        customShiftName: shiftData?.customShiftName,
        titlen: shiftData?.titlen,
        status: true,
      };

      if (props?.shiftsList?.length > 0) {
        let isfind = false;

        props?.shiftsList?.filter((sitem) => {

          if (
            sitem?.day?.join("-") === saveShiftData.day?.join("-") &&
            sitem?.shift === saveShiftData.shift
          ) {
            isfind = true;
          }

          return sitem;
        });

        if (isfind && !isEditMode) {
          confirm("Do you want to create same shift again?").then(() => {
            proceedtoCreateShift(saveShiftData);
          });
        } else {
          proceedtoCreateShift(saveShiftData);
        }
      } else {
        proceedtoCreateShift(saveShiftData);
      }
    }
  };
  return (
    <>
      <div
        className={`stepSectionContent ${isEditMode === true ? "editShift" : ""
          }`}
      >
        <div className="formFieldwrap w-50 pr-20">
          <SingleSelect
            label="Select Shift"
            value={shiftData?.shift ? shiftData?.shift : ""}
            className={`field`}
            name={"shift"}
            onChange={handleInputChange}
            options={allShiftsOptions}
          />
          {typeof shiftDataError?.shift !== "undefined" &&
            shiftDataError?.shift !== "" && (
              <span className="small error color-danger">
                {shiftDataError?.shift}
              </span>
            )}
        </div>
        {shiftData?.shift && shiftData?.shift === "Custom Shift" && (
          <div className="formFieldwrap w-50 pr-20">
            <TextField
              fullWidth={true}
              size="small"
              name="customShiftName"
              id="customShiftName"
              label="Custom Shift Name"
              variant="outlined"
              placeholder="Custom Shift Name"
              onChange={handleInputChange}
              value={
                shiftData?.customShiftName ? shiftData?.customShiftName : ""
              }
            />
            {typeof shiftDataError?.customShiftName !== "undefined" &&
              shiftDataError?.customShiftName !== "" && (
                <span className="small error color-danger">
                  {shiftDataError?.customShiftName}
                </span>
              )}
          </div>
        )}

        <div className="mt-20">
          <h5 className="stepSectionTitle w-50">Shift Details</h5>
          <div className="stepSectionContent w-50 pl-15">
            <div className="formFieldwrap">
              <FormControl component="div" className={"checkboxfieldwrap"}>
                <FormLabel component="legend" className={"checkboxfieldlabel"}>
                  <strong>Select Day</strong>
                </FormLabel>
                <div className={"checkboxGroupwrap"}>
                  <FormGroup row>
                    {ShiftDays.map((shiftItem, index) => {
                      return (
                        <FormControlLabel
                          key={"shiftitemkey" + index}
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              checked={
                                shiftData?.day
                                  ? shiftData?.day.includes(shiftItem)
                                  : false
                              }
                              onChange={handleShiftCheckChange}
                              name={shiftItem}
                            />
                          }
                          label={shiftItem}
                        />
                      );
                    })}
                  </FormGroup>
                </div>
              </FormControl>
              {typeof shiftDataError?.day !== "undefined" &&
                shiftDataError?.day !== "" && (
                  <FormHelperText className="small error color-danger">
                    {shiftDataError?.day}
                  </FormHelperText>
                )}
            </div>

            {isEditMode !== true && (
              <Button
                className="flatbutton themebutton mt-12"
                color={"primary"}
                variant="contained"
                type="button"
                onClick={saveShift}
              >
                Add Shift
              </Button>
            )}
            {typeof shiftDataError?.shiftErrorGlob !== "undefined" &&
              shiftDataError?.shiftErrorGlob !== "" && (
                <FormHelperText className="small error color-danger">
                  {shiftDataError?.shiftErrorGlob}
                </FormHelperText>
              )}
            {typeof shiftDataError?.startTime !== "undefined" &&
              shiftDataError?.startTime !== "" && (
                <FormHelperText className="small error color-danger">
                  {shiftDataError?.startTime}
                </FormHelperText>
              )}
            {typeof shiftDataError?.endTime !== "undefined" &&
              shiftDataError?.endTime !== "" && (
                <FormHelperText className="small error color-danger">
                  {shiftDataError?.endTime}
                </FormHelperText>
              )}
            {typeof shiftDataError?.diff !== "undefined" &&
              shiftDataError?.diff !== "" && (
                <FormHelperText className="small error color-danger">
                  {shiftDataError?.diff}
                </FormHelperText>
              )}
          </div>
        </div>
        {isEditMode === true && (
          <>
            <Button
              type="button"
              variant="contained"
              color="primary"
              className="flatbutton themebutton mr-20"
              onClick={saveShift}
            >
              Update
            </Button>
            <Button
              type="button"
              variant="contained"
              className="flatbutton themebutton "
              onClick={() => {
                confirm(
                  "Are you sure you want to cancel , this wont be saved/ created ?"
                ).then(() => {
                  props?.onClose();
                });
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default CreateShift;
