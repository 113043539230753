
import React, { useEffect } from "react";
import { Card, Divider, List, Typography, Collapse, IconButton } from '@mui/material';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from "@material-ui/icons/Lock";
import { withStyles } from "@material-ui/core/styles";
import { Switch } from '@material-ui/core';
import { getFormatedDate } from "../../../services/helpers";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const EmployeeLabel = ({ employeeName }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        {employeeName}
    </Typography>
);

const ChannelLabel = ({ channelName }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        Name : {channelName}
    </Typography>
);

const ClientLabel = ({ clientNumbers }) => (
    <Typography variant="body" className="color-primary" style={{ fontSize: '18px', fontWeight: "800" }}>
        Client ID# : <span style={{ fontSize: "20px" }}>{clientNumbers}</span>
    </Typography>
);

const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        "&$checked": {
            transform: "translateX(12px)",
            color: theme.palette.common.white,
            "& + $track": {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: "none",
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

const ManagePagesCommonCard = ({ index, record, isOpen, handleExpandClick, redirectToDetails, columns, OnSwitchChange, loginWithEmployee,
    Employee, deleteChannelHandler, handelTooltipClicks, Client, Channel, opens }) => {

    const [switchStatus, setSwitchStatus] = React.useState(true);

    useEffect(() => {
        setSwitchStatus(
            record?.status ? record?.status : false
        );
    }, [record]);

    const handleSwitchChange = (event) => {
        let newstatus = !switchStatus;
        setSwitchStatus(newstatus);
        OnSwitchChange && OnSwitchChange(record, newstatus);
    };

    return (
        <React.Fragment key={`record-${index}`}>
            <Card style={{ boxShadow: "none", borderRadius: "0px", border: "1px solid #a9a9a9", width: "97%" }} className="mb-20">
                <div className="d-flex flex-center flex-space-between pv-5 pl-15 pr-8" style={{ backgroundColor: "#efefef" }}>
                    {Employee && record?.userName ? <EmployeeLabel employeeName={record?.userName} /> : null}
                    {Channel && record?.groupName ? <ChannelLabel channelName={record?.groupName} /> : null}
                    {Client && record?.clientNumbers ? <ClientLabel clientNumbers={record?.clientNumbers} /> : null}
                    <div>
                        {Channel === true ? <MoreHorizRoundedIcon className="color-primary" fontSize="large" onClick={() => handelTooltipClicks(record?.id)} />
                            : <MoreHorizRoundedIcon className="color-primary" fontSize="large" onClick={() => redirectToDetails(record?.id)} />}
                    </div>
                </div>
                <Divider />
                {opens === record?.id && Channel === true && (
                    <>
                        <div style={{ marginTop: "-10px", position: 'absolute', right: '5px', background: '#fff', border: '1px solid #ccc', borderRadius: '5px', zIndex: '1000' }}>
                            <svg
                                style={{ position: 'absolute', top: '-10px', right: '38px' }}
                                width="20" height="10"
                                viewBox="0 0 20 10"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <polygon points="0,10 10,0 20,10" fill="white" />

                                <line x1="0" y1="10" x2="10" y2="0" stroke="#ccc" strokeWidth="1" />

                                <line x1="10" y1="0" x2="20" y2="10" stroke="#ccc" strokeWidth="1" />
                            </svg>
                            <div style={{ cursor: 'pointer' }} >
                                <div className="d-flex flex-center" style={{ fontSize: "16px", color: 'green', fontWeight: 'bold', marginBottom: '5px', padding: '5px 15px' }} onClick={() => redirectToDetails(record?.id)}>
                                    <EditIcon fontSize="small" /> <span className="ml-5">Edit</span>
                                </div>
                                <Divider />
                                <div className="d-flex flex-center" style={{ fontSize: "16px", fontWeight: 'bold', padding: '5px 15px' }} onClick={() => deleteChannelHandler(record)}>
                                    <DeleteIcon fontSize="small" className="color-danger" /> <span className="ml-5 color-badge">Delete</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <List className="mv-15">
                    {columns.filter(column => !column.isCollapsible).map((column, columnIndex) => (
                        <div className="d-flex flex-space-between ph-25 pv-10" key={`column-${columnIndex}`}>
                            {columnIndex === 0 ? (
                                <Typography variant="body" style={{ fontSize: '16px', fontWeight: "900", color: "#3d3d3d" }}>
                                    {column?.label}
                                </Typography>
                            ) : (
                                <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}>
                                    {column?.label}
                                </Typography>
                            )}
                            <div className="w-45">
                                {columnIndex === 0 ? (
                                    <Typography variant="body" style={{ fontSize: '16px', fontWeight: "900", color: "#606060" }}>
                                        {record[column?.id]}
                                    </Typography>
                                ) : column.id === "phoneNumber" && Employee ? (
                                    <Typography variant="body" className="resLinks">
                                        {record[column?.id]}
                                    </Typography>
                                ) : column.id === "NoOfMembers" && Channel ? (
                                    <Typography variant="body" style={{ color: "#606060" }}>
                                        {record.employee ? record.employee.length : 0}
                                    </Typography>
                                ) : column.id === "shiftCount" && Client ? (
                                    <Typography variant="body" style={{ color: "#606060" }}>
                                        {record?.shiftData ? Object.keys(record.shiftData).length : ''}
                                    </Typography>
                                ) : column.id === "email" ? (
                                    <div>
                                        <a
                                            href={`mailto:${record[column?.id]}`}
                                            className="resLinks"
                                            style={{ overflowWrap: "anywhere" }}>
                                            {record[column?.id]}
                                        </a>
                                    </div>
                                ) : column.id === "createdOn" && Channel === true ? (
                                    getFormatedDate(record['createdOn'])
                                ) : (
                                    <Typography variant="body" style={{ color: "#606060" }}>
                                        {record[column?.id]}
                                    </Typography>
                                )}
                            </div>
                        </div>
                    ))}

                    <Collapse in={isOpen} timeout="auto" unmountOnExit className="w-100">
                        {columns.filter(col => col.isCollapsible).map((column, columnIndex) => (
                            <div className="d-flex flex-space-between ph-25 pv-10" key={`collapsible-column-${columnIndex}`}>
                                <Typography variant="body" style={{ fontSize: '14px', fontWeight: "450", color: "#454545" }}>
                                    {column.label}
                                </Typography>
                                <Typography variant="body" style={{ color: "#606060" }} className="w-45">
                                    {column.id === "switch" ? (
                                        <AntSwitch
                                            checked={switchStatus}
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            name="switchStatus"
                                            inputProps={{ "aria-label": "switchStatus" }}
                                        />
                                    ) : column.id === "action" && Employee === true ? (
                                        <LockIcon className='color-primary' onClick={() => loginWithEmployee(record)} />
                                    ) : (
                                        record[column.id]
                                    )}
                                </Typography>
                            </div>
                        ))}
                    </Collapse>

                </List>
                <Divider />
                <div className="d-flex flex-justify-center">
                    <IconButton className="p-0" onClick={() => handleExpandClick(record?.id)}>
                        <ExpandMoreIcon
                            fontSize="large"
                            style={{
                                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                            }}
                        />
                    </IconButton>
                </div>
            </Card>
        </React.Fragment>
    );
};

export default ManagePagesCommonCard;