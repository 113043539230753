import { firebase } from '../firebase-config';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { COLLECTIONS } from '../config';

/**
 * Get Data from USER_ACTIVITY_LOG collection
 * @param {Array} userIds - Array of user IDs to fetch data for
 * @param {Function} onSuccess - Callback function for successful retrieval
 * @param {Function} onError - Callback function for handling errors
 */

export async function getUserActivity(userIds) {
  const colRef = collection(firebase, COLLECTIONS.USER_ACTIVITY_LOG);

  try {
    if (!Array.isArray(userIds) || userIds.length === 0) {
      throw new Error("No user IDs provided.");
    }

    const userQuery = query(colRef, where('userId', 'in', userIds));
    const querySnapshot = await getDocs(userQuery);

    const userDataArray = [];

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      userDataArray.push(userData);
    });

    return userDataArray;
  } catch (error) {
    console.error('Error getting user activity: ', error);
    throw error; 
  }
}
