import axiosInstance from "../axios";
import { BaseUrl } from '../config';
import { getLoginToken } from '../storage';

export async function sendEmailWithFiles(to, subject, contenttxt, excelUrl, pdfUrl, fullPdfUrl, fullExcelUrl, reportDate) {
    try {

        const requestBody = {
            to: to,
            subject: subject,
            contenttxt: contenttxt,
            xlsAttachment: excelUrl,
            pdfAttachment: pdfUrl,
            fullPdfAttachment: fullPdfUrl,
            fullExcelAttachment: fullExcelUrl,
            date: reportDate
        };
        
        const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
        const res = await axiosInstance.post(
            `${BaseUrl}sendEmailWithFiles`,
            requestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                    withCredentials: true,
                    ...authHeaders,
                },
            }
        );

        return { success: true, status: res.status, data: res.data };

    } catch (error) {
        console.error('Error sending email:', error);
        return { success: false, status: error.response?.status || 500, error: 'Failed' };
    }
}
