import React from "react";
import { confirmable } from "react-confirm";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "4px 8px",
    display: "flex",
    alignItems: "center",
  },
  dialogTitle: {
    fontSize: "14px",
    fontWeight: "700",
    flex: "1",
    paddingLeft: "3px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, extraClasses, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={`${classes.root} ${extraClasses}`} {...other}>
      <Typography variant="h6" className={`${classes.dialogTitle} mr-20`}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size={"small"}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  dividers: {
    borderColor: theme.palette.secondaryBorder,
    borderBottom: "none",
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

class ConfirmationNew extends React.Component {
  render() {
    const {
      okLabel = "OK",
      cancelLabel = "Cancel",
      title,
      titleWrapClass,
      confirmation,
      show,
      proceed,
      dismiss,
      cancel,
      modal,
    } = this.props;

    return (
      <div>
        <Dialog
          open={show}
          className="thameSecondaryDialog"
          maxWidth={"md"}
          modal={modal}
        >
          <DialogTitle extraClasses={titleWrapClass ? titleWrapClass : ''} id="customized-dialog-title" onClose={dismiss}>
            {title}
          </DialogTitle>
          <DialogContent dividers className="thameDialogContent">
            {confirmation}
          </DialogContent>
          {(proceed || cancel) && (
            <DialogActions>
              {proceed && (
                <Button autoFocus onClick={() => proceed(true)} color="primary">
                  {okLabel ? okLabel : "Yes"}
                </Button>
              )}
              {cancel && (
                <Button autoFocus onClick={() => proceed(false)} color="primary">
                  {cancelLabel ? cancelLabel : "Cancel"}
                </Button>
              )}
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}

export default confirmable(ConfirmationNew);
