import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import { getDocCount } from "../../../services/apiservices/counters";
import { COLLECTIONS } from "../../../services/config";
import styles from "../dashboard.module.scss";
import moment from "moment/moment";

function Notifications(props) {

  const [notificationDataemail, setNotificationDataEmail] = useState([]);
  const [notificationDataapp, setNotificationDataApp] = useState([]);
  const [notificationDataboth, setNotificationDataBoth] = useState([]);

  const [totalemail, setTotalEmail] = useState([]);
  const [totalsms, setTotalSms] = useState([]);
  const [totalboth, setTotalBoth] = useState([]);

  const [progress, setProgress] = useState();
  const [frequency, setFrequency] = useState("All");
  const frequencyList = [
    { label: "All", value: "All" },
    { label: "Today", value: "Today" },

  ];

  const datefilter = (data, datet) => {

    const filterdata = data.filter(item => moment(item.createdOn).format(moment.HTML5_FMT.DATE) === datet);
    return filterdata;
  }


  const onChangeInput = (e) => {
    setFrequency(e.target.value);
    if (e.target.value === "All") {
      setTotalEmail(notificationDataemail.length);
      setTotalSms(notificationDataapp.length);
      setTotalBoth(notificationDataboth.length);

    }
    if (e.target.value === "Today") {
      let filterdate = moment().format(moment.HTML5_FMT.DATE);
      let emaildata=datefilter(notificationDataemail,filterdate);
      let smsdata=datefilter(notificationDataapp,filterdate);
      let bothdata=datefilter(notificationDataboth,filterdate);

      setTotalEmail(emaildata.length);
      setTotalSms(smsdata.length);
      setTotalBoth(bothdata.length);
    }

  };
  const updateProgress = (newval) => {
    setProgress(newval);


  };
  React.useEffect(() => {
    getDocCount(
      COLLECTIONS.NOTIFICATIONS,
      (res) => {
        updateProgress(res.length);
        const emailtype = res.filter(item => item.Type === 'Email');
        const apptype = res.filter(item => item.Type === 'Push');
        const Bothtype = res.filter(item => item.Type === 'Both');

        setNotificationDataEmail(emailtype);
        setNotificationDataApp(apptype);
        setNotificationDataBoth(Bothtype);

        setTotalEmail(emailtype.length);
        setTotalSms(apptype.length);
        setTotalBoth(Bothtype.length);
        updateProgress(res.length);
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );

  }, []);



  return (
    <>
      <Grid container spacing={2} className="mb-10">
        <Grid item xs={12} sm={7}>
          <p className="p-0 m-0 mb-5 fonthe">
            Notifications
          </p>
          <SingleSelect
            value={frequency}
            className={`field newonr`}
            name={"frequency"}
            onChange={(e) => onChangeInput(e)}
            options={frequencyList}
          />
        </Grid>
        <Grid item xs={12} sm={5} className="text-right">
          <CircularProgressWithLabel value={progress} wrapclass="color-noti" />
        </Grid>
      </Grid>
      <ul>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Email
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalemail ? totalemail : 0}</strong>
            </Grid>
          </Grid>
        </li>

        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Sms
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalsms ? totalsms : 0}</strong>
            </Grid>
          </Grid>
        </li>
        <li className={styles.listItem}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={9}>
              Both
            </Grid>
            <Grid item xs={12} sm={3} className="text-right">
              <strong>{totalboth ? totalboth : 0}</strong>
            </Grid>
          </Grid>
        </li>
      </ul>
    </>
  );
}

export default Notifications;
